import { Loader } from 'components/Loader';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
	getLoaderSelector,
	getTreasurytSelector,
	getWithdrawalSelector,
} from 'redux/reducers/transaction/selectors';
import { ETabsReserved, ETabsTransaction } from 'services/constants/lists-table';
import { DebounceInput } from 'react-debounce-input';
import { SearchSvg } from 'assets/svg-icon';
import { ReservedTabs } from './ReservedTabs/ReservedTabs';
import { ReservedHeader } from 'components/ReservedComponents/ReservedHeader/ReservedHeader';
import { ReservedBody } from 'components/ReservedComponents/ReservedBody/ReservedBody';
import { UsernameBody } from 'components/ReservedComponents/UsernameBody/UsernameBody';
import { UsernameHeader } from 'components/ReservedComponents/UsernameHeader/UsernameHeader';
import { UsernameFilter } from 'components/ReservedComponents/UsernameFilter/UsernameFilter';
import {
	addUsernamesRequest,
	deleteUsernamesRequest,
	editUsernamesRequest,
	getUsernamesRequest,
} from 'redux/reducers/reserved/reducer';
import { getUsernamesSelector, getreservedLoaderSelector } from 'redux/reducers/reserved/selectors';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { Drop } from 'ui/Drop/Drop';
import { Input } from 'ui/Input';
import { Pagination } from 'ui/Pagination';
import { deleteUserRequest } from 'redux/reducers/users/reducer';
import { onButtonName, onLabel, onPlaceholder, onTitlePopUp } from 'untils/popUpWrapperFunc';
import { Exception } from './Exception/Exception';

const Reserved: FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const [search, setSearch] = useState<string>('');
	const loader = useSelector(getreservedLoaderSelector);
	const usrsname = useSelector(getUsernamesSelector);
	const [popup, setPopup] = useState<boolean>(false);
	const [file, setFile] = useState<FileList | null>(null);
	const [name, setName] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [popupName, setPopupName] = useState<string>('');
	const [idName, setIdName] = useState<number>(0);
	const [namePopup, setNamePopup] = useState<string>('');
	const [exception, setException] = useState<Array<string>>([]);

	const handleChange = (file: FileList) => {
		setFile(file);
	};

	const addException = () => {
		setException((prev) => [...prev, name]);
		setName('');
	};

	const removeException = (item: string) => {
		setException(exception.filter((element) => item !== element));
	};

	const isOpenModal = (event?: MouseEvent) => {
		const id = event?.target as HTMLButtonElement;

		setPopup(!popup);
		setName('');

		String(id.id) !== 'Exception for use' && setException([]);

		if (id) {
			setNamePopup(id.id);
			if (id.id === 'Edit') {
				setName(usrsname?.usernames.find((user) => user.id === idName)?.username || '');
				setException(
					usrsname?.usernames.find((user) => user.id === idName)?.config?.excepted_for_emails || [],
				);
			}
		}
	};

	const onHandleSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
	};

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		setName(value);
	};

	const onPopup = (value: number) => {
		setIdName(value);
		setException(
			usrsname?.usernames?.find((email) => email.id === value)?.config?.excepted_for_emails || [],
		);
	};

	const handleButton = () => {
		switch (namePopup) {
			case 'Edit':
				dispatch(
					editUsernamesRequest({
						body: {
							config: {
								excepted_for_emails: exception,
							},
							username: name,
							id: idName,
						},
						onClose: () => {
							isOpenModal();
						},
					}),
				);
				break;
			case 'Exception for use':
				dispatch(
					editUsernamesRequest({
						body: {
							config: {
								excepted_for_emails: exception,
							},
							username: usrsname?.usernames.find((user) => user.id === idName)?.username || '',
							id: idName,
						},
						onClose: () => {
							isOpenModal();
						},
					}),
				);
				break;
			case 'Delete':
				dispatch(
					deleteUsernamesRequest({
						id: String(idName),
						onClose: () => {
							isOpenModal();
						},
						body: {
							search,
							current_page: currentPage,
							per_page: 8,
						},
					}),
				);
				break;

			default:
				name &&
					dispatch(
						addUsernamesRequest({
							body: { username: name },
							onClose: isOpenModal,
						}),
					);
		}
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	const definePage = () => {
		switch (params.tab) {
			// case ETabsReserved.REGISTRATION_REQUESTS:
			// 	return (
			// 		<div className={`table table--reserved`}>
			// 			<ReservedHeader />
			// 			{loader ? <Loader /> : <ReservedBody />}
			// 		</div>
			// 	);
			default:
				return (
					<div className={`table table--usernames table-body-wrap`}>
						<UsernameFilter
							isOpenModal={isOpenModal}
							search={search}
							handleSearch={onHandleSearch}
						/>
						<UsernameHeader />
						{loader ? (
							<Loader />
						) : (
							<UsernameBody
								isOpenModal={isOpenModal}
								idName={idName}
								onPopup={onPopup}
								usernames={usrsname?.usernames}
							/>
						)}
					</div>
				);
		}
	};

	// useEffect(() => {
	// 	submitFilter(+location.search.slice(1) || 1);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [params, dataFiltter]);

	useEffect(() => {
		dispatch(
			getUsernamesRequest({
				search,
				current_page: currentPage,
				per_page: 8,
			}),
		);
	}, [search, currentPage]);

	return (
		<div className="main-content">
			{popup && (
				<PopUpWrapper
					value={name || namePopup === 'Delete' ? 'true' : ''}
					disablet={exception}
					isOpenModal={isOpenModal}
					handleButton={handleButton}
					title={onTitlePopUp(namePopup)}
					btnName={onButtonName(namePopup)}
				>
					{namePopup === 'Exception for use' ? (
						<Exception
							exception={exception}
							namePopup={namePopup}
							name={name}
							handleInput={handleInput}
							removeException={removeException}
							addException={addException}
						/>
					) : (
						<>
							{namePopup !== 'Delete' ? (
								<Input
									handleInput={handleInput}
									value={name}
									type="text"
									name="word"
									placeholder={onPlaceholder(namePopup)}
									label={onLabel(namePopup)}
									className="padding-bottom"
								/>
							) : null}
							{/* <div className="or-another">OR</div> */}

							{/* <Drop value={name} handleChange={handleChange} /> */}
						</>
					)}
				</PopUpWrapper>
			)}
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title"> Reserved usernames </p>
				</div>
			</div>
			<ReservedTabs />
			<div className="table-block">
				{/* {(params.tab === ETabsReserved.REGISTRATION_REQUESTS || !params.tab) && (
					<div className="input input-custom input-search input-search-custom input-search-width380 input-search-padding">
						<div className="input-wrapper input-wrapper-custom input-wrapper-custom100">
							<DebounceInput
								className="input-item input-item--left-icon"
								type="text"
								name="search"
								placeholder="Search by username"
								value={search || ''}
								onChange={onHandleSearch}
								debounceTimeout={500}
							/>
							<span className="input-icon">
								<SearchSvg />
							</span>
						</div>
					</div>
				)} */}
				<div className="table-wrapper">{definePage()}</div>
			</div>
			{usrsname?.usernames && usrsname?.total > 8 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(usrsname.total / 8)}
				/>
			) : null}
		</div>
	);
};

export default Reserved;
