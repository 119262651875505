import React, { useEffect, useState } from 'react';
import { IEntities, TextEditor } from 'ui/TextEditor/TextEditor';
import './AddNewTemplate.scss';
import { DropFile } from 'ui/DropFile/DropFile';
import { DownloadSvg } from 'assets/svg-icon';
import Remove from '../../../../assets/svg-icon/remove.svg';
import TimeZoneFilter, { IAddDateAndTime } from '../DatePicker/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { addAdvertiseRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { useParams } from 'react-router-dom';
import { IParams } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { getLogoLoaderSelector } from 'redux/reducers/ai-chat-bot/selectors';
import { SmallLoader } from 'components/SmallLoader';
import { maxFileSize } from 'untils/maxFileSize';

interface IProps {
	onUpload?: (data: File, name: string) => void;
	nameFile?: string;
	size?: number;
	loader?: boolean;
	onClose: () => void;
	editAdvertise: any;
}

const AddNewTemplate: React.FC<IProps> = ({ nameFile, loader, onClose, editAdvertise }) => {
	const dispatch = useDispatch();
	const [description, setDescription] = useState('');
	const [entities, setEntities] = useState<Array<IEntities> | []>([]);
	const [files, setFiles] = useState<File[]>([]);
	const params = useParams<IParams>();
	const loaderBtn = useSelector(getLogoLoaderSelector);
	const [dataAndTime, setDataAndTime] = useState<IAddDateAndTime>({
		time_from: '',
		time_to: '',
		time_zone: '',
	});

	const handleChange = (newFiles: File, _name: string) => {
		if (newFiles.size >= maxFileSize(1)) {
			alert('The file exceeds the maximum size of 1 MB, so it has been rejected.');
			return;
		}
		setFiles((prev) => [...prev, newFiles]);
	};

	const onDelete = (file: File) => {
		setFiles((prev) => prev.filter((item: File) => item.name !== file.name));
	};

	const addAdvertise = () => {
		const formData = new FormData();
		formData.append('title', description);
		if (entities.length) {
			formData.append('entities', JSON.stringify(entities));
		}
		formData.append('time_from', dataAndTime.time_from);
		formData.append('time_to', dataAndTime.time_to);
		formData.append('time_zone', dataAndTime.time_zone);

		if (files[0]) {
			for (let index = 0; index < files.length; index++) {
				if (index === 0) {
					formData.append(`file`, files[index]);
				} else {
					formData.append(`file${index + 1}`, files[index]);
				}
			}
		}

		dispatch(addAdvertiseRequest({ dispatch, onClose, bot_id: params.id, formData }));
	};

	const addDateAndTime = (_data: IAddDateAndTime) => {
		setDataAndTime(_data);
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	useEffect(() => {
		(description || files.length || entities.length) &&
			editAdvertise({ title: description, entities, file: files });
	}, [description, files]);

	return (
		<div className="add-new-template">
			<div className="add-new-template-popup">
				<button type="button" onClick={onClose} className="close">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="23"
						viewBox="0 0 22 23"
						fill="none"
					>
						<path
							d="M16.5551 17.195L5.72618 6.36611M5.72618 17.195L16.5551 6.36611"
							stroke="#929298"
							strokeLinecap="round"
						/>
					</svg>
				</button>
				<div className="add-new-template-popup__title">Add New Template</div>
				<div className="add-new-template-popup__lable">Title</div>
				<div className="add-new-template-popup__editor">
					<TextEditor
						setDescription={setDescription}
						defaultText={description}
						setEntities={setEntities}
						entities={entities}
						defaultEntities={[]}
					/>
				</div>
				<div className="add-new-template-popup__lable">Offer Validity Period</div>
				<TimeZoneFilter editAdvertise={editAdvertise} addDateAndTime={addDateAndTime} />
				<div className="add-new-template-popup__lable">Upload file</div>
				{files.length ? (
					<div className="image-preview">
						{files?.map((file, index) => (
							<div
								className="image-preview-wrap"
								style={{
									overflow: 'hidden',
									display: 'flex',
									alignItems: 'center',
									position: 'relative',
									width: '135px',
									height: '135px',
									margin: '5px',
								}}
								key={index}
							>
								<button onClick={() => onDelete(file)} type="button" className="btn-remove">
									<img src={Remove} alt="remove button" />
								</button>
								<img
									className="ava"
									style={{ width: '100%', maxWidth: '135px', maxHeight: '135px' }}
									src={URL.createObjectURL(file)}
									alt={`Preview ${index + 1}`}
								/>
							</div>
						))}
					</div>
				) : null}
				{files?.length < 10 ? (
					<div className="accordion__right-content">
						<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
							<DropFile
								photo={''}
								svg={<DownloadSvg />}
								size={0}
								nameFile={nameFile}
								handleChange={(file: any) => handleChange(file, '')}
								loader={loader}
								// multiple={true}
							/>
						</div>
					</div>
				) : null}
				<div className="add-new-template-popup__btn-wrap">
					<button
						disabled={
							!(
								dataAndTime.time_from &&
								dataAndTime.time_to &&
								dataAndTime.time_zone &&
								description
							)
						}
						onClick={addAdvertise}
						className="add-new-template-popup__btn-approve"
					>
						{loaderBtn ? <SmallLoader /> : 'Approve'}
					</button>
					<button onClick={onClose} className="add-new-template-popup__btn-reject">
						Reject
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddNewTemplate;
