import { ChangeEvent, FC } from 'react';
import { AdminRules } from '../AdminRules/AdminRules';
import { Select } from 'ui/Select/Select';
import { SearchSvg } from 'assets/svg-icon';
import { IAdminsResponse } from 'redux/reducers/admins/types';
import { SelectList } from 'ui/SelectList/SelectList';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';
import { getCSVRequest } from 'redux/reducers/admins/reducer';

const admin = {
	ruleName: 'Admin',
	ruleDesc: 'Admins can add and remove users and manage channel-level settings.',
};

const user = {
	ruleName: 'User Manager',
	ruleDesc: 'Admins can add and remove users and manage channel-level settings.',
};

const help = {
	ruleName: 'Help Desk',
	ruleDesc: 'Admins can add and remove users and manage channel-level settings.',
};

const manager = {
	ruleName: 'Billing manager',
	ruleDesc: 'Admins can add and remove users and manage channel-level settings.',
};

interface IProps {
	admins: Array<IAdminsResponse> | null;
	onSearch: (event: ChangeEvent) => void;
	search: string;
}

export const ActiveTab: FC<IProps> = ({ admins, onSearch, search }) => {
	const dispatch = useDispatch();

	const onGetCSV = (value: string) => {
		dispatch(getCSVRequest());
	};

	return (
		<>
			<div className="filter-wrapper filter-flex-between">
				<div className="user-data__block left">
					<SelectList onSelect={onGetCSV} placeholder={'Export'} data={['CSV']} />
				</div>
				<div className="input input-custom input-search select-custom-width">
					<div className="input-wrapper">
						<DebounceInput
							className="input-item input-item--left-icon"
							type="text"
							name="search"
							placeholder="Search"
							value={search || ''}
							onChange={onSearch}
							debounceTimeout={500}
						/>
						<span className="input-icon">
							<SearchSvg />
						</span>
					</div>
				</div>
			</div>

			<div className="table-wrapper">
				<AdminRules
					rule={admin}
					role={admins?.filter(
						(rule) => rule.role?.toLowerCase() === 'admin' || rule.role?.toLowerCase() === 'owner',
					)}
				/>
				<AdminRules
					rule={user}
					role={admins?.filter((rule) => rule.role?.toLowerCase().includes('user'))}
				/>
				<AdminRules
					rule={help}
					role={admins?.filter((rule) => rule.role?.toLowerCase().includes('help'))}
				/>
				<AdminRules
					rule={manager}
					role={admins?.filter(
						(rule) =>
							rule.role?.toLowerCase().includes('manager') || rule.role.includes('billing_manager'),
					)}
				/>
			</div>
		</>
	);
};
