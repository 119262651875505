import { typeFilter, typeStatus } from 'CONST/userFilter';
import { ReportsBody } from 'components/ReportsComponents/ReportsBody';
import { ReportsGroupsHeader } from 'components/ReportsComponents/ReportsGroupsHeader/ReportsGroupsHeader';
import { ReportsChannelsHeader } from 'components/ReportsComponents/ReportsHeader';
import { ReportsTabs } from 'components/ReportsComponents/ReportsTabs';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	getReportsChannelRequest,
	getReportsGroupsRequest,
	getReportsMessagesRequest,
	getReportsPostRequest,
} from 'redux/reducers/reports/reducer';
import {
	getChannelsSelector,
	getGroupsSelector,
	getLoader,
	getMessagesSelector,
	getPostsSelector,
	getTypesSelector,
} from 'redux/reducers/reports/selectors';
import { IChannelsResponse } from 'redux/reducers/reports/types';
import { EReportsTabs } from 'services/constants/lists-table';
import { Fillter } from 'ui/Fillter/Fillter';
import { IDataFiltter } from '../Channels';
import { Pagination } from 'ui/Pagination';
import { NotFound } from 'ui/NotFound/NotFound';

const Reports: FC = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({});
	const typesList = useSelector(getTypesSelector);
	const loader = useSelector(getLoader);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	const defineSelector = () => {
		if (params.tab === EReportsTabs.GROUPS) {
			return getGroupsSelector;
		}
		if (params.tab === EReportsTabs.MESSAGES_IN_GROUPS) {
			return getMessagesSelector;
		}
		if (params.tab === EReportsTabs.CHANNEL_POSTS) {
			return getPostsSelector;
		}

		return getChannelsSelector;
	};

	const tableDate: IChannelsResponse | null = useSelector(defineSelector());

	const onHeaderTable = () => {
		switch (params.tab) {
			case EReportsTabs.GROUPS:
				return <ReportsGroupsHeader />;

			case EReportsTabs.MESSAGES_IN_GROUPS:
				return <ReportsGroupsHeader />;

			default:
				return <ReportsChannelsHeader />;
		}
	};

	useEffect(() => {
		const filter: {
			type_id: number | string | undefined;
			date_from: string | Date;
			date_to: string | Date;
			search: string;
		} = {
			type_id: dataFiltter.type_id,
			date_from: dataFiltter.date_from || '',
			date_to: dataFiltter.date_to || '',
			search: dataFiltter.search || '',
		};

		const data = {
			current_page: currentPage,
			per_page: 15,
			...filter,
		};

		switch (params.tab) {
			case EReportsTabs.GROUPS:
				dispatch(getReportsGroupsRequest(data));
				break;

			case EReportsTabs.MESSAGES_IN_GROUPS:
				dispatch(getReportsMessagesRequest(data));
				break;

			case EReportsTabs.CHANNEL_POSTS:
				dispatch(getReportsPostRequest(data));
				break;

			default:
				dispatch(getReportsChannelRequest(data));
		}
	}, [params.tab, dataFiltter, currentPage]);

	const onImport = () => {
		dispatch(getReportsChannelRequest({ current_page: 1, per_page: 15, export: 'csv' }));
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block title-block--space-between">
					<p className="title"> Reports </p>
				</div>
			</div>
			<ReportsTabs />
			<div style={{ paddingBottom: '15px' }} className="table-block">
				<Fillter
					onImport={onImport}
					keyStatus="status"
					keyType="types"
					setDataFiltter={setDataFiltter}
				/>
				<div className="table-wrapper">
					<div className="table table--users-private-reports container-finance">
						{onHeaderTable()}
						{tableDate?.reports?.length ? (
							<ReportsBody param={params.tab as string} reports={tableDate.reports} />
						) : (
							<NotFound name={'reports'} />
						)}
					</div>
				</div>
			</div>
			{!loader && tableDate?.reports?.length && tableDate?.total > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(tableDate.total) / 15}
				/>
			) : null}
		</div>
	);
};

export default Reports;
