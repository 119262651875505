import { cardNameChannels, recommended, verified } from 'CONST/cardName';
import { DeleteSvg, LockRedSvg, LockSvg } from 'assets/svg-icon';
import { FC, useState } from 'react';
import { DetailsChannels } from './DetailsChannels/DetailsChannels';
import { IDetailsChannel } from 'redux/reducers/channels/types';
import { ICountry } from 'redux/reducers/users/types';
import { Select } from 'ui/Select/Select';
import { useDispatch } from 'react-redux';
import {
	blockChannelsRequest,
	changeRecommendedRequest,
	changeVerifiedRequest,
	deleteChannelsRequest,
} from 'redux/reducers/channels/reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';
import { numeralFunc, numeralSpaceFunc } from 'untils/numerals';

interface IPropsChannelsData {
	channel: IDetailsChannel;
	countriesList: null | Array<ICountry>;
}

export const ChannelsData: FC<IPropsChannelsData> = ({ channel, countriesList }) => {
	const [toggle, setToggle] = useState(false);
	const dispatch = useDispatch();
	const params = useParams();
	const [text, setText] = useState<string>('');
	const navigate = useNavigate();

	const handleTofuChange = () => {
		setToggle(!toggle);
	};

	const onGetDetailsChannel = (value: string) => {
		switch (value) {
			case cardNameChannels[0]:
				return numeralSpaceFunc(channel.participant_count);
			case cardNameChannels[1]:
				return `$${numeralFunc(channel.earn_sum)}`;
			default:
				return numeralSpaceFunc(channel.count_of_claims);
		}
	};

	const changeVerified = (channel_id: number, verify: boolean) => {
		dispatch(
			changeVerifiedRequest({
				channel_id,
				verify,
			}),
		);
	};

	const changeRecommended = (channel_id: number, recommend: boolean) => {
		dispatch(
			changeRecommendedRequest({
				channel_id,
				recommend,
			}),
		);
	};

	const onBlock = (name: string) => {
		setText(name);
	};

	const onRemoveUser = () => {
		text === 'delete' || text === 'recover'
			? dispatch(
					deleteChannelsRequest({
						paramsApi: {
							channel_id: Number(params.id),
							recover: text === 'recover' ? true : false,
						},
						navigate,
					}),
			  )
			: dispatch(
					blockChannelsRequest({
						paramsApi: {
							deactivate: params.userId?.includes('blocked') ? false : true,
							channel_id: Number(params.id),
						},
						navigate,
					}),
			  );
	};

	return (
		<div className="user-data">
			{text && (
				<PopUpAsk
					setText={setText}
					title={`Are you sure you want to ${text} this channel?`}
					btnNameOne="Yes, I’m sure"
					btnNameTwo="Cancel"
					onClick={onRemoveUser}
				/>
			)}
			<div className="user-data__header">
				<div className="user-data__block left channels-data">
					<Select data={verified} defaultType={channel.verified} upgradeChannel={changeVerified} />

					{channel.hide_recommend ? null : (
						<Select
							data={recommended}
							defaultType={channel.recommended}
							upgradeChannel={changeRecommended}
						/>
					)}
				</div>
				<div className="user-data__block right width">
					{params.userId === 'delete-channels' ? (
						<div
							onClick={() => onBlock('recover')}
							className="user-data__toggle-wrapper right pointer"
						>
							<LockRedSvg />
							<span className={`user-data__toggle-text red`}>Recover channel</span>
						</div>
					) : (
						<div
							onClick={() => onBlock('block')}
							className="user-data__toggle-wrapper right pointer"
						>
							{params.userId?.includes('blocked') ? <LockSvg /> : <LockRedSvg />}
							<span
								className={`user-data__toggle-text ${
									params.userId?.includes('blocked') ? 'green' : 'red'
								}`}
							>
								{params.userId?.includes('blocked') ? 'Unblock' : 'Block'}
							</span>
						</div>
					)}
					{params.userId === 'delete-channels' ? (
						<div
							onClick={() => onBlock('delete')}
							className="user-data__toggle-wrapper right pointer"
						>
							<DeleteSvg />
							<span className="user-data__toggle-text red">Irrevocably delete</span>
						</div>
					) : (
						<div
							onClick={() => onBlock('delete')}
							className="user-data__toggle-wrapper right pointer"
						>
							<DeleteSvg />
							<span className="user-data__toggle-text red">Delete channel</span>
						</div>
					)}
				</div>
			</div>
			<div className="user-data__body">
				<div className="user-data__card-wrapper">
					{cardNameChannels?.map((key) => (
						<div key={key} className="user-data__card">
							<div className="user-data__title">{key}</div>
							<div className="user-data__subtitle">{`${onGetDetailsChannel(key)}`}</div>
						</div>
					))}
				</div>
				<DetailsChannels channel={channel} countriesList={countriesList} />
			</div>
		</div>
	);
};
