import { SmallLoader } from 'components/SmallLoader';
import React, { useEffect, useState, useRef, useCallback, FC, ChangeEvent } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { IAiChatBotsResponse } from 'redux/reducers/ai-chat-bot/types';
import { CheckBoxThree } from 'ui/CheckBoxThree/CheckBoxThree';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import './InfiniteScroll.scss';

interface IPropsInfiniteScroll {
	onNextPopup: () => void;
	onClose: () => void;
	search: string;
	handlInput: (event: ChangeEvent) => void;
	botsList: null | IAiChatBotsResponse;
	checkBox: string;
	onChange: (event: ChangeEvent) => void;
	setPerPage: (value: number) => void;
	loadingAI: boolean;
}

export const InfiniteScrollCodeGeneration: FC<IPropsInfiniteScroll> = ({
	onNextPopup,
	search,
	handlInput,
	botsList,
	onClose,
	checkBox,
	onChange,
	setPerPage,
	loadingAI,
}) => {
	const [page, setPage] = useState(1); // Номер сторінки
	const [loading, setLoading] = useState(false); // Стан завантаження

	const scrollRef = useRef<HTMLDivElement | null>(null);

	// Функція для завантаження нових даних
	const loadMoreItems = useCallback(async () => {
		if (!loading) {
			setLoading(true);
			setTimeout(() => {
				setPage((prevPage) => prevPage + 1);
				setPerPage((page + 1) * 15); // Оновлення кількості елементів на сторінці
				setLoading(false); // Завантаження завершено
			}, 1000); // Симуляція затримки в 1 секунду
		}
	}, [loading, page, search]);

	// Обробка скролу всередині блоку code-generation
	const handleScroll = useCallback(() => {
		const container = scrollRef.current;

		if (container) {
			const { scrollTop, scrollHeight, clientHeight } = container;

			if (
				Math.ceil(scrollTop + clientHeight) >= scrollHeight &&
				!loading &&
				Number(page * 15) < Number(botsList?.total)
			) {
				loadMoreItems(); // Завантажити нові дані, якщо досягнуто кінця контейнера
			}
		}
	}, [loading, loadMoreItems]);

	useEffect(() => {
		const container = scrollRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [handleScroll]);

	return (
		<div>
			<PopUpWrapper
				handleButton={onNextPopup}
				disablet={checkBox.length ? checkBox : ''}
				isOpenModal={onClose}
				title={'Code generation'}
				btnName={'Next'}
			>
				<DebounceInput
					className={`input-item input-item--left-icon input-item--left-icon-none`}
					type="text"
					name="search"
					placeholder={'Search AI bot'}
					value={search || ''}
					onChange={handlInput}
					debounceTimeout={500}
				/>
				<div
					ref={scrollRef}
					className="code-generation"
					style={{ position: 'relative', overflowY: 'auto' }}
				>
					{botsList?.ai_bots?.map((bot, index) => (
						<CheckBoxThree
							key={index}
							onChange={onChange}
							type={'radio'}
							title={bot.name}
							subtitle={bot.username}
							photo={bot.photo_access_hash}
							name={String(bot.id)}
							checkBox={checkBox}
						/>
					))}
				</div>
				<div className="loader-center">{loadingAI ? <SmallLoader /> : null}</div>
			</PopUpWrapper>
		</div>
	);
};
