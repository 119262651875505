// import { Centrifuge } from 'lib';
import { FC, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { refreshResponse } from 'redux/reducers/auth/reducer';
import { router } from 'routes';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

interface IMassage {
	channel: string;
	data: {
		admin: boolean;
		input: string;
		admin_id: number;
	};
}

interface IType {
	type?: 0 | 1 | 2;
	id?: number;
}

const App: FC = () => {
	return (
		<Suspense fallback="Loading...">
			<ErrorBoundary>
				<RouterProvider router={router} />
			</ErrorBoundary>
		</Suspense>
	);
};

export default App;
