import { ArrowBackSvg, PlusSvg, SearchSvg } from 'assets/svg-icon';
import { Groups } from 'components/UserDetailsComponents/Groups';
import { SubTabsConversations } from 'components/UserDetailsComponents/SubTabsGroups';
import { SubTabsDetailsUser } from 'components/UserDetailsComponents/SubTabsDetailsUser';
import { TabsDetailsUser } from 'components/UserDetailsComponents/TabsDetailsUser';
import { UserData } from 'components/UserDetailsComponents/UserData/UserData';
import { UserDetailsTableBody } from 'components/UserDetailsComponents/UserDetailsTableBody';
import { UserDetailsTableHeader } from 'components/UserDetailsComponents/UserDetailsTableHeader';
import { UserTransactionHistory } from 'components/UserDetailsComponents/UserTransactionHIstory';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubscriptionTabs, ETabsUsersDetails } from 'services/constants/lists-table';
import { Fillter } from 'ui/Fillter/Fillter';
import { transactionStatus, transactionTypes } from 'CONST/userFilter';
import classNames from 'classnames';
import { mediaTabs } from 'CONST/channels-tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
	getChannelsRequest,
	getGroupsRequest,
	getPhotosRequest,
	getTransactionRequest,
	getUserRequest,
	getUserSuccess,
	putCounties,
} from 'redux/reducers/users/reducer';
import {
	getAllCountries,
	getChannels,
	getGroups,
	getLoader,
	getTransaction,
	getUserDetails,
} from 'redux/reducers/users/selectors';
import {
	IChannelsResponse,
	IGroupsResponse,
	ITransactionResponse,
} from 'redux/reducers/users/types';
import { Pagination } from 'components/Pagination';
import { DebounceInput } from 'react-debounce-input';
import { IDataFiltter } from '../Channels';
import { Subscriptions } from 'components/UserDetailsComponents/Subscriptions/Subscriptions';
import { SubscriptionTabs } from 'components/UserDetailsComponents/Subscriptions/SubscriptionTabs/SubscriptionTabs';
import { Button } from 'ui/Button/Button';
import { CardFinance } from 'components/UserDetailsComponents/CardFinance/CardFinance';
import { Modal } from 'ui/Modal/Modal';
import photo from '../../../assets/images/ava.png';
import { AiBot } from 'components/UserDetailsComponents/AiBot/AiBot';
import { Discount } from 'components/UserDetailsComponents/Discount/Discount';
import { getReferral } from 'redux/reducers/referral/selectors';
import { NotFound } from 'ui/NotFound/NotFound';
import { Limits } from 'components/UserDetailsComponents/Limits/Limits';
import { Loader } from 'components/Loader';
const mocData = [
	{ ava: photo, title: 'Gal Gadot - Official bla', subtitle: 'elloapp.org/GalGadot' },
	{ ava: photo, title: 'Gal Gadot - Official bla', subtitle: 'elloapp.org/GalGadot' },
	{ ava: photo, title: 'Gal Gadot - Official bla', subtitle: 'elloapp.org/GalGadot' },
	{ ava: photo, title: 'Gal Gadot - Official bla', subtitle: 'elloapp.org/GalGadot' },
	{ ava: photo, title: 'Gal Gadot - Official bla', subtitle: 'elloapp.org/GalGadot' },
];

const UserDetails: FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const location = useLocation();
	const countriesList = useSelector(getAllCountries);
	const loader = useSelector(getLoader);
	const [search, setSearch] = useState<string>('');
	const [sum, setSum] = useState<string>('');
	const [subtitle, setSubtitle] = useState<string>('');
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({});
	const [modal, setModal] = useState<boolean>(false);
	const referral = useSelector(getReferral);
	const userDetails = useSelector(getUserDetails);

	const onNextModal = () => {
		if (subtitle) {
			setSubtitle('');
			setModal(false);
		}
		!subtitle &&
			setSubtitle(
				`The user will be subscribed for one month. From "date" the user will need to cancel the ${
					params.subtab === 'ai'
						? params.subtab
						: params.subtab === 'backgrounds'
						? params.subtab
						: 'channels'
				}.`,
			);
	};

	const onNextModalForFinance = () => {
		if (subtitle) {
			setSubtitle('');
			setModal(false);
		}
		!subtitle && setSubtitle(`Do you really want to top up Corey Mango Ello earned balance?`);
	};

	const defineSelector = () => {
		if (params.tab === ETabsUsersDetails.CONVERSATION) {
			return getGroups;
		}
		if (params.tab === ETabsUsersDetails.CHANNELS) {
			return getChannels;
		}
		if (params.tab === ETabsUsersDetails.TRANSACTION_HISTORY) {
			return getTransaction;
		}

		return getUserDetails;
	};

	// @ts-ignore
	const tableDate: any = useSelector(defineSelector());

	const onModal = () => {
		setModal(!modal);
		setSubtitle('');
	};

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSum(value);
	};

	const onType = () => {
		switch (params.subTab) {
			case 'created':
				return 1;
			case 'tab-admin':
			case 'admin':
				return 2;
			default:
				return 0;
		}
	};

	const definePage = () => {
		switch (params.tab) {
			case ETabsUsersDetails.TRANSACTION_HISTORY:
				return (
					<UserTransactionHistory
						setDataFiltter={setDataFiltter}
						loader={loader}
						tableDate={tableDate as ITransactionResponse}
					/>
				);
			case ETabsUsersDetails.CONVERSATION:
				return tableDate?.data?.chats?.length ? (
					<Groups tableDate={tableDate as IGroupsResponse} loader={loader} />
				) : (
					<NotFound name={'groups'} />
				);
			case ETabsUsersDetails.SUBSCRIPTIONS:
				return <Subscriptions />;
			case ETabsUsersDetails.AI_BOT:
				return <AiBot />;
			case ETabsUsersDetails.DISCOUNT:
				return <Discount userDetails={userDetails?.data} />;
			case ETabsUsersDetails.FINANCE:
				return (
					<CardFinance
						onModal={onModal}
						modal={modal}
						handleInput={handleInput}
						sum={sum}
						onNextModal={onNextModalForFinance}
						subtitle={subtitle}
					/>
				);
			case ETabsUsersDetails.LIMITS:
				return userDetails ? (
					<Limits
						created={userDetails?.data.limit_channel_public_creator}
						subscribed={userDetails?.data.limit_channel_public_subscriber}
						user_id={Number(params.id)}
					/>
				) : (
					<Loader />
				);
			case ETabsUsersDetails.USER_DATA:
				return (
					userDetails?.data && (
						<UserData userDetails={userDetails.data} countriesList={countriesList} />
					)
				);
			default:
				return (
					<div className="table table--users-private-chats">
						<UserDetailsTableHeader setDataFiltter={setDataFiltter} />
						{tableDate?.data?.channels?.length ? (
							<UserDetailsTableBody
								setDataFiltter={setDataFiltter}
								loader={loader}
								tableDate={tableDate as IChannelsResponse}
							/>
						) : (
							<NotFound name={'transaction history'} />
						)}
					</div>
				);
		}
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	useEffect(() => {
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
			.then((response) => response.json())
			.then((data) => {
				dispatch(putCounties(data.countries));
				// setSelectedCountry(data.userSelectValue);
			})
			.catch((error) => {
				console.log(error);
			});

		return () => {
			dispatch(getUserSuccess(null));
		};
	}, []);

	useEffect(() => {
		if (params.tab === ETabsUsersDetails.USER_DATA) {
			dispatch(getPhotosRequest({ user_id: params.id as string }));
		}

		if (params.tab === ETabsUsersDetails.CHANNELS) {
			dispatch(
				getChannelsRequest({
					user_id: params.id as string,
					search,
					current_page: currentPage,
					per_page: 15,
					user_types: onType(),
					...dataFiltter,
				}),
			);
		}

		if (params.tab === ETabsUsersDetails.CONVERSATION) {
			dispatch(
				getGroupsRequest({
					search,
					user_id: params.id as string,
					current_page: currentPage,
					participiant_type: onType(),
					per_page: 15,
					...dataFiltter,
				}),
			);
		}

		if (params.tab === ETabsUsersDetails.TRANSACTION_HISTORY) {
			dispatch(
				getTransactionRequest({
					user_id: params.id as string,
					current_page: currentPage,
					per_page: 15,
					...dataFiltter,
				}),
			);
		}
	}, [params.tab, params.subTab, search, dataFiltter, currentPage]);

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
	};

	const onHandleSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
	};

	useEffect(() => {
		dispatch(getUserRequest({ user_id: params.id as string }));
	}, [referral]);

	return (
		<div className="main-content">
			{modal && params.sub === 'subscriptions' && (
				<Modal
					title={
						subtitle
							? `Are you sure you want to subscribe the user to ${
									params.subtab === 'ai'
										? params.subtab
										: params.subtab === 'backgrounds'
										? params.subtab
										: 'channels'
							  }?`
							: 'Subscribe user'
					}
					subtitle={subtitle}
					data={mocData}
					onModal={onModal}
					onNextModal={onNextModal}
					onHandleSearch={onHandleSearch}
					search={search}
				/>
			)}
			<div className="url color-grey big-first-letter">
				{location.pathname
					.slice(1)
					.replaceAll('/', ' / ')
					.replace(
						params.id || '',
						`${userDetails?.data?.first_name || ''} ${userDetails?.data?.last_name || ''}`,
					)}
			</div>

			<div className="title-block-wrap">
				<div className="title-block">
					<Link className="back-button" to={`${ELinksName.USERS}/registered/personal-profile`}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">
						{`${userDetails?.data?.first_name || ''} ${userDetails?.data?.last_name || ''}`}
					</p>
				</div>
			</div>
			<TabsDetailsUser />
			<div className={`table-block ${params.tab === 'user-data' ? 'table-body-height' : ''}`}>
				{ETabsUsersDetails.TRANSACTION_HISTORY === params.tab ? (
					<Fillter
						type={transactionTypes}
						status={transactionStatus}
						typesName="Types"
						statusName="Status"
						setDataFiltter={setDataFiltter}
						keyStatus="status"
						keyType="types"
					/>
				) : (
					ETabsUsersDetails.USER_DATA !== params.tab &&
					params.tab !== ETabsUsersDetails.FINANCE &&
					params.tab !== ETabsUsersDetails.DISCOUNT &&
					params.tab !== ETabsUsersDetails.LIMITS &&
					ETabsUsersDetails.AI_BOT !== params.tab && (
						<div
							className={classNames(
								'input input-item-margin input-custom input-search input-search-custom button-left-margin',
								{
									'input-search-wrapper': mediaTabs.includes(params.tab || ''),
								},
							)}
						>
							<div className="input-wrapper input-wrapper-custom">
								<DebounceInput
									className="input-item input-item--left-icon"
									type="text"
									name="search"
									placeholder="Search by user, username, email"
									debounceTimeout={500}
									value={search || ''}
									onChange={onSearch}
								/>
								<span className="input-icon">
									<SearchSvg />
								</span>
							</div>
							{params.tab === ETabsUsersDetails.SUBSCRIPTIONS ? (
								<div className="button-left-margin">
									<Button
										className="button-wrapper"
										onClick={onModal}
										type="button"
										name="Subscribe user"
									>
										<PlusSvg />
									</Button>
								</div>
							) : null}
						</div>
					)
				)}
				{params.tab === ETabsUsersDetails.CHANNELS && <SubTabsDetailsUser />}
				{params.tab === ETabsUsersDetails.CONVERSATION && <SubTabsConversations />}
				{params.tab === ETabsUsersDetails.SUBSCRIPTIONS && <SubscriptionTabs />}
				<div className="table-wrapper">{definePage()}</div>
			</div>
			{tableDate?.data && tableDate?.data.total > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(tableDate.data.total / 15)}
				/>
			) : null}
		</div>
	);
};

export default UserDetails;
