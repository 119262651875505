import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { TransactionAdminTreasury } from 'components/TransactionComponents/TransactionAdminTreasury';
import { TransactionHistory } from 'components/TransactionComponents/TransactionHistory';
import { TransactionSabTabs } from 'components/TransactionComponents/TransactionSabTabs';
import { TransactionTabs } from 'components/TransactionComponents/TransactionTabs';
import { TransactionWithdrawalsBody } from 'components/TransactionComponents/TransactionWithdrawals/TransactionWithdrawalsBody';
import { TransactionWithdrawalsHeader } from 'components/TransactionComponents/TransactionWithdrawals/TransactionWithdrawalsHeader';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getAdminTreasuryRequest, getWithdrawalRequest } from 'redux/reducers/transaction/reducer';
import {
	getLoaderSelector,
	getTreasurytSelector,
	getWithdrawalSelector,
} from 'redux/reducers/transaction/selectors';
import { ESubtabsTransaction, ETabsTransaction } from 'services/constants/lists-table';
import { IDataFiltter } from '../Channels';

const Transaction: FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const withdrawal = useSelector(getWithdrawalSelector);
	const treasuryt = useSelector(getTreasurytSelector);
	const loader = useSelector(getLoaderSelector);
	const [dataFiltter, setDataFiltter] = useState<any>({
		order_by: '',
		sort: '',
	});

	const pagination = params.tab === ETabsTransaction.WITHDRAWALS ? treasuryt : withdrawal;

	const submitFilter = (value: number) => {
		(params.tab === ETabsTransaction.WITHDRAWALS || !params.tab) &&
			dispatch(
				getWithdrawalRequest({
					type: 'withdrawal',
					page: value,
					...dataFiltter,
				}),
			);

		params.tab === ETabsTransaction.TRANSACTION_HISTORY &&
			dispatch(
				getWithdrawalRequest({
					page: value,
					...dataFiltter,
				}),
			);

		params.tab === ETabsTransaction.ADMIN_TREASURY &&
			dispatch(
				getAdminTreasuryRequest({
					page: value,
					...dataFiltter,
				}),
			);
	};

	useEffect(() => {
		submitFilter(+location.search.slice(1) || 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, dataFiltter]);

	const definePage = () => {
		switch (params.tab) {
			case ETabsTransaction.ADMIN_TREASURY:
				return (
					treasuryt?.data && (
						<TransactionAdminTreasury
							loader={loader}
							setDataFiltter={setDataFiltter}
							treasuryt={treasuryt.data}
						/>
					)
				);
			case ETabsTransaction.TRANSACTION_HISTORY:
				return (
					withdrawal?.data && (
						<TransactionHistory
							loader={loader}
							setDataFiltter={setDataFiltter}
							transactions={withdrawal.data}
						/>
					)
				);
			default:
				return (
					<div
						className={`table table--transactions-withdrawals${
							params.subTab === ESubtabsTransaction.CHANNELS ? '-channels' : ''
						}`}
					>
						<TransactionWithdrawalsHeader setDataFiltter={setDataFiltter} />
						{loader ? (
							<Loader />
						) : (
							withdrawal?.data && <TransactionWithdrawalsBody withdrawals={withdrawal.data} />
						)}
					</div>
				);
		}
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title"> Transactions </p>
				</div>
			</div>
			<TransactionTabs />
			<div className="table-block">
				{params.tab === ETabsTransaction.WITHDRAWALS && <TransactionSabTabs />}
				<div className="table-wrapper">{definePage()}</div>
			</div>
			{pagination?.page_count && pagination?.page_count > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={pagination.page}
					numberPage={Math.ceil(pagination.page_count / 15)}
				/>
			) : null}
		</div>
	);
};

export default Transaction;
