import { ArrowDownSvg, FilterSvg, PlusSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { reportList } from 'CONST/selectList';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { ChangeEvent, FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'ui/Button/Button';
import { SelectList } from 'ui/SelectList/SelectList';
import { DebounceInput } from 'react-debounce-input';

export const FilterAiBot: FC<{
	types?: Array<string>;
	status?: Array<string>;
	categories?: Array<string>;
	other?: Array<string>;
	typesName?: string;
	statusName?: string;
	otherName?: string;
	defaultSelectType: string;
	defaultSelectStatus: string;
	defaultSelectCategory: string;
	keyCategory: string;
	keyStatus: string;
	keyType: string;
	grid?: string;
	setDataFiltter?: (value: IDataFiltter) => void;
}> = ({
	types,
	status,
	categories,
	typesName,
	setDataFiltter,
	defaultSelectType,
	defaultSelectStatus,
	defaultSelectCategory,
}) => {
	const [search, setSearch] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);
	const [category, setCategory] = useState<string>('');
	const [type, setType] = useState<string>('');
	const [statusSelect, setStatusSelect] = useState<string>('');

	const { pathname } = useLocation();
	const navigate = useNavigate();

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		navigate({
			search: '1',
		});
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search: value,
			};
		});
	};

	const onSave = () => {
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search,
				status: statusSelect.replaceAll('_', ' ') || undefined,
				category: category.replaceAll('_', ' ') || undefined,
				type: type.replaceAll('_', ' ') || undefined,
			};
		});

		navigate({
			search: '1',
		});

		setOpen(false);
	};

	const onSelect = (name: string, id?: number | string) => {
		if (id === 'type') {
			setType(name.includes('reset') ? '' : name);
		} else if (id === 'status') {
			setStatusSelect(name.includes('reset') ? '' : name);
		} else {
			setCategory(name.includes('reset') ? '' : name);
		}
	};

	return (
		<div className="filter-wrapper filter-custom">
			<div className="filter-custom__inputs-block filter-custom__inputs-block--grid">
				<div className="wraper-report">
					<div onClick={() => setOpen(!open)} className="filter-custom__btn pointer">
						<div className="filter-custom__btn-name">
							<FilterSvg />
							<span>Filters</span>
						</div>
						<ArrowDownSvg />
					</div>
					{pathname.includes('reports') && (
						<SelectList data={reportList} maxWidth={'max-width'} defaultSelect="Export" />
					)}
				</div>
				<div className="active-btn">
					<div className="input input-custom input-search select-custom-width">
						<div className="input-wrapper">
							<DebounceInput
								className="input-item input-item--left-icon"
								placeholder="Search by name, username"
								debounceTimeout={500}
								value={search || ''}
								onChange={onSearch}
							/>
							<span className="input-icon">
								<SearchSvg />
							</span>
						</div>
					</div>
					{pathname.includes('monetization') && (
						<Button className="button-wrapper" type={'button'} name={'Add background'}>
							<PlusSvg />
						</Button>
					)}
				</div>
			</div>

			<div className={classNames('filter-custom__inputs-inner-custom', { 'open-custom': open })}>
				<div className={classNames('ai-bots__filter-wrap', { none: !open })}>
					<div className="ai-bots__select-list">
						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{typesName}</div>
							</div>
							<div>
								<div className="">
									<div className="">
										<div className="filter-custom__country-wrapper">
											<SelectList
												onSelect={onSelect}
												data={categories?.map((item) => item) || []}
												defaultSelect={defaultSelectCategory}
												keySelect="categoty"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{typesName}</div>
							</div>
							<div>
								<div className="">
									<div className="">
										<div className="filter-custom__country-wrapper">
											<SelectList
												onSelect={onSelect}
												data={types?.map((item) => item) || []}
												defaultSelect={defaultSelectType}
												keySelect="type"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{typesName}</div>
							</div>
							<div>
								<div className="">
									<div className="">
										<div className="filter-custom__country-wrapper">
											<SelectList
												onSelect={onSelect}
												data={status?.map((item) => item) || []}
												defaultSelect={defaultSelectStatus}
												keySelect="status"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button onClick={onSave} type="button" className="ai-bots__btn-save">
						Save
					</button>
				</div>
			</div>
		</div>
	);
};
