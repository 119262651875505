import { HorizenPointsSvg, NotVerifiedGraySvg, StarOffSvg, StarOnSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { FC, MouseEvent } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { IChannels } from 'redux/reducers/channels/types';
import { ICountry } from 'redux/reducers/users/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsChannel } from 'services/constants/lists-table';
import { ESlice } from 'services/constants/slice';
import { EChannelUpgradeType } from 'services/constants/type';
import { Avatar } from 'ui/Avatar/Avatar';
import { Popup } from 'ui/Popup/Popup';
import { SvgVerifiedAccount } from 'ui/svg-image/SvgAllChannels';
import { findCountry } from 'untils/findCountry';
import { numeralFunc } from 'untils/numerals';
import arrow_up from '../../../../assets/svg-icon/arrow-big-up.svg';
import arrow_down from '../../../../assets/svg-icon/arrow-big-down.svg';
import { NUMDEF } from 'CONST/cardName';

interface IProps {
	provided?: any;
	param: string;
	index: number;
	channel: IChannels;
	params: any;
	search: string;
	countriesList: null | Array<ICountry>;
	onChangeVerification: number | null;
	upgradeChannel: (event: MouseEvent, value: boolean) => void;
	setOnChangeVerification: (value: number | null) => void;
	onPopup: (value: number, index?: number) => void;
	createAvataar: (value: string) => string;
	popup: number;
	page?: number;
}

export const Table: FC<IProps> = ({
	provided,
	countriesList,
	params,
	search,
	param,
	index,
	channel,
	onChangeVerification,
	upgradeChannel,
	setOnChangeVerification,
	onPopup,
	createAvataar,
	popup,
	page,
}) => {
	return (
		<div
			ref={provided?.innerRef}
			{...provided?.draggableProps}
			{...provided?.dragHandleProps}
			className="tr"
		>
			{param === ETabsChannel.RECOMMENDED_CHANNELS && (
				<div className="td td-flex">
					<p className="td-hidden-name">№</p>
					{index < 10 && Number(page) <= 1 && (
						<svg
							className="pointer"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect x="2" y="6" width="12" height="1" fill="#929298" />
							<rect x="2" y="9" width="12" height="1" fill="#929298" />
						</svg>
					)}
					<span style={{ whiteSpace: 'nowrap' }}>{index + 1}</span>
				</div>
			)}

			<div className="td">
				<p className="td-hidden-name"> Avatar</p>
				{param === ETabsChannel.RECOMMENDED_CHANNELS ? (
					(index > 9 || !(index < 10 && String(channel.id).includes('empty'))) && (
						<div className="avatar avatar--s-size">
							{+channel?.photo_access_hash &&
							String(channel?.photo_access_hash) !== String(NUMDEF) ? (
								<Avatar hash={String(channel.photo_access_hash)} />
							) : (
								<div className="avatar-custom">
									<span>{createAvataar(channel?.title)}</span>
								</div>
							)}
						</div>
					)
				) : (
					<div className="avatar avatar--s-size">
						{+channel?.photo_access_hash &&
						String(channel?.photo_access_hash) !== String(NUMDEF) ? (
							<Avatar hash={String(channel.photo_access_hash)} />
						) : (
							<div className="avatar-custom">
								<span>{createAvataar(channel?.title)}</span>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Channel name</p>

				<Link
					to={`${ELinksName.CHANNEL_DETAIL_USER}/${channel.id}/${params.tab || 'all-channels'}`}
					className="link link--type2"
				>
					<Highlighter
						title={channel.title.length < ESlice.END ? '' : channel.title}
						highlightClassName="YourHighlightClass"
						searchWords={[search]}
						autoEscape={true}
						textToHighlight={
							channel.title.length < ESlice.END
								? channel.title
								: `${channel.title?.slice(ESlice.START, ESlice.END)} ...`
						}
					/>
				</Link>
			</div>
			<div className="td">
				<div className="td-hidden-name"> Channel URL</div>

				<Link
					to={`${ELinksName.CHANNEL_DETAIL_USER}/${channel.id}/${params.tab || 'all-channels'}`}
					className="link link--type2"
				>
					{channel?.link}
				</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name"> # Members </p>
				<p>{channel?.participant_count || ''}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p className="upper-text-custom">{channel.type}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created date</p>
				<div className="upper-text-custom">
					{channel.created_at?.slice(
						0,
						channel.created_at?.slice(0, channel.created_at.indexOf(':')).lastIndexOf(' '),
					)}
					<p className="upper-text-custom gray">
						{channel.created_at?.slice(channel.created_at.indexOf(',') + 6)}
					</p>
				</div>
			</div>
			{param !== ETabsChannel.RECOMMENDED_CHANNELS && (
				<div className="td td--right-custom">
					<p className="td-hidden-name">Subscrption cost</p>
					<p className="upper-text-custom">{+channel.cost ? numeralFunc(+channel.cost) : '-'}</p>
				</div>
			)}
			<div className="td td--right-custom td--right">
				<p className="td-hidden-name">Country</p>
				{param === ETabsChannel.RECOMMENDED_CHANNELS ? (
					(index > 9 || !(index < 10 && String(channel.id).includes('empty'))) && (
						<p className="upper-text-custom">{findCountry(countriesList, channel.country)}</p>
					)
				) : (
					<p className="upper-text-custom">{findCountry(countriesList, channel.country)}</p>
				)}
			</div>
			<div className="td td--right-custom td--right">
				<p className="td-hidden-name">Category</p>
				<p className="upper-text-custom">{channel.category}</p>
			</div>
			<div className="td td--right-custom">
				<p className="td-hidden-name">Age</p>
				<p className="upper-text-custom">{channel.adult ? '18+' : ''}</p>
			</div>
			<div className="td td--right td--right-custom td--left">
				<p className="td-hidden-name">Recommended</p>
				{param === ETabsChannel.RECOMMENDED_CHANNELS ? (
					(index > 9 || !(index < 10 && String(channel.id).includes('empty'))) && (
						<p className="upper-text-custom">
							{channel.recommended ? <StarOnSvg /> : <StarOffSvg />}
						</p>
					)
				) : (
					<p className="upper-text-custom">
						{channel.recommended ? <StarOnSvg /> : <StarOffSvg />}
					</p>
				)}
			</div>
			<div className="td td--right td--right-custom td--left">
				<p className="td-hidden-name"> Verified account</p>
				{param === ETabsChannel.RECOMMENDED_CHANNELS ? (
					(index > 9 || !(index < 10 && String(channel.id).includes('empty'))) &&
					(onChangeVerification === channel.id ? (
						<div className="vertified-butons">
							<button
								id={String(channel.id)}
								onClick={(event) => upgradeChannel(event, true)}
								name={EChannelUpgradeType.VERIFY}
								type="button"
								className="vertified-btn"
							>
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M2.625 8.625L6.99529 12.75L15.75 4.5"
										stroke="#27AE60"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
							<button
								name={EChannelUpgradeType.UNVERIFY}
								id={String(channel.id)}
								onClick={(event) => upgradeChannel(event, false)}
								type="button"
								className="vertified-btn"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19.0527 19.9471C19.248 20.1424 19.5646 20.1424 19.7598 19.9471C19.9551 19.7519 19.9551 19.4353 19.7598 19.24L19.0527 19.9471ZM5.3213 4.8015C5.12604 4.60624 4.80946 4.60624 4.61419 4.8015C4.41893 4.99676 4.41893 5.31335 4.61419 5.50861L5.3213 4.8015ZM4.61419 19.24C4.41893 19.4353 4.41893 19.7519 4.6142 19.9471C4.80946 20.1424 5.12604 20.1424 5.3213 19.9471L4.61419 19.24ZM19.7598 5.50861C19.9551 5.31335 19.9551 4.99676 19.7598 4.8015C19.5646 4.60624 19.248 4.60624 19.0527 4.8015L19.7598 5.50861ZM19.7598 19.24L5.3213 4.8015L4.61419 5.50861L19.0527 19.9471L19.7598 19.24ZM5.3213 19.9471L19.7598 5.50861L19.0527 4.8015L4.61419 19.24L5.3213 19.9471Z"
										fill="#EF4062"
									/>
								</svg>
							</button>
						</div>
					) : channel.verified ? (
						<button
							onClick={() => setOnChangeVerification(Number(channel.id))}
							type="button"
							className="vertified-btn"
						>
							<SvgVerifiedAccount />
						</button>
					) : (
						<button
							onClick={() => setOnChangeVerification(Number(channel.id))}
							type="button"
							className="vertified-btn"
						>
							<NotVerifiedGraySvg />
						</button>
					))
				) : onChangeVerification === channel.id ? (
					<div className="vertified-butons">
						<button
							id={String(channel.id)}
							onClick={(event) => upgradeChannel(event, true)}
							name={EChannelUpgradeType.VERIFY}
							type="button"
							className="vertified-btn"
						>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.625 8.625L6.99529 12.75L15.75 4.5"
									stroke="#27AE60"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<button
							name={EChannelUpgradeType.UNVERIFY}
							id={String(channel.id)}
							onClick={(event) => upgradeChannel(event, false)}
							type="button"
							className="vertified-btn"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.0527 19.9471C19.248 20.1424 19.5646 20.1424 19.7598 19.9471C19.9551 19.7519 19.9551 19.4353 19.7598 19.24L19.0527 19.9471ZM5.3213 4.8015C5.12604 4.60624 4.80946 4.60624 4.61419 4.8015C4.41893 4.99676 4.41893 5.31335 4.61419 5.50861L5.3213 4.8015ZM4.61419 19.24C4.41893 19.4353 4.41893 19.7519 4.6142 19.9471C4.80946 20.1424 5.12604 20.1424 5.3213 19.9471L4.61419 19.24ZM19.7598 5.50861C19.9551 5.31335 19.9551 4.99676 19.7598 4.8015C19.5646 4.60624 19.248 4.60624 19.0527 4.8015L19.7598 5.50861ZM19.7598 19.24L5.3213 4.8015L4.61419 5.50861L19.0527 19.9471L19.7598 19.24ZM5.3213 19.9471L19.7598 5.50861L19.0527 4.8015L4.61419 19.24L5.3213 19.9471Z"
									fill="#EF4062"
								/>
							</svg>
						</button>
					</div>
				) : channel.verified ? (
					<button
						onClick={() => setOnChangeVerification(Number(channel.id))}
						type="button"
						className="vertified-btn"
					>
						<SvgVerifiedAccount />
					</button>
				) : (
					<button
						onClick={() => setOnChangeVerification(Number(channel.id))}
						type="button"
						className="vertified-btn"
					>
						<NotVerifiedGraySvg />
					</button>
				)}
			</div>
			{param === ETabsChannel.RECOMMENDED_CHANNELS && (
				<div
					className={classNames('td td--right-custom pointer', { active: channel.id === popup })}
				>
					<Popup
						data={[index > 9 ? 'Add channel to top 10' : 'Remove channel from top 10']}
						onPopup={onPopup}
						svg={index <= 9 ? arrow_down : arrow_up}
						index={index}
						// isOpenModal={isOpenModal}
					/>
					<p className="td-hidden-name">Action</p>
					{param === ETabsChannel.RECOMMENDED_CHANNELS ? (
						(index > 9 || !(index < 10 && String(channel.id).includes('empty'))) && (
							<div
								onClick={() => {
									onPopup(Number(channel.id), index);
								}}
							>
								<HorizenPointsSvg />
							</div>
						)
					) : (
						<div
							onClick={() => {
								onPopup(Number(channel.id));
							}}
						>
							<HorizenPointsSvg />
						</div>
					)}
				</div>
			)}
		</div>
	);
};
