import { ArrowBackSvg } from 'assets/svg-icon';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { Details } from './Details/Details';
import { useDispatch, useSelector } from 'react-redux';
import {
	getReportPreviousRequest,
	getReportPreviousSuccess,
	getReportsDetailsRequest,
	getReportsDetailsSuccess,
} from 'redux/reducers/reports/reducer';
import { getPreviousSelector, getReportDetailsSelector } from 'redux/reducers/reports/selectors';
import { IPrivious } from 'redux/reducers/reports/types';

export const ReportsDetails: FC = () => {
	const params = useParams<{ tab: string; id: string }>();
	const dispatch = useDispatch();
	const reportDetails = useSelector(getReportDetailsSelector);
	const previous = useSelector(getPreviousSelector);

	useEffect(() => {
		dispatch(getReportsDetailsRequest(params.id || ''));
		return () => {
			dispatch(getReportsDetailsSuccess(null));
		};
	}, []);

	useEffect(() => {
		reportDetails?.channel.id &&
			dispatch(
				getReportPreviousRequest({
					payload: {
						current_page: 1,
						per_page: 15,
					},
					id: String(reportDetails?.channel.id) || '',
					page: params.tab || '',
				}),
			);
	}, [reportDetails]);

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap">
				<div className="title-block">
					<Link className="back-button" to={ELinksName.REPORTS}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">
						{params.tab &&
							`${params.tab[0].toUpperCase()}${params.tab
								?.replaceAll('-', ' ')
								.slice(1)
								.toLowerCase()}`}
					</p>
				</div>
			</div>
			{reportDetails?.report ? (
				<Details previous={previous || ({} as IPrivious)} reportDetails={reportDetails} />
			) : null}
		</div>
	);
};
