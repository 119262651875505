import { createBrowserRouter, FutureConfig } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import SuportRoutes from './SuportRoutes';

interface ExtendedFutureConfig extends FutureConfig {
	v7_startTransition: boolean;
}

export const router = createBrowserRouter([PrivateRoutes, PublicRoutes, SuportRoutes], {
	future: {
		v7_fetcherPersist: true,
		v7_normalizeFormMethod: true,
		v7_partialHydration: true,
		v7_relativeSplatPath: true,
		v7_skipActionErrorRevalidation: true,
		v7_startTransition: true,
	} as ExtendedFutureConfig,
});
