import { ITreasuryBody } from 'components/TransactionComponents/type';
import { FC } from 'react';

export const TreasuryBody: FC<ITreasuryBody> = ({ treasuryts }) => {
	return (
		<div className="table-body">
			{treasuryts.map((treasuryt) => (
				<div key={treasuryt.link} className="tr">
					<div className="td">
						<p className="td-hidden-name"> Wallet </p>
						<p>{treasuryt.wallet}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Available </p>
						<p>{`~${String(treasuryt.available)} USD`}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name"> Amount </p>
						<div className="table-buttons">
							<button
								type="button"
								className="button button--type5 button--small-width button--small-height"
							>
								<span className="button__icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.95605 10.4243L10.4248 2.95557"
											stroke="#0A49A5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7.43835 11.9243L8.18835 11.1743"
											stroke="#0A49A5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.12061 10.2428L10.6144 8.74902"
											stroke="#0A49A5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M2.75079 6.89944L6.90079 2.74944C8.22579 1.42444 8.88829 1.41819 10.2008 2.73069L13.2695 5.79944C14.582 7.11194 14.5758 7.77444 13.2508 9.09944L9.10079 13.2494C7.77579 14.5744 7.11329 14.5807 5.80079 13.2682L2.73204 10.1994C1.41954 8.88694 1.41954 8.23069 2.75079 6.89944Z"
											stroke="#0A49A5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M1.75 14.249H14.25"
											stroke="#0A49A5"
											strokeWidth="1.1"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								Manual Refill
							</button>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
