import { IPropsTreasuryHeader } from 'components/TransactionComponents/type';
import { FC, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const TreasuryHeader: FC<IPropsTreasuryHeader> = ({ setDataFiltter }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		setDataFiltter({ order_by: target.id, sort: target.name });
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p> Wallet</p>
						<div className="sort-td">
							<button
								// onClick={sortTable} id="user_id" name="asc"
								type="button"
							>
								<SvgArrowUpHeader />
							</button>
							<button
								// onClick={sortTable} id="user_id" name="desc"
								type="button"
							>
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Available</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Amount</p>
					</div>
				</div>
			</div>
		</div>
	);
};
