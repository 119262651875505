import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ITransactionState, ITreasuryResponse, IWithdrawalResponse } from './types';

const getTransactionState = (state: IStoreState): ITransactionState => state.transaction;

export const getWithdrawalSelector = createSelector(
	[getTransactionState],
	(transaction: ITransactionState): null | IWithdrawalResponse => transaction.withdrawal,
);

export const getTreasurytSelector = createSelector(
	[getTransactionState],
	(transaction: ITransactionState): null | ITreasuryResponse => transaction.treasury,
);

export const getLoaderSelector = createSelector(
	[getTransactionState],
	(transaction: ITransactionState): boolean => transaction.transactionsLoader,
);
