import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../AddNewTemplate/AddNewTemplate.scss';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getTimeZoneListSelector } from 'redux/reducers/ai-chat-bot/selectors';

export interface IAddDateAndTime {
	time_from: string;
	time_to: string;
	time_zone: string;
}

interface IProps {
	dateAndTimeStart?: string;
	dateAndTimeEnd?: string;
	gmto?: string;
	editAdvertise?: any;
	addDateAndTime?: (value: IAddDateAndTime) => void;
}

const TimeZoneFilter: React.FC<IProps> = ({
	editAdvertise,
	addDateAndTime,
	dateAndTimeStart,
	dateAndTimeEnd,
	gmto,
}) => {
	const [startDate, setStartDate] = useState<Date | null>(new Date(dateAndTimeStart || new Date()));
	const [startTime, setStartTime] = useState<Date | null>(new Date(dateAndTimeStart || new Date()));
	const [endDate, setEndDate] = useState<Date | null>(new Date(dateAndTimeEnd || new Date()));
	const [endTime, setEndTime] = useState<Date | null>(new Date(dateAndTimeEnd || new Date()));
	const [timezone, setTimezone] = useState<string>(gmto ? gmto : 'Pacific/Midway');
	const timeZoneList = useSelector(getTimeZoneListSelector);

	const handleTimezoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setTimezone(event.target.value);
	};

	useEffect(() => {
		editAdvertise?.({
			time_from: `${moment(startDate).format('YYYY-MM-DD')} ${moment(startTime).format(
				'hh:mm:ss',
			)}`,
			time_to: `${moment(endDate).format('YYYY-MM-DD')} ${moment(endTime).format('hh:mm:ss')}`,
			time_zone: timezone,
		});

		addDateAndTime?.({
			time_from: `${moment(startDate).format('YYYY-MM-DD')} ${moment(startTime).format(
				'hh:mm:ss',
			)}`,
			time_to: `${moment(endDate).format('YYYY-MM-DD')} ${moment(endTime).format('hh:mm:ss')}`,
			time_zone: timezone,
		});
	}, [startDate, startTime, endDate, endTime, timezone]);

	return (
		<div className="timezone-filter">
			<div className="date-picker-wrap">
				<DatePicker
					selected={startDate}
					onChange={(date) => {
						if (date && moment(date).isAfter(endDate)) {
							setEndDate(date); // Якщо нова початкова дата більша за кінцеву, синхронізуємо кінцеву дату з початковою
						}
						setStartDate(date);
					}}
					placeholderText="Start date"
					dateFormat="dd.MM.yyyy"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					minDate={new Date()} // Мінімально допустима дата
				/>
				<FaCalendarAlt className="icon" />
			</div>
			<div className="date-picker-wrap">
				<DatePicker
					selected={startTime}
					onChange={(time) => setStartTime(time)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={1}
					timeCaption="Time"
					dateFormat="h:mm:ss"
					placeholderText="Start time"
				/>
				<FaClock className="icon" />
			</div>
			<div className="date-picker-wrap end-date">
				<DatePicker
					selected={endDate}
					onChange={(date) => {
						if (date && moment(date).isBefore(startDate)) {
							alert('End date cannot be earlier than the start date.');
							return; // Якщо дата завершення менша за початкову, не оновлюємо стан
						}
						setEndDate(date);
					}}
					placeholderText="End date"
					dateFormat="dd.MM.yyyy"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					minDate={startDate} // Мінімально допустима дата
				/>
				<FaCalendarAlt className="icon" />
			</div>
			<div className="date-picker-wrap end-time">
				<DatePicker
					selected={endTime}
					onChange={(time) => setEndTime(time)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={1}
					timeCaption="Time"
					dateFormat="h:mm:ss"
					placeholderText="End time"
				/>
				<FaClock className="icon" />
			</div>
			<select value={timezone} onChange={handleTimezoneChange}>
				{timeZoneList?.map((zone) => (
					<option key={zone.id} value={zone.zone}>
						{zone.gmt}
					</option>
				))}
			</select>
		</div>
	);
};

export default TimeZoneFilter;
