import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'untils/notificationContainer';
import {
	addLoyatlyRequest,
	addUserPackageRequest,
	changeLoyatlyRequest,
	deleteLoyaltyRequest,
	deleteLoyaltySuccess,
	deleteUserPackageRequest,
	deleteUserPackageSuccess,
	getGraphIdRequest,
	getGraphIdSuccess,
	getGraphRequest,
	getGraphSuccess,
	getListLoyaltyRequest,
	getListUserPackageRequest,
	getLoyaltyUsersPartnerRequest,
	getPackageRequest,
	getSummaBonusesRequest,
	getSummaBonusesSuccess,
	getSummaPartnersRequest,
	getSummaPartnersSuccess,
	getSummaRequest,
	getSummaSuccess,
	putLoyaltyListSuccess,
	putLoyaltyUserPackageSuccess,
	putPackageSuccess,
	referralInitState,
	setChangeLoyatlySuccess,
	setLoyatlySuccess,
	setUserPackageSuccess,
} from './reducer';
import {
	IAddLoyatlyRequest,
	IChangeLoyatlyRequest,
	IGraphic,
	ILoyaltyLisREsponse,
	IReferral,
	IRequestGraph,
	IResponseGraph,
	ISummaRequest,
	ISummaResponse,
	LoyaltyUsersRequest,
} from './types';

function* getGraphRequestWorker(action: PayloadAction<IRequestGraph>) {
	const { payload } = action;

	try {
		const response: { data: IGraphic } = yield call(api.referral.getGraph, payload);
		yield put(getGraphSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getSummaRequestWorker(action: PayloadAction<ISummaRequest>) {
	const { payload } = action;

	try {
		const response: { data: ISummaResponse } = yield call(api.referral.getSumma, payload);
		yield put(getSummaSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getSummaBonusesRequestWorker(action: PayloadAction<ISummaRequest>) {
	const { payload } = action;

	try {
		const response: { data: ISummaResponse } = yield call(api.referral.getSumma, payload);
		yield put(getSummaBonusesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getSummaPartnersRequestWorker(action: PayloadAction<ISummaRequest>) {
	const { payload } = action;

	try {
		const response: { data: ISummaResponse } = yield call(api.referral.getSumma, payload);
		yield put(getSummaPartnersSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getGraphIdRequestWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		const response: { data: IResponseGraph } = yield call(api.referral.getGraphId, payload);
		yield put(getGraphIdSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* addLoyatlyWorker(action: PayloadAction<IAddLoyatlyRequest>) {
	const { payload } = action;

	try {
		const response: { data: ILoyaltyLisREsponse } = yield call(api.referral.addLoyatly, payload);
		yield put(setLoyatlySuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* changeLoyatlyWorker(action: PayloadAction<IChangeLoyatlyRequest>) {
	const { payload } = action;

	try {
		yield call(api.referral.changeLoyalty, payload);
		yield put(setChangeLoyatlySuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getListLoyaltyWorker(action: PayloadAction<IAddLoyatlyRequest>) {
	const { payload } = action;

	try {
		const response: { data: IReferral } = yield call(api.referral.userPackageAdd, payload);
		yield put(putLoyaltyListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* deleteLoyaltyWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.referral.deletePackage, payload);
		yield put(deleteLoyaltySuccess(payload));
	} catch (error) {
		yield notificationContainer('Discount not removed', 'error');
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* addUserPackageWorker(action: PayloadAction<IAddLoyatlyRequest>) {
	const { payload } = action;

	try {
		const response: { data: any } = yield call(api.referral.userPackageAdd, payload);
		yield put(setUserPackageSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* deleteUserPackageWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.referral.deleteUserPackage, payload);
		yield put(deleteUserPackageSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getListUserPackageWorker(action: PayloadAction<LoyaltyUsersRequest>) {
	const { payload } = action;

	try {
		const response: { data: any } = yield call(api.referral.getLoyaltyUsers, payload);
		yield put(putLoyaltyUserPackageSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getLoyaltyUsersPartnerWorker(action: PayloadAction<LoyaltyUsersRequest>) {
	const { payload } = action;

	try {
		const response: { data: any } = yield call(api.referral.getLoyaltyUsersPartner, payload);
		yield put(putLoyaltyUserPackageSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

function* getListPackageWorker() {
	try {
		const response: { data: any } = yield call(api.referral.getPackageList);
		yield put(putPackageSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(referralInitState());
	}
}

export function* referralSaga() {
	yield takeEvery(getGraphRequest.type, getGraphRequestWorker);
	yield takeEvery(getGraphIdRequest.type, getGraphIdRequestWorker);
	yield takeEvery(addLoyatlyRequest.type, addLoyatlyWorker);
	yield takeEvery(changeLoyatlyRequest.type, changeLoyatlyWorker);
	yield takeEvery(getListLoyaltyRequest.type, getListLoyaltyWorker);
	yield takeEvery(deleteLoyaltyRequest.type, deleteLoyaltyWorker);
	yield takeEvery(addUserPackageRequest.type, addUserPackageWorker);
	yield takeEvery(deleteUserPackageRequest.type, deleteUserPackageWorker);
	yield takeEvery(getListUserPackageRequest.type, getListUserPackageWorker);
	yield takeEvery(getLoyaltyUsersPartnerRequest.type, getLoyaltyUsersPartnerWorker);
	yield takeEvery(getPackageRequest.type, getListPackageWorker);
	yield takeEvery(getSummaRequest.type, getSummaRequestWorker);
	yield takeEvery(getSummaBonusesRequest.type, getSummaBonusesRequestWorker);
	yield takeEvery(getSummaPartnersRequest.type, getSummaPartnersRequestWorker);
}
