import React, { FC } from 'react';
import { Chart } from 'react-google-charts';

export const ColumnChart: FC<{ data: any; options?: any }> = ({ data, options = {} }) => {
	return (
		<div id="chart">
			<Chart
				chartVersion="51"
				chartType="Bar"
				width="100%"
				height="200px"
				data={data}
				options={options}
			/>
		</div>
	);
};
