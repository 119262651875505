import { ArrowBackSvg } from 'assets/svg-icon';
import { EditAIChatBotTabs } from 'components/AIChatBotComponents/EditAIChatBotTabs/EditAIChatBotTabs';
import { Main } from 'components/AIChatBotComponents/Main/Main';
import { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EEditAiChatBotTabs } from 'services/constants/lists-table';
import { Appearance } from 'components/AIChatBotComponents/Appearance/Appearance';
import { TextTemplate } from 'components/AIChatBotComponents/TextTemplate/TextTemplate';
import { ImageTemplate } from 'components/AIChatBotComponents/ImageTemplate/ImageTemplate';
import { Structure } from 'components/AIChatBotComponents/Structure/Structure';
import './EditAiChatBot.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	editBotRequest,
	editButtonsRequest,
	editCommandRequest,
	getBotDataRequest,
	getBotInfoRequest,
	getBotDataSuccess,
	getBotСategoryRequest,
	getTemplateTextRequest,
	getBotStructureInfoRequest,
	getTemplatePhotoRequest,
	deleteBotRequest,
	getCommandsInfoRequest,
	getBotStructureInfoSuccess,
	getAdvertiseListRequest,
	timeZoneListRequest,
	editAdvertiseRequest,
	getFrequencyRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';

import {
	getBotDataSelector,
	getCategoryBotSelector,
	getCommandsInfoSelector,
	getButtonsSelector,
	getTemplateTextSelector,
	getBotStructureInfoSelector,
	getTemplatePhotoSelector,
	getAdvertiseListSelector,
	getFrequencySelector,
} from 'redux/reducers/ai-chat-bot/selectors';
import { Loader } from 'components/Loader';
import { IButtons, ICommand, IEditBot } from 'redux/reducers/ai-chat-bot/types';
import { isChanged } from 'untils/isChangedObject';
import { validateUsername } from 'untils/validUsernamePattern ';
import { notificationContainer } from 'untils/notificationContainer';
import Recommendations from 'components/AIChatBotComponents/Recommendations/Recommendations';
import { IEntities } from 'ui/TextEditor/TextEditor';
import { arraysEqual } from 'untils/arraysEqual';

export interface IParams {
	[key: string]: string | any;
}

export interface IEditVerifidOrRecommend {
	recommended?: boolean;
	verified?: boolean;
}

export interface IEditBotInputs {
	nmae?: boolean;
	username?: boolean;
	description?: boolean;
	category?: boolean;
}

export interface IData {
	time_from: string;
	time_to: string;
	time_zone: string;
	title: string;
	file: any;
	advertise_id: null | number;
	entities: Array<IEntities> | [];
}

export const EditAiChatBot: FC = () => {
	const [modal, setModal] = useState(false);
	const [name, setName] = useState<string>('');
	const [userName, setUserName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [pandaDescription, setPandaDescription] = useState<string>('');
	const [category, setCategory] = useState<string>('');
	const params = useParams<IParams>();
	const categoryList = useSelector(getCategoryBotSelector);
	const buttons = useSelector(getButtonsSelector);
	const commands = useSelector(getCommandsInfoSelector);
	const structures = useSelector(getBotStructureInfoSelector);
	const listTemplateText = useSelector(getTemplateTextSelector);
	const listTemplatePhoto = useSelector(getTemplatePhotoSelector);
	const botInfo = useSelector(getBotDataSelector);
	const advertiseList = useSelector(getAdvertiseListSelector);
	const frequency = useSelector(getFrequencySelector);
	const [error, setError] = useState('');
	const [errorShown, setErrorShown] = useState(false);
	const [errorTimeout, setErrorTimeout] = useState(null);
	const [isModified, setIsModified] = useState(false);

	const [datAdvertise, setDatAdvertise] = useState<IData>({
		time_from: '',
		time_to: '',
		time_zone: '',
		title: '',
		file: '',
		advertise_id: null,
		entities: [],
	});

	const setDefaultTextInput = () => {
		setUserName(botInfo?.username || '');
		setDescription(botInfo?.description || '');
		setPandaDescription(botInfo?.panda_description || '');
		setName(botInfo?.name || '');
	};

	const dispatch = useDispatch();

	const onModal = () => {
		setModal(!modal);
	};

	const removeAiBot = () => {
		dispatch(deleteBotRequest(params.id));
	};

	// const editTabs = () => {

	// }

	const onEditVerifidOrRecommend = (value: IEditVerifidOrRecommend) => {
		botInfo && dispatch(editBotRequest({ ...botInfo, ...value }));
	};

	const editAdvertise = (data: any) => {
		setDatAdvertise((prev) => ({ ...prev, ...data }));
	};

	const onChangeInput = (event: ChangeEvent) => {
		const { name, value } = event.target as HTMLInputElement;
		if (value.length > 30 && (name === 'name' || name === 'username')) {
			return;
		}

		if (/[^a-zA-Z0-9\s]/.test(value) && (name === 'name' || name === 'username')) {
			if (!errorShown) {
				const timeout: any = setTimeout(() => {
					setErrorShown(false);
				}, 5000); // Затримка в 2 секунди
				setErrorTimeout(timeout);
				setErrorShown(true);

				notificationContainer('Use only Latin letters', 'info');
			}
			return;
		} else {
			setErrorShown(false);
			if (errorTimeout) {
				clearTimeout(errorTimeout);
			}
		}

		switch (name) {
			case 'name':
				validateUsername(setError, value, 'Name');
				setName(value);
				break;

			case 'username':
				validateUsername(setError, value, 'Username');
				setUserName(value);
				break;

			case 'description':
				if (value.length > 128) break;
				setDescription(value);
				break;

			case 'panda_description':
				if (value.length > 210) break;

				setPandaDescription(value);
				break;
			default:
		}
	};

	const onSelect = (value: string) => {
		setCategory(value);
		botInfo && dispatch(editBotRequest({ ...botInfo, category: value }));
	};

	const onEditBotInputs = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { code } = event;

		const data = {
			...botInfo,
			name: name || botInfo?.name,
			username: userName || botInfo?.username,
			description: description || botInfo?.description,
			panda_description: pandaDescription || botInfo?.panda_description,
			category: category || botInfo?.category,
		} as IEditBot;

		if (error && code === 'Enter') notificationContainer(error, 'error');

		code === 'Enter' &&
			!error &&
			botInfo &&
			isChanged(botInfo, data) &&
			dispatch(editBotRequest(data));
	};

	const onEditButton = (
		name: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => {
		const { code } = event;

		if (buttons && code === 'Enter') {
			const buttonsNew: Array<{
				buttons: Array<IButtons>;
				key: string;
				title: string;
			}> = buttons.buttons.map((button) => {
				if (button.key === key) {
					const result = button.buttons.map((btn) => {
						if (btn.option === option) {
							return { ...btn, name: name[String(option)] };
						}
						return btn;
					});
					return { ...button, buttons: result };
				}
				return button;
			});

			code === 'Enter' && dispatch(editButtonsRequest({ ...buttons, buttons: buttonsNew }));
		}
	};

	const onEditCommand = (
		command: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => {
		const { code } = event;

		if (commands && code === 'Enter') {
			const commandsNew: Array<ICommand> = commands.commands.map((item) => {
				if (item.key === key) {
					return { ...item, command: command[String(option)] };
				}
				return item;
			});

			dispatch(editCommandRequest({ ...commands, commands: commandsNew }));
		}
	};

	const onSave = useCallback(() => {
		const data = {
			...botInfo,
			name: name || botInfo?.name,
			username: userName || botInfo?.username,
			description: description || botInfo?.description,
			panda_description: pandaDescription || botInfo?.panda_description,
			category: category || botInfo?.category,
		} as IEditBot;

		// Виклик dispatch тільки якщо дані змінилися
		if (botInfo && isChanged(botInfo, data)) {
			dispatch(editBotRequest(data));
		}
	}, [
		botInfo,
		name,
		userName,
		pandaDescription,
		description,
		category,
		isChanged,
		dispatch,
		editBotRequest,
	]);

	const definePage = () => {
		switch (params.tab) {
			case EEditAiChatBotTabs.STRUCTURE:
				return (
					<Structure
						onEditCommand={onEditCommand}
						onEditButton={onEditButton}
						commands={commands}
						buttons={buttons}
						structures={structures}
					/>
				);
			case EEditAiChatBotTabs.APPEARANCE:
				return <Appearance botId={params.id} />;
			case EEditAiChatBotTabs.TEXT_TEMPLATE:
				return listTemplateText ? (
					<TextTemplate params={params} templates={listTemplateText} />
				) : null;
			case EEditAiChatBotTabs.IMAGE_TEMPLATE:
				return listTemplatePhoto ? (
					<ImageTemplate params={params} templates={listTemplatePhoto} />
				) : null;
			case EEditAiChatBotTabs.RECOMMENDATIONS:
				return advertiseList && frequency ? (
					<Recommendations
						frequency={frequency}
						advertiseList={advertiseList}
						editAdvertise={editAdvertise}
						datAdvertise={datAdvertise}
						isModified={isModified}
						onSaveEdit={onSaveEdit}
					/>
				) : null;
			default:
				return botInfo ? (
					<Main
						onEditVerifidOrRecommend={onEditVerifidOrRecommend}
						onChangeInput={onChangeInput}
						onEditBotInputs={onEditBotInputs}
						onSave={onSave}
						onSelect={onSelect}
						onModal={onModal}
						categoryList={categoryList}
						botInfo={botInfo}
						description={description}
						pandaDescription={pandaDescription}
						name={name}
						userName={userName}
						setDefaultTextInput={setDefaultTextInput}
					/>
				) : null;
		}
	};

	const onSaveEdit = () => {
		const formData = new FormData();
		formData.append('title', datAdvertise.title);
		formData.append('advertise_id', String(datAdvertise.advertise_id));
		if (datAdvertise.entities.length) {
			formData.append('entities', JSON.stringify(datAdvertise.entities));
		}
		formData.append('time_from', datAdvertise.time_from);
		formData.append('time_to', datAdvertise.time_to);
		formData.append('time_zone', datAdvertise.time_zone);

		if (datAdvertise.file[0]) {
			formData.append('file', datAdvertise.file[0]);
		}
		dispatch(editAdvertiseRequest({ ...datAdvertise, bot_id: Number(params.id), file: undefined }));
	};

	useEffect(() => {
		dispatch(getBotInfoRequest(params.id || ''));
		dispatch(getBotDataRequest(params.id || ''));
		dispatch(getBotСategoryRequest(params.id || ''));
		dispatch(getCommandsInfoRequest(params.id || ''));
		dispatch(timeZoneListRequest());

		return () => {
			dispatch(getBotDataSuccess(null));
		};
	}, []);

	useEffect(() => {
		params.tab === 'text-template' && dispatch(getTemplateTextRequest(params.id || ''));
		params.tab === 'image-template' && dispatch(getTemplatePhotoRequest(params.id || ''));
		params.tab === 'structure' && dispatch(getBotStructureInfoRequest(params.id || ''));

		if (params.tab?.includes(EEditAiChatBotTabs.RECOMMENDATIONS)) {
			dispatch(
				getAdvertiseListRequest({
					bot_id: Number(params.id),
					current_page: 1,
					per_page: 15,
				}),
			);
			dispatch(getFrequencyRequest(params.id || ''));
		}

		return () => {
			dispatch(getBotStructureInfoSuccess(null));
		};
	}, [params]);

	// Очищення таймерів при розмонтуванні компонента
	useEffect(() => {
		return () => {
			if (errorTimeout) {
				clearTimeout(errorTimeout);
			}
		};
	}, [errorTimeout]);

	// Check for changes in title or code
	useEffect(() => {
		const result = advertiseList?.advertise_list?.find(
			(value) => value.advertise_id === datAdvertise.advertise_id,
		);

		if (
			result?.title !== datAdvertise.title ||
			result?.time_from !== datAdvertise.time_from ||
			result?.time_to !== datAdvertise.time_to ||
			result?.time_zone !== datAdvertise.time_zone.trim() ||
			!arraysEqual(result.entities, datAdvertise.entities)
		) {
			setIsModified(true);
		} else {
			setIsModified(false);
		}
	}, [datAdvertise]);

	return (
		<div
			style={{
				paddingBottom: params.tab?.includes(EEditAiChatBotTabs.RECOMMENDATIONS) ? '80px' : '30px',
			}}
			className="main-content"
		>
			{modal && (
				<PopUpWrapper
					// value={String(wordId)}
					handleButton={removeAiBot}
					isOpenModal={onModal}
					title="Are you sure you want to delete this AI Bot?"
					btnName="Yes, I’m sure"
					disablet={'true'}
				/>
			)}
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="header-title code-generation-btn">
				<div className="title-block">
					<Link className="back-button" to={ELinksName.AI_CHAT_BOT}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">{params?.name.replaceAll('_', ' ')}</p>
				</div>
				{/* {datAdvertise.advertise_id ? (
					<button disabled={!isModified} onClick={onSaveEdit} className="button-save" type="button">
						Save
					</button>
				) : null} */}
			</div>
			<EditAIChatBotTabs params={params} shows={commands?.shows} />
			<div className="table-block">
				<div className="edit-bot">{botInfo || buttons ? definePage() : <Loader />}</div>
			</div>
		</div>
	);
};
