import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { IResponseTemplateText, ITemplateText } from 'redux/reducers/ai-chat-bot/types';
import { Input } from 'ui/Input';
import { Textarea } from 'ui/Textarea/Textarea';
import { useDispatch } from 'react-redux';
import {
	editTemplatePhotoRequest,
	editTemplateTextRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import { useParams } from 'react-router-dom';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';
import { WatchButton } from 'ui/WatchButton/WatchButton';

interface IProps {
	structure: ITemplateText;
	templates: IResponseTemplateText;
	text: string;
}

export const Accordion: FC<IProps> = ({ templates, structure, text }) => {
	const dispatch = useDispatch();
	const params = useParams();

	const [name, setName] = useState<string>(structure.name.slice(0, 30));
	const [desc, setDesc] = useState<string>(structure.prompt);
	const [open, setOpen] = useState<string>('');
	const [option] = useState<number>(structure.option);
	const [isModified, setIsModified] = useState(false);

	const handleInput = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;

		if (value.length > 30 && name === 'name') {
			return;
		}

		name === 'name' ? setName(value) : setDesc(value);
	};

	const onSave = () => {
		const data = {
			bot_id: templates.bot_id,
			templates: templates.templates.map((item) => {
				if (item.option === option) {
					return {
						name,
						prompt: desc,
						option,
					};
				}

				return item;
			}),
		};

		params.tab === 'image-template'
			? dispatch(editTemplatePhotoRequest({ data }))
			: dispatch(editTemplateTextRequest({ data }));
	};

	const deleteTemplate = () => {
		const data = {
			bot_id: templates.bot_id,
			templates: templates.templates.filter((item) => String(item.option) !== open),
		};

		params.tab === 'image-template'
			? dispatch(editTemplatePhotoRequest({ data, close: setOpen }))
			: dispatch(editTemplateTextRequest({ data, close: setOpen }));
	};

	// Check for changes in title or code
	useEffect(() => {
		if (name !== structure.name.slice(0, 30) || desc !== structure.prompt) {
			setIsModified(true);
		} else {
			setIsModified(false);
		}
	}, [name, desc, structure.name, structure.prompt]);

	return (
		<div className="accordion">
			<button
				className="accordion__delete"
				onClick={() => setOpen(String(structure.option))}
				type="button"
			>
				DELETE
			</button>
			<button disabled={!isModified} className="accordion__save" onClick={onSave} type="button">
				SAVE
			</button>

			{open ? (
				<PopUpAsk
					setText={setOpen}
					title={`Are you sure you want to delete this ${params.tab?.replace('-', ' ')}?`}
					btnNameOne="Yes, I’m sure"
					btnNameTwo="Cancel"
					onClick={deleteTemplate}
				/>
			) : null}
			<div className={`accordion__box${text ? `-${text}` : ''}`}>
				<div className="accordion__left-content">Title</div>
				<div className="accordion__right-content">
					{/* <Editor styles={{ minHeight: 'auto' }} /> */}
					<Input
						name={'name'}
						type={'text'}
						handleInput={handleInput}
						value={name}
						placeholder={'Name'}
					/>
					<span>{30 - name.length}</span>
				</div>
			</div>
			<div className={`accordion__box${text ? `-${text}` : ''}`}>
				<div className="accordion__left-content">Code</div>
				<div className="accordion__right-content">
					{/* <Editor styles={{ minHeight: 'auto' }} /> */}
					<Textarea
						handleTextarea={handleInput}
						name={'desc'}
						placeholder={'Template'}
						value={desc}
					/>
				</div>
			</div>
			{/* <div className="warning">To change the text in the field, press the enter button.</div> */}
			{/* {structure.code ? (
				<div className="accordion__box">
					<div className="accordion__left-content">{structure.code}</div>
					<div className="accordion__right-content">
						<Editor styles={{ minHeight: 'auto' }} />
					</div>
				</div>
			) : null} */}
		</div>
	);
};
