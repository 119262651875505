import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EditSvg } from 'assets/svg-icon';
import { role } from 'untils/formValidation';
import { IAdminsResponse } from 'redux/reducers/admins/types';
import moment from 'moment';

interface IProps {
	admins: Array<IAdminsResponse> | null;
}

export const AdminsBody: FC<IProps> = ({ admins }) => {
	const params = useParams();

	return (
		<div className="table-body">
			{admins?.map((admin) => (
				<div key={admin.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Name</p>
						<Link
							to={`${ELinksName.ADMINS_DETAILS}/${admin.email || ''}`}
							className="link link--type2"
						>
							{admin.first_name + ' ' + admin.last_name}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Email</p>
						<p>
							<Link
								to={`${ELinksName.ADMINS_DETAILS}/${admin.email || ''}`}
								className="link link--type2"
							>
								{admin.email}
							</Link>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Reason for blocking</p>
						<p>-</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Role</p>
						<p>{admin.role}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Status</p>
						<p>Blocked</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Block date</p>
						<p>-</p>
						<p className="gray"></p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Last active</p>
						<p>{admin.last_login ? moment(admin.last_login).format('MMM DD, YYYY') : null}</p>
						<p className="gray">
							{admin.last_login ? moment(admin.last_login).format('h:mm a') : null}
						</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<Link to={`${ELinksName.ADMINS_DETAILS}/${admin.email}`}>
							<EditSvg />
						</Link>
					</div>
				</div>
			))}
		</div>
	);
};
