import { FC, useEffect, useRef, useState } from 'react';
import { displayProtectedImage } from 'untils/getFile';
import { getTypeFile } from 'untils/getTypeFile';
import { onHttp } from 'untils/onHttp';

interface IProps {
	hash: string;
	endpoint?: string;
	type?: string;
	file_name?: string;
}

export const File: FC<IProps> = ({ hash, type, endpoint = '/storage/photo', file_name }) => {
	const refVideo = useRef<any>();
	const [loader, setLoader] = useState(false);
	const link = 'link';

	useEffect(() => {
		Number(hash) &&
			refVideo &&
			displayProtectedImage(
				refVideo,
				`${onHttp()}${endpoint}/${hash}`,
				localStorage.getItem('token') || '',
				setLoader,
				link,
			);
	}, [refVideo, hash]);

	return (
		<a style={{ display: 'flex', alignItems: 'center', gap: 10 }} ref={refVideo} download>
			{getTypeFile(file_name?.slice(file_name?.lastIndexOf('.') + 1) || '')}
			<span>{file_name}</span>
		</a>
	);
};
