import { FC, ChangeEvent, KeyboardEvent } from 'react';

interface IProps {
	className?: string;
	name: string;
	placeholder?: string;
	id?: string;
	handleTextarea: (event: ChangeEvent) => void;
	onKeyDownInput?: (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	title?: string;
	value: string;
}

export const Textarea: FC<IProps> = ({
	id,
	className,
	name,
	placeholder,
	title,
	handleTextarea,
	onKeyDownInput,
	value,
}) => {
	return (
		<div className="background__textarea-wrap">
			<p className="background__title">{title}</p>
			<textarea
				name={name}
				className={`background__textarea ${className}`}
				id={id}
				placeholder={placeholder}
				onChange={handleTextarea}
				onKeyDown={onKeyDownInput}
				value={value}
			/>
		</div>
	);
};
