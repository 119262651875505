import { IPropsWithdrawalsBody } from 'components/TransactionComponents/type';
import moment from 'moment';
import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
	putTransactionsApproveRequest,
	putTransactionsRejectRequest,
} from 'redux/reducers/transaction/reducer';
import { statusColors } from 'services/constants/colors';
import { SvgActionButtonDecline, SvgActionButtonAppruve } from 'ui/svg-image/SvgTransaction';

export const TransactionWithdrawalsBody: FC<IPropsWithdrawalsBody> = ({ withdrawals }) => {
	const dispatch = useDispatch();

	const changeTextCapitelReplace = (value: string) => {
		const newText = String(value?.split('_').join(' '));
		return newText;
	};

	const handleButtons = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		target.name === 'approve'
			? dispatch(putTransactionsApproveRequest({ transaction_id: +target.id }))
			: dispatch(putTransactionsRejectRequest({ transaction_id: +target.id }));
	};

	return (
		<div className="table-body">
			{withdrawals.map((withdrawal) => (
				<div key={withdrawal.id} className="tr">
					<div className="td">
						<p className="td-hidden-name"> User ID </p>
						<p>
							<a href="/" className="link link--type2">
								{withdrawal.user_id}
							</a>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> User email </p>
						<p>
							<a href="/" className="link link--type2">
								user@gmail.com
							</a>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Wallet balance </p>
						<p>{withdrawal.wallet_balance}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Request date </p>
						<p>{moment(withdrawal.updated_at).format('DD.MM.YYYY, h:mm:ss')}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Amount </p>
						<p>{withdrawal.amount}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Payment method </p>
						<p>{withdrawal.payment_method}</p>
					</div>
					<div className="td td--fw-500">
						<p className="td-hidden-name upper-custom"> Status </p>
						<p className={`${statusColors[withdrawal.status]} upper-custom`}>
							{changeTextCapitelReplace(withdrawal?.status)}
						</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						{/* {withdrawal.status === ETransaction.PENDING && ( */}
						<div className="table-buttons">
							<button
								onClick={handleButtons}
								id={String(withdrawal.id)}
								name="decline"
								type="button"
								className="btn-reletive"
							>
								<SvgActionButtonDecline />
								<span className="button-tooltip">Decline</span>
							</button>
							<button
								onClick={handleButtons}
								id={String(withdrawal.id)}
								name="approve"
								type="button"
								className="btn-reletive"
							>
								<SvgActionButtonAppruve />
								<span className="button-tooltip">Approve</span>
							</button>
						</div>
						{/* )} */}
					</div>
				</div>
			))}
		</div>
	);
};
