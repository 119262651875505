import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { SelectList } from 'ui/SelectList/SelectList';
import { numeralFunc } from 'untils/numerals';
import { ChartHeader } from 'components/UserPartnerComponent/ChartHeader/ChartHeader';
import { ChartBar } from 'components/UserPartnerComponent/Graphs/ChartBar';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { useParams } from 'react-router-dom';
import { UserPartnerHeader } from 'components/UserPartnerComponent/UserPartnerHeader/UserPartnerHeader';
import { Loader } from 'components/Loader';
import { UserPartnerBody } from 'components/UserPartnerComponent/UserPartnerBody/UserPartnerBody';
import { NotFound } from 'ui/NotFound/NotFound';
import { SearchSvg } from 'assets/svg-icon';
import { DebounceInput } from 'react-debounce-input';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';
import {
	changeLoyatlyRequest,
	deleteLoyaltyRequest,
	getGraphIdRequest,
	getListLoyaltyRequest,
	getListUserPackageRequest,
	getPackageRequest,
	getGraphRequest as getGraph,
	getSummaRequest,
} from 'redux/reducers/referral/reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
	getGraphPartnersSelector,
	getListUsersDiscount,
	getLoader,
	getPackages,
	getSummaSelector,
} from 'redux/reducers/referral/selectors';
import './Discount.scss';
import { IUser } from 'redux/reducers/users/types';
import { typeGraph } from 'CONST/typeFile';
import { onPackage } from 'untils/onPackage';

interface IProps {
	userDetails: IUser | undefined;
}

export const Discount: FC<IProps> = ({ userDetails }) => {
	const dispatch = useDispatch();
	const packages = useSelector(getPackages);
	const [activeBtn, setActiveBtn] = useState<string>('day');
	const [search, setSearch] = useState<string>('');
	const [discount, setDiscount] = useState<string>('');
	const [discActive, setDiscActive] = useState<string>('');
	const loader = useSelector(getLoader);
	const listUsers = useSelector(getListUsersDiscount);
	const graphPartners = useSelector(getGraphPartnersSelector);
	const summa = useSelector(getSummaSelector);
	const params = useParams<{
		subTab?: string;
		tab?: string;
		id?: string;
	}>();

	const [graphRequest, getGraphRequest] = useState({
		// limit: 0,
		// offset: 0,
		payment_system: 'paypal',
		period: 'day',
		type: 'deposit',
	});

	const onActivBtn = (event: MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setActiveBtn(name);
		getGraphRequest((prev) => ({ ...prev, period: name }));
	};

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
	};

	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({
		order_by: '',
		asc: undefined,
	});

	const onDiscount = (value: string) => {
		setDiscount(value);
	};

	const onSubmit = () => {
		const package_id = packages.findIndex((item) => {
			return item.toLowerCase() === discount.replaceAll('_', ' ');
		});

		if (!userDetails?.has_referral) {
			dispatch(
				getListLoyaltyRequest({
					loyalty_package_id: package_id === 0 ? 1 : package_id,
					user_id: Number(params.id),
				}),
			);
		} else {
			dispatch(
				changeLoyatlyRequest({
					package_id: package_id + 1,
					user_id: Number(params.id),
				}),
			);
		}

		setDiscount('');
	};

	useEffect(() => {
		dispatch(getPackageRequest());
	}, []);

	useEffect(() => {
		dispatch(
			getListUserPackageRequest({
				per_page: 15,
				current_page: 1,
				user_id: Number(params?.id) as number,
				search,
				...dataFiltter,
			}),
		);
	}, [search, dataFiltter]);

	useEffect(() => {
		dispatch(
			getGraph({
				group: typeGraph.indexOf(activeBtn),
				user_id: Number(params?.id),
			}),
		);
	}, [activeBtn]);

	useEffect(() => {
		userDetails?.referral_id &&
			dispatch(
				getSummaRequest({
					is_business: Number(userDetails?.referral_id) > 1 ? true : false,
					user_id: Number(params?.id),
				}),
			);
	}, []);

	return (
		<div className="discount">
			{discount ? (
				<PopUpAsk
					setText={setDiscount}
					onClick={onSubmit}
					title={'Package'}
					btnNameOne={'Change'}
					btnNameTwo={'Cancel'}
					subtitle={`Are you sure you want to change the user's package plan?`}
				/>
			) : null}
			<div className="discount__first-wrap">
				<h3 className="discount__title">Package</h3>
				{userDetails && (
					<SelectList
						onSelect={onDiscount}
						placeholder="Discount"
						data={packages}
						func={setDiscActive}
						defaultSelect={packages[(userDetails?.referral_id as number) - 1]}
						className="input-item--pr50"
					/>
				)}
				<div className="discount__desc">
					{discActive?.toLowerCase()?.replaceAll('_', ' ')[0]?.toUpperCase() +
						discActive?.toLowerCase()?.replaceAll('_', ' ').slice(1)}
				</div>
			</div>
			<div className="filter-custom__info-card padding-null">
				<div className="filter-custom__card">
					<p className="filter-custom__title color-grey">Total income received</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(summa?.Summa || 0)}`}</p>
				</div>
			</div>
			{userDetails?.has_referral && Number(userDetails.referral_id) > 1 && (
				<div className="platform-balances__graph-wrap">
					<ChartHeader name="Income received" onActivBtn={onActivBtn} activeBtn={activeBtn} />
					<ChartBar
						activeBtn={'Paid'}
						backgroundColor={'#27AE60'}
						labels={graphPartners?.graph?.map((item) => item.Date) || []}
						numbers={graphPartners?.graph?.map((item) => item.Total) || []}
					/>
				</div>
			)}
			{userDetails?.has_referral && Number(userDetails.referral_id) > 1 && (
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--users-partners">
							<div className="input input-custom input-search select-custom-width">
								<h3 className="discount__title2 discount__padding">Invited users</h3>
								<div className="input-wrapper discount__input-wrapper">
									<DebounceInput
										className="input-item input-item--left-icon"
										placeholder="Search by name or ID"
										debounceTimeout={500}
										value={search || ''}
										onChange={onSearch}
									/>
									<span className="input-icon">
										<SearchSvg />
									</span>
								</div>
							</div>
							<UserPartnerHeader setDataFiltter={setDataFiltter} />
							{loader ? (
								<Loader />
							) : listUsers?.loyally_full_users.length ? (
								<UserPartnerBody listUsers={listUsers?.loyally_full_users} search={''} />
							) : (
								<NotFound name={dataFiltter?.search || ''} />
							)}
						</div>
					</div>
				</div>
			)}
			{/* {!loader && usersList?.data && usersList?.data.total > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(usersList.data.total / 15)}
				/>
			) : null} */}
		</div>
	);
};
