import { Loader } from 'components/Loader';
import { FC } from 'react';
import { ITransactionAdminTreasury } from '../type';
import { TreasuryBody } from './TreasuryBody';
import { TreasuryHeader } from './TreasuryHeader';

export const TransactionAdminTreasury: FC<ITransactionAdminTreasury> = ({
	loader,
	setDataFiltter,
	treasuryt,
}) => {
	return (
		<div className="table table--admin-treasury">
			<TreasuryHeader setDataFiltter={setDataFiltter} />
			{loader ? <Loader /> : treasuryt && <TreasuryBody treasuryts={treasuryt} />}
		</div>
	);
};
