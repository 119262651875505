import { AiChatSvg, EditSvg } from 'assets/svg-icon';
import { NUMDEF } from 'CONST/cardName';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import { editBotTurnOfAndOnRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { IAiBots } from 'redux/reducers/ai-chat-bot/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { Avatar } from 'ui/Avatar/Avatar';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';

interface IProps {
	bot: IAiBots;
}

export const AiBot: FC<IProps> = ({ bot }) => {
	const [toggleFirst, setToggleFirst] = useState(bot.hidden);
	const dispatch = useDispatch();

	const handleToggleFirstChange = () => {
		setToggleFirst(!toggleFirst);
		dispatch(
			editBotTurnOfAndOnRequest({
				toggle: setToggleFirst,
				data: {
					bot_id: Number(bot?.id),
					enable: toggleFirst,
				},
			}),
		);
	};

	return (
		<div key={bot?.id} className="ai-bots__card">
			<div className="ai-bots__content">
				{bot.photo_access_hash && bot.photo_access_hash !== String(NUMDEF) ? (
					<div style={{ width: '52px' }}>
						<Avatar hash={String(bot.photo_access_hash)} />
					</div>
				) : (
					<AiChatSvg />
				)}
				<div className="ai-bots__blok-text">
					<div className="ai-bots__title">{bot.name}</div>
					<div className="ai-bots__table">
						<div className="ai-bots__list">
							<div className="ai-bots__item">
								<div className="ai-bots__type">Type:</div>
								<div className="ai-bots__type-name">---</div>
							</div>
							<div className="ai-bots__item">
								<div className="ai-bots__type">Username:</div>
								<div className="ai-bots__type-name">{firstUppercaseLetter(bot.username)}</div>
							</div>
							<div className="ai-bots__item">
								<div className="ai-bots__type">Bot category:</div>
								<div className="ai-bots__type-name">{bot.category || '----'}</div>
							</div>
							<div className="ai-bots__item">
								<div className="ai-bots__type">Bot verified status:</div>
								<div className={`ai-bots__type-name ${bot.verified ? 'green' : 'red'}`}>
									{bot.verified ? 'Verified' : 'Not verified'}
								</div>
							</div>
							<div className="ai-bots__item">
								<div className="ai-bots__type">Recommended status:</div>
								<div className="ai-bots__type-name">
									{bot.recommended ? 'recommended' : 'not recommended'}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ai-bots__action">
				<div className="user-data__toggle-wrapper left">
					<span className="user-data__toggle-text payment-methods__width">
						{toggleFirst ? 'Turned ON' : 'Turned OFF'}
					</span>
					<Toggle checked={!toggleFirst} icons={true} onChange={handleToggleFirstChange} />
				</div>
				<Link
					to={`${ELinksName.EDIT_BOT}/${bot.id}/${bot.name.replaceAll(' ', '_')}`}
					className="ai-bots__btn"
				>
					<EditSvg />
					<span className="ai-bots__btn-name">Edit</span>
				</Link>
			</div>
		</div>
	);
};
