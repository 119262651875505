import {
	IDeleteRequest,
	ILimitRequest,
	IUserRequest,
	IUsersRequest,
} from 'redux/reducers/users/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUsersApi } from './types';

export const users: IUsersApi = {
	usersList: (payload: IUsersRequest) =>
		http.post(endpoint.users.USERS_LIST, payload).then((response) => response.data),
	usersUpgrade: (payload) =>
		http.post(endpoint.users.USERS_VERIFY, payload).then((response) => response.data),
	getUser: (params: IUserRequest) =>
		http.get(endpoint.users.USER(params.user_id)).then((response) => response.data),
	blockUser: (payload) =>
		http.post(endpoint.users.BLOCK_USER, payload).then((response) => response.data),
	deleteUser: (payload: IDeleteRequest) =>
		http.post(endpoint.users.DELETE_USER, payload).then((response) => response.data),
	getPhotos: (params: IUserRequest) =>
		http.get(endpoint.users.PHOTOS_USER(params.user_id)).then((response) => response.data),
	getChannels: (param: IUserRequest) => {
		const params = {
			current_page: param?.current_page || 1,
			per_page: param.per_page || 8,
			search: param.search,
			user_types: param?.user_types || 0,
		};

		return http
			.get(endpoint.users.CHANNELS_USER(param.user_id), { params })
			.then((response) => response.data);
	},
	getGroups: (param: IUserRequest) => {
		const params = {
			current_page: param?.current_page || 1,
			per_page: param.per_page || 8,
			search: param.search,
			participiant_type: param?.participiant_type || 0,
		};

		return http
			.get(endpoint.users.GROUPS_USER(param.user_id), { params })
			.then((response) => response.data);
	},
	getTransaction: (param: IUserRequest) => {
		const params = {
			current_page: param?.current_page || 1,
			per_page: param.per_page || 8,
			search: param.search,
			date_from: param.date_from,
			date_to: param.date_to,
			order_by: param.order_by,
			type: param.types,
			asc: param.asc,
			status: param.status,
		};

		return http
			.get(endpoint.users.TRANSACTIONS_USER(param.user_id), { params })
			.then((response) => response.data);
	},
	setLimit: (payload: ILimitRequest) =>
		http.post(endpoint.users.LIMIT, payload).then((response) => response.data),
};
