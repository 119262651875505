import { FC } from 'react';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { IAppearanceData } from '../Appearance/data';
import { LogoHistoryCard } from './LogoHistoryCard';
import { IListLogo } from 'redux/reducers/ai-chat-bot/types';
import './LogoHistory.scss';

interface IProps {
	avaList: Array<IListLogo>;
	structure: IAppearanceData;
	botId: string;
}

export const LogoHistory: FC<IProps> = ({ avaList, structure, botId }) => {
	return (
		<div className="accordion__box accordion__box-2">
			<div className="appearance">
				<div className="appearance-title">{firstUppercaseLetter(structure.title)}</div>
				<div className="appearance-subtitle">{structure.subtitle}</div>
			</div>
			<div className="accordion__right-content">
				<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
					<div className="logo-history">
						{avaList.map((ava) => (
							<LogoHistoryCard
								key={ava.id}
								hash={ava.photo_access_hash}
								photoId={String(ava.id)}
								botId={botId}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
