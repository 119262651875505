import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubtabsTransaction, ETabsTransaction } from 'services/constants/lists-table';

export const TransactionTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.TRANSACTIONS}/${ETabsTransaction.WITHDRAWALS}/${ESubtabsTransaction.TRANSACTION}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsTransaction.WITHDRAWALS || !params.tab,
				})}
			>
				Withdrawals
			</Link>
			<Link
				to={`${ELinksName.TRANSACTIONS}/${ETabsTransaction.ADMIN_TREASURY}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsTransaction.ADMIN_TREASURY,
				})}
			>
				Admin Treasury
			</Link>
			<Link
				to={`${ELinksName.TRANSACTIONS}/${ETabsTransaction.TRANSACTION_HISTORY}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsTransaction.TRANSACTION_HISTORY,
				})}
			>
				Transaction history
			</Link>
		</div>
	);
};
