export async function fetchWithAuthentication(url: string, authToken: string) {
	const headers = new Headers();
	headers.set('Authorization', `Bearer ${authToken}`);
	try {
		return fetch(url, { headers });
	} catch (error) {
		console.log(error);
	}
}

async function setImg(
	ref: any,
	link: string | undefined,
	setLoader: (value: boolean) => void,
	response: Response | undefined,
) {
	if (response) {
		const blob = await response.blob();
		const objectUrl = window.URL.createObjectURL(blob);

		let result: Blob | null = null;

		if (blob.type.includes('heif') || blob.type.includes('heic')) {
			const heic2any = (await import('heic2any')).default;
			//@ts-ignore
			result = await heic2any({
				blob: blob,
				toType: 'image/jpeg',
			});
		}

		setLoader(false);

		if (ref.current) {
			// Якщо link передано, це, можливо, елемент <a>, і ми встановлюємо href
			if (link) {
				ref.current.setAttribute('href', objectUrl);
			} else {
				// В іншому випадку ми встановлюємо src для зображення <img>
				const srcUrl = result instanceof Blob ? window.URL.createObjectURL(result) : objectUrl;
				ref.current.setAttribute('src', srcUrl);
			}
		}
	}
}

export async function displayProtectedImage(
	ref: any,
	imageUrl: string,
	authToken: string,
	setLoader: (value: boolean) => void,
	link?: string,
) {
	setLoader(true);
	// Fetch the image.

	try {
		const response = await fetchWithAuthentication(imageUrl, authToken);

		if (!response?.ok) {
			const response = await fetchWithAuthentication(
				imageUrl.replace(
					imageUrl.includes('photo') ? 'photo' : 'document',
					imageUrl.includes('photo') ? 'document' : 'photo',
				),
				authToken,
			);

			setImg(ref, link, setLoader, response);
		} else {
			setImg(ref, link, setLoader, response);
		}
	} catch (error) {
		console.log(error);
	}
}
