import { ArrowBackSvg, PointsSvg } from 'assets/svg-icon';
import { ChangeEvent, FC, useState, MouseEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from 'ui/Input';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { Popup } from 'ui/Popup/Popup';
import { Tabs } from './Tabs/Tabs';
import './Term.scss';
import { SvgArrowDownDownload } from 'ui/svg-image/SvgAllChannels';
import zip from '../../../assets/images/zip.png';
import preview from '../../../assets/images/preview.png';
import { IEntities, TextEditor } from 'ui/TextEditor/TextEditor';
import { Drop } from 'ui/Drop/Drop';
import useOutsideClick from 'hooks/useOutsideClick';

export const TermManagementChange: FC = () => {
	const [popup, setPopup] = useState<number>(0);
	const [addLanguage, setAddLanguage] = useState<boolean>(false);
	const [namePopup, setNamePopup] = useState<string>('');
	const [name, setName] = useState<string>('');
	const ref = useRef<HTMLButtonElement | null>(null);
	const [entities, setEntities] = useState<Array<IEntities> | []>([]);
	const params = useParams();
	const navigate = useNavigate();
	const [description, setDescription] = useState('');

	useOutsideClick(ref, () => setPopup(0));

	const onPopup = (value: number) => {
		setPopup(value);
	};

	const onAddLanguage = () => {
		setAddLanguage(!addLanguage);
	};

	const isOpenModal = (event?: MouseEvent) => {
		const { id } = event?.target as HTMLButtonElement;

		setNamePopup(id);
	};

	const handleButton = () => {
		console.log('Add to Api');
	};

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		setName(value);
	};

	const handleDrop = () => {};

	return (
		<div className="main-content">
			{namePopup && (
				<PopUpWrapper
					isOpenModal={isOpenModal}
					handleButton={handleButton}
					title={
						namePopup === 'Delete'
							? `Are you sure you want to remove the language "${
									params.tab ? params.tab[0].toUpperCase() + params.tab.slice(1) : 'English'
							  }"?`
							: 'Preview'
					}
					btnName={namePopup === 'Delete' ? 'Yes, I’m sure' : ''}
				>
					{namePopup !== 'Delete' ? (
						<div style={{ width: '876px' }}>
							<img src={preview} alt="" />
						</div>
					) : null}
				</PopUpWrapper>
			)}

			{addLanguage && (
				<PopUpWrapper
					isOpenModal={onAddLanguage}
					handleButton={handleButton}
					title={'Add new language'}
					btnName={'Add'}
					disablet={name}
				>
					<div className="input-wrapper input-wrapper-custom input-wrapper-custom100">
						<div className="popup-box__label">Language name</div>

						<input value={name} onChange={handleInput} placeholder="Name" className="input-item" />
						<div className="popup-box__label gray-text">Language name to be displayed to users</div>

						<Drop
							handleChange={handleDrop}
							title="Drop Language file here"
							typeFile="(.tsv, .xls)"
						/>
					</div>
				</PopUpWrapper>
			)}
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap flex-content">
				<div className="title-block">
					<div className="back-button pointer" onClick={() => navigate(-1)}>
						<ArrowBackSvg />
					</div>
					<p className="title big-first-letter">Privacy Policy</p>
				</div>
				<div className="button-style-wrap tr">
					<div className={popup ? 'active' : ''} style={{ position: 'relative' }}>
						<button className="button-style" ref={ref} onClick={() => onPopup(1)} type="button">
							<PointsSvg />
						</button>
						<Popup
							data={['Preview', 'Delete']}
							onPopup={onPopup}
							isOpenModal={isOpenModal}
							className="popup-position"
						/>
					</div>
				</div>
			</div>
			<Tabs onAddLanguage={onAddLanguage} />
			<div className="table-block">
				<div className="term">
					<ul className="term__list">
						<li className="term__item">
							<span>Document title</span>
							<span>Privacy Policy</span>
						</li>
						<li className="term__item">
							<span>Link to web page</span>
							<span>https://policies.ello.com/egreg455</span>
						</li>
						<li className="term__item">
							<span>Last update date</span>
							<span>Sep 24, 2022, 3:06 PM </span>
						</li>
					</ul>
					<div className="track-details__footer">
						<p className="track-details__title-h3">Document</p>
						<div className="track-details__file-wrap">
							<div className="track-details__file-info">
								<img src={zip} alt="" className="track-details__img" />
								<div className="track-details__name">Name example.zip</div>
							</div>
							<SvgArrowDownDownload />
						</div>
					</div>
					<TextEditor
						setDescription={setDescription}
						setEntities={setEntities}
						entities={entities}
						defaultText={description}
						defaultEntities={[]}
					/>
					<div className="term__buttons">
						<button type="button" className="term__btn-save">
							Save
						</button>
						<button type="button" className="term__btn-public">
							Public
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
