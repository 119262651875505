import { AxiosError } from 'axios';
import { Navigate } from 'react-router-dom';
import { notificationContainer } from '../../untils/notificationContainer';
import { customResponseErrors } from './customResponseError/customResponseErrors';
import { ELinksName } from 'services/constants/list-links-sidebar';
// ==========================================:

const responseErrors = (error: any) => {
	const { response } = error;
	if (!response) {
		notificationContainer('Server error', 'error');
	}
	if (response) {
		const { status, data } = response;
		const errorList: Array<string> = [];
		if (data?.message) {
			errorList.push(data?.message);
		} else if (Array.isArray(data?.errors)) {
			data?.errors &&
				Object.keys(data?.errors)?.forEach((item) =>
					errorList?.push(data?.errors[item as keyof typeof data.errors]),
				);
		} else {
			data?.errors && errorList.push(data?.errors);
		}

		const errorMessage = errorList.length
			? errorList.flat()[0]
			: response.data.error ||
			  response.data.message ||
			  response.data.replaceAll(',[object Object]', '');

		switch (status) {
			case 400: // Bad request
				notificationContainer(errorMessage, 'error');
				break;

			// case 403: // Form validation
			// 	notificationContainer(errorMessage, 'error');
			// 	break;

			case 404: // Not found
				notificationContainer(errorMessage, 'error');
				break;
			case 412: // Not found
				notificationContainer(errorMessage, 'error');
				break;
			case 422: // Form validation
				customResponseErrors(errorMessage, 'info', response);
				break;

			case 429: // Too Many Attempts
				notificationContainer(String(`too_many_requests`), 'error');
				break;

			case 500: // Internal Server Error
				<Navigate to={ELinksName.ERROR} replace />;
				// notificationContainer(String(`server_error`), 'error');
				break;

			case 504: // Too Many Attempts
				notificationContainer('Gateway Timeout', 'error');
				break;

			default:
				break;
		}
	}
};

export { responseErrors };
