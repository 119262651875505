import { ITransactionBody } from 'components/TransactionComponents/type';
import { FC } from 'react';
import { statusColors } from 'services/constants/colors';

export const HistoryBody: FC<ITransactionBody> = ({ transactions }) => {
	return (
		<div className="table-body">
			{transactions.map((transaction) => (
				<div key={transaction.id} className="tr">
					<div className="td">
						<p className="td-hidden-name"> User ID </p>
						<p>
							<a href="/" className="link link--type2">
								{transaction.user_id}
							</a>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> User email </p>
						<p>
							<a href="/" className="link link--type2">
								user@gmail.com
							</a>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Wallet balance </p>
						<p>{transaction.wallet_balance}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name upper-custom"> Type </p>
						<p className="upper-custom">{transaction.type}</p>
					</div>
					<div className="td td--fw-500">
						<p className="td-hidden-name"> Status </p>
						<p className={`${statusColors[transaction.status]} upper-custom`}>
							{transaction.status.replaceAll('_', ' ')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Date </p>
						<p>{transaction.updated_at}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name"> Amount </p>
						<p>{transaction.amount}</p>
					</div>
				</div>
			))}
		</div>
	);
};
