import { IEntities } from 'ui/TextEditor/TextEditor';

// Функція для порівняння масивів
export const arraysEqual = (arr1: IEntities[], arr2: IEntities[]) => {
	if (arr1.length !== arr2.length) return false;

	return arr1.every((item, index) => {
		const otherItem = arr2[index];
		return (
			item.type === otherItem.type &&
			item.offset === otherItem.offset &&
			item.length === otherItem.length &&
			item.url === otherItem.url
		);
	});
};
