import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { ArrowDownGraySvg, ArrowUpGraySvg, EditSmallSvg } from 'assets/svg-icon';
import './Structure.scss';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { useDispatch } from 'react-redux';
import {
	getButtonsRequest,
	getCommandsRequest,
	getCommandsSuccess,
} from 'redux/reducers/ai-chat-bot/reducer';
import { useParams } from 'react-router-dom';
import { IButtonBot, ICommandAllData, IStructureRsponse } from 'redux/reducers/ai-chat-bot/types';
import { Accordion } from './Accordion/Accordion';
import { StructureList } from './StructureList/StructureList';

interface IProps {
	commands: ICommandAllData | null;
	buttons: IButtonBot | null;
	structures: IStructureRsponse | null;
	onEditButton: (
		name: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => void;
	onEditCommand?: (
		name: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => void;
}

export const Structure: FC<IProps> = ({
	onEditCommand,
	onEditButton,
	buttons,
	commands,
	structures,
}) => {
	const [open, setOpen] = useState<string>('');
	const dispatch = useDispatch();
	const params = useParams();
	const [input, setInput] = useState<{ [key: string]: string }>({});

	const onChange = (event: ChangeEvent) => {
		const { value, id } = event.target as HTMLInputElement;

		if (value.length > 30) {
			return;
		}

		setInput((prev) => ({ ...prev, [id]: value || ' ' }));
	};

	const handleButton = (event: MouseEvent) => {
		const { id } = event.currentTarget as HTMLDivElement;
		setOpen(id === open ? '' : id);
	};

	useEffect(() => {
		dispatch(getCommandsRequest(params.id || ''));
		dispatch(getButtonsRequest(params.id || ''));

		() => {
			dispatch(getCommandsSuccess(null));
		};
	}, []);

	return (
		<div className="structure">
			{buttons?.buttons?.map((button) => (
				<div key={button.key} className="structure__box">
					<div id={String(button.key)} onClick={handleButton} className="structure__header">
						<div className="structure__name">
							<div className="structure__line">
								<span></span>
								<span></span>
							</div>
							<span>{firstUppercaseLetter(button.title.replaceAll('_', ' '))}</span>
							<EditSmallSvg />
						</div>
						<div className="structure__arrow">
							{open === String(button.key) ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
						</div>
					</div>
					{open === String(button.key) ? (
						<Accordion onEditButton={onEditButton} structure={button.buttons} keyBtn={button.key} />
					) : null}
				</div>
			))}

			<div className="structure__box">
				<div id="comment" onClick={handleButton} className="structure__header">
					<div className="structure__name">
						<div className="structure__line">
							<span></span>
							<span></span>
						</div>
						<span>Commands</span>
						<EditSmallSvg />
					</div>
					<div className="structure__arrow">
						{open === 'comment' ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
					</div>
				</div>
				{open === 'comment' ? (
					<div className="accordion">
						{commands?.commands.map((command, index) => (
							<div key={index} className="accordion__box accordion__box-2">
								<div className="accordion__left-content">{`Command ${index + 1}`}</div>
								<label className="accordion__label">
									Name
									<input
										title="Press Enter to make changes"
										onChange={onChange}
										onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
											onEditCommand?.(input, index, command.key, event)
										}
										value={input[index] || command.command}
										id={String(index)}
										className="accordion__input"
										type="text"
									/>
									<span>{30 - (input[index]?.length || command.command?.length)}</span>
								</label>
							</div>
						))}
					</div>
				) : null}
			</div>
			{structures?.structure?.map((structure, index) => (
				<StructureList
					key={structure.key}
					structures={structures}
					keyStructure={open}
					structure={structure}
					handleButton={handleButton}
				/>
			))}
		</div>
	);
};
