import { Loader } from 'components/Loader';
import { FC } from 'react';
import { ITransactionHistory } from '../type';
import { HistoryBody } from './HistoryBody';
import { HistoryHeader } from './HistoryHeader';

export const TransactionHistory: FC<ITransactionHistory> = ({
	loader,
	setDataFiltter,
	transactions,
}) => {
	return (
		<div className="table table--transaction-history">
			<HistoryHeader setDataFiltter={setDataFiltter} />
			{loader ? <Loader /> : transactions && <HistoryBody transactions={transactions} />}
		</div>
	);
};
