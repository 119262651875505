import { AIChatBotTabs } from 'components/AIChatBotComponents/AIChatBotTabs/AIChatBotTabs';
import { AiBots } from 'components/AIChatBotComponents/AiBots/AiBots';
import { BotsStatistics } from 'components/AIChatBotComponents/BotsStatistics/BotsStatistics';
import { FilterAiBot } from 'components/AIChatBotComponents/FilterAiBot/FilterAiBot';
import { Pricing } from 'components/AIChatBotComponents/Pricing/Pricing';
import { RecommendedTable } from 'components/AIChatBotComponents/RecommendedTable/RecommendedTable';
import { Loader } from 'components/Loader';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	botPromoCodeListRequest,
	getAdvertiseListRequest,
	getAiChatBotsForCreateRequest,
	getAiChatBotsRequest,
	getBotСategoryRequest,
	getPartnersRequest,
	getPricingRequest,
	getTotalStatisticsRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import {
	getAiChatBotsForCreateSelector,
	getAiChatBotsSelector,
	getAiLoaderSelector,
	getBotPromoCodeSelector,
	getCategoryBotSelector,
	getPartnersSelector,
	getPricingSelector,
	getTotalStatisticsSelector,
} from 'redux/reducers/ai-chat-bot/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs } from 'services/constants/lists-table';
import { CreateBotList } from 'ui/CreateBotList/CreateBotList';
import { IDataFiltter } from '../Channels';
import { ListCodes } from 'components/AIChatBotComponents/ListCodes/ListCodes';
import { Pagination } from 'ui/Pagination';

export const AiChatBot: FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const botsListCode = useSelector(getBotPromoCodeSelector);
	const botsList = useSelector(getAiChatBotsSelector);
	const botsListForCreate = useSelector(getAiChatBotsForCreateSelector);
	const statistics = useSelector(getTotalStatisticsSelector);
	const pricing = useSelector(getPricingSelector);
	const categoryList = useSelector(getCategoryBotSelector);
	const partners = useSelector(getPartnersSelector);
	const loading = useSelector(getAiLoaderSelector);
	const [open, setOpen] = useState(false);
	const [onOpen, setOnOpen] = useState<boolean>(false);

	const [didUpdate, setDidUpdate] = useState(false);
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({});
	const [search, setSearch] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPagination, setTotalPagination] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(15);

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	const onClosePopup = () => {
		setOpen(!open);
	};

	const definePage = () => {
		if (params.tab?.includes(EAiChatBotTabs.PRICING)) {
			return pricing ? <Pricing pricing={pricing} dispatch={dispatch} /> : null;
		} else if (params.tab?.includes(EAiChatBotTabs.BOTS_STATISTICS)) {
			return statistics?.list ? <BotsStatistics statistics={statistics.list} /> : null;
		} else if (params.tab?.includes(EAiChatBotTabs.RECOMMENDED_AI_BOTS)) {
			return <RecommendedTable dataFiltter={dataFiltter} setDataFiltter={setDataFiltter} />;
		} else if (params.tab?.includes(EAiChatBotTabs.LIST_CODES)) {
			return (
				<ListCodes
					dataFiltter={dataFiltter}
					setDataFiltter={setDataFiltter}
					botPromoCodeList={botsListCode}
				/>
			);
		} else {
			return loading ? <Loader /> : <AiBots botsList={botsList?.ai_bots} />;
		}
	};

	useEffect(() => {
		dispatch(
			getAiChatBotsRequest({
				current_page: dataFiltter.search ? 1 : currentPage,
				per_page: 15,
				search: dataFiltter.search || undefined,
				...dataFiltter,
			}),
		);

		params.tab?.includes(EAiChatBotTabs.PRICING) && dispatch(getPricingRequest(params.id || ''));
		params.tab?.includes(EAiChatBotTabs.LIST_CODES) &&
			dispatch(
				botPromoCodeListRequest({
					current_page: currentPage,
					per_page: 15,
					...dataFiltter,
				}),
			);

		params.tab?.includes(EAiChatBotTabs.BOTS_STATISTICS) &&
			dispatch(
				getTotalStatisticsRequest({
					current_page: currentPage,
					per_page: 15,
				}),
			);
	}, [params.tab, currentPage, dataFiltter]);

	useEffect(() => {
		didUpdate &&
			dispatch(
				getAiChatBotsForCreateRequest({
					current_page: 1,
					per_page: perPage,
					search: search || undefined,
				}),
			);
		setDidUpdate(true);
	}, [search, perPage, onOpen]);

	useEffect(() => {
		setCurrentPage(1);
	}, [params.tab]);

	const onCreateBot = (event: MouseEvent) => {
		const { id } = event.target as HTMLLIElement;

		navigate(ELinksName.CREATE_BOT + '/' + id);
	};

	useEffect(() => {
		if (params.tab?.includes(EAiChatBotTabs.LIST_CODES)) {
			setTotalPagination(botsListCode?.total || 0);
		} else {
			setTotalPagination(botsList?.total || 0);
		}

		window.scrollTo(0, 0);
	}, [params, botsList, botsListCode]);

	useEffect(() => {
		dispatch(getBotСategoryRequest());
		dispatch(getPartnersRequest());
	}, [botsListCode]);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<div className="header-title code-generation-btn">
						<p className="title">AI Chat bot</p>
						<CreateBotList
							setSearch={setSearch}
							search={search}
							param={params.tab || ''}
							botsList={botsListForCreate}
							onCreateBot={onCreateBot}
							setPerPage={setPerPage}
							loading={loading}
							partners={partners?.partners || []}
							onOpen={onOpen}
							setOnOpen={setOnOpen}
						/>
					</div>
				</div>
				<AIChatBotTabs idBot={botsList?.ai_bots?.[1]?.id} />
			</div>

			<div className="table-block">
				{params?.tab?.includes('ai-bots') || !params.tab ? (
					<div>
						<FilterAiBot
							defaultSelectStatus="All Status"
							defaultSelectType="All Type"
							defaultSelectCategory="All Category"
							types={['-reset-', 'text', 'photo', 'document']}
							status={['-reset-', 'verified', 'no verified', 'no recommended', 'recommended']}
							categories={
								categoryList ? ['-reset-', ...categoryList.map((item) => item.category)] : []
							}
							setDataFiltter={setDataFiltter}
							keyStatus="status"
							keyType="types"
							keyCategory="category"
						/>
					</div>
				) : null}
				{loading ? (
					<div className="table-block">
						<Loader />
					</div>
				) : (
					definePage()
				)}
			</div>

			{totalPagination > 15 &&
			(params.tab?.includes(EAiChatBotTabs.LIST_CODES) ||
				params.tab?.includes(EAiChatBotTabs.AI_BOTS) ||
				!params.tab) ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(totalPagination / 15)}
				/>
			) : null}
		</div>
	);
};
