import { ChangeEvent, FC, useState } from 'react';
import './Pricing.scss';
import { IPricingConfig, IPricingResponse } from 'redux/reducers/ai-chat-bot/types';
import { DebounceInput } from 'react-debounce-input';
import { editPricingRequest } from 'redux/reducers/ai-chat-bot/reducer';

interface IProps {
	pricing: IPricingResponse;
	dispatch: (func: any) => void;
}

export const Pricing: FC<IProps> = ({ pricing, dispatch }) => {
	const initial: IPricingConfig = {
		img_default_quantity: pricing.img_default_quantity,
		img_default_subscription: pricing.img_default_subscription,
		img_price_per_quantity: pricing.img_price_per_quantity,
		img_price_subscription: pricing.img_price_subscription,
		text_default_quantity: pricing.text_default_quantity,
		text_default_subscription: pricing.text_default_subscription,
		text_price_per_quantity: pricing.text_price_per_quantity,
		text_price_subscription: pricing.text_price_subscription,
	};
	const [onInitial, setOnInitial] = useState<IPricingConfig>(initial);

	const onChangePricing = (event: ChangeEvent) => {
		const { name, value } = event.target as HTMLInputElement;
		const result = {
			...onInitial,
			[name]: +value,
		};
		setOnInitial(result);

		dispatch(
			editPricingRequest({
				prevState: setOnInitial,
				initial,
				request: {
					bot_id: pricing.bot_id,
					config: result,
				},
			}),
		);
	};

	return (
		<div className="pricing">
			<div className="pricing__card">
				<div className="pricing__header">
					<div className="pricing__header-title">Text requests</div>
				</div>
				<ul className="pricing__box-input-list">
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Price</div>
						<DebounceInput
							name="text_default_quantity"
							value={onInitial.text_default_quantity}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
							debounceTimeout={1000}
						/>
					</li>
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Number of requests in a pack</div>
						<input
							name="text_default_subscription"
							value={onInitial.text_default_subscription}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
				</ul>
			</div>

			<div className="pricing__card">
				<div className="pricing__header">
					<div className="pricing__header-title">Image requests</div>
				</div>
				<ul className="pricing__box-input-list">
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Price</div>
						<input
							name="img_default_quantity"
							value={onInitial.img_default_quantity}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Number of requests in a pack</div>
						<input
							name="img_default_subscription"
							value={onInitial.img_default_subscription}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
				</ul>
			</div>

			<div className="pricing__card">
				<div className="pricing__header">
					<div className="pricing__header-title">Image+ text unified pack</div>
				</div>
				<ul className="pricing__box-input-list">
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Price</div>
						<input
							name="img_price_per_quantity"
							value={onInitial.img_price_per_quantity}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Number of requests in a pack</div>
						<input
							name="img_default_quantity"
							value={onInitial.img_default_quantity}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Number of free requests</div>
						<input
							name="img_default_quantity"
							value={onInitial.img_default_quantity}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
				</ul>
			</div>

			<div className="pricing__card">
				<div className="pricing__header">
					<div className="pricing__header-title">Free requests management</div>
				</div>
				<ul className="pricing__box-input-list">
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Number of requests in a pack</div>
						<input
							name="text_price_subscription"
							value={onInitial.text_price_subscription}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
					<li className="pricing__box-input-item">
						<div className="pricing__input-name">Number of free requests</div>
						<input
							name="text_price_subscription"
							value={onInitial.text_price_subscription}
							type="number"
							className="pricing__input"
							onChange={onChangePricing}
						/>
					</li>
				</ul>
			</div>
		</div>
	);
};
