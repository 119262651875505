import { FieldProps } from 'formik';
import React, { FC } from 'react';
import './InputGray.scss';

interface CustomInputProps {
	type?: string;
	className?: string;
}

const InputGray: FC<CustomInputProps & FieldProps> = ({
	field,
	form: { touched, errors },
	type = 'text',
	className,
	...props
}) => {
	return (
		<div className={`input-gray ${className ? className : ''}`}>
			<div className="input-gray-wrapper">
				<input type={type} {...field} {...props} className="input-gray-item" />
				{/* {field.name === 'username' && (
					<div className="input-gray-actions">
						<div className="input-gray-info">
							<div className="input-gray-icon">
								<InformationSvg />
							</div>
							<span className="tooltip tooltip--bottom tooltip--center ">
								<span className="tooltip__text ">
									<span>{t('Forms.Sign_up.display_name_info')}</span>
								</span>
							</span>
						</div>
					</div>
				)} */}
			</div>
			{touched[field.name] && errors[field.name] && (
				<p className="input-notification input-notification--error">{String(errors[field.name])}</p>
			)}
		</div>
	);
};

export default InputGray;
