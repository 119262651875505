import { FC, useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import { SubTabs } from './SubTabs/SubTabs';
import { withdrawalCard } from 'CONST/cardName';
import { Fillter } from 'ui/Fillter/Fillter';
import { Loader } from 'components/Loader';
import { WithdrawalHeader } from './WithdrawalHeader/WithdrawalHeader';
import { useSelector, useDispatch } from 'react-redux';
import { WithdrawalBody } from './WithdrawalBody/WithdrawalBody';
import { useParams } from 'react-router-dom';
import { CryptoHeader } from './CryptoHeader/CryptoHeader';
import { CryptoBody } from './CryptoBody/CryptoBody';
import { IFiatResponse } from 'redux/reducers/finance/finance-types';
import { getFiatSelector, getLoaderSelector } from 'redux/reducers/finance/finance-selectors';
import { ModalWrapper } from 'ui/ModalWrapper/ModalWrapper';
import { CheckBoxTwo } from 'ui/CheckBoxTwo/CheckBoxTwo';
import { Checkbox } from 'ui/Checkbox/Checkbox';
import {
	getFinanceDetailsRequest,
	getFinanceDetailsSuccess,
	getPDFRequest,
	setWithdrawalRequest,
} from 'redux/reducers/finance/finance-reducer';
import { DetailsWithdrawal } from 'components/DetailsWithdrawal/DetailsWithdrawal';
import { NotFound } from 'ui/NotFound/NotFound';

interface IProps {
	fiat: null | IFiatResponse;
	setWithdrawalFiat: any;
}

export const WithdrawalRequests: FC<IProps> = ({ fiat, setWithdrawalFiat }) => {
	const loader = useSelector(getLoaderSelector);
	const detailsFiat = useSelector(getFiatSelector);
	const params = useParams();
	const dispatch = useDispatch();
	const [reasons, setReasons] = useState<string>('');
	const [action, setaction] = useState<string>('');
	const [transaction_id, setTransaction_id] = useState<string>('');
	const elem = document.querySelector('.modal-wrap');
	const [requestId, setRequestId] = useState('');

	const onRadioInput = (event: ChangeEvent) => {
		const { id } = event.target as HTMLInputElement;
		setReasons(id);
	};

	const [popup, setPopup] = useState(false);
	const [contentPopUp, setContentPopUp] = useState({
		nameBtn: '',
		title: '',
		subTitle: '',
	});

	const handlePopup = (event: MouseEvent) => {
		const { name, id } = event.currentTarget as HTMLButtonElement;
		name && setaction(name);
		setTransaction_id(id);
		setPopup(!popup);
		setContentPopUp(
			name === 'reject'
				? {
						nameBtn: 'Yes, I’m sure',
						title: 'Are you sure you want to reject this withdrawal request?',
						subTitle: 'Select the reason for reject*',
				  }
				: {
						nameBtn: 'Yes, I’m sure',
						title: 'Are you sure you want to confirm this transaction?',
						subTitle: '',
				  },
		);
	};

	const onSubmitWithdrawal = () => {
		const data = {
			action,
			reasons,
			transaction_id: +transaction_id,
		};

		dispatch(
			setWithdrawalRequest({
				data,
				onClose: setPopup,
			}),
		);
	};

	const onDetails = (id: string) => {
		dispatch(getFinanceDetailsRequest(id));
		setRequestId(id);
	};

	const closeDetails = () => {
		dispatch(getFinanceDetailsSuccess(null));
	};

	const onDownloadPdf = () => {
		dispatch(getPDFRequest(requestId));
	};

	useEffect(() => {
		return () => {
			setWithdrawalFiat({
				country_code: '',
				currencies: '',
				current_page: 1,
				date_from: '',
				date_to: '',
				per_page: 15,
				search: '',
				wallet_type: '',
				withdraw_method: '',
			});
		};
	}, []);

	return (
		<>
			{detailsFiat ? (
				<ModalWrapper
					onClosePopUp={closeDetails}
					nameBtn="Export PDF"
					title="Transaction Method Information"
					subTitle=""
					onSubmit={onDownloadPdf}
					loader={loader}
				>
					<DetailsWithdrawal item={detailsFiat} />
				</ModalWrapper>
			) : null}
			{popup ? (
				<ModalWrapper
					onClosePopUp={handlePopup}
					nameBtn={contentPopUp.nameBtn}
					title={contentPopUp.title}
					subTitle={contentPopUp.subTitle}
					onSubmit={onSubmitWithdrawal}
				>
					{contentPopUp.subTitle ? (
						<>
							<Checkbox
								id="Reason number one"
								name="request"
								type="radio"
								onChange={onRadioInput}
								title="Reason number one"
								// subtitle="Admins can add and remove users and manage channel-level settings."
							/>
							<Checkbox
								id="Reason number two"
								name="request"
								type="radio"
								onChange={onRadioInput}
								title="Reason number two"
								// subtitle="Admins can add and remove users and manage channel-level settings."
							/>
							<Checkbox
								id="Reason number three"
								name="request"
								type="radio"
								onChange={onRadioInput}
								title="Reason number three"
								// subtitle="Admins can add and remove users and manage channel-level settings."
							/>
							<Checkbox
								id="Reason number four"
								name="request"
								type="radio"
								onChange={onRadioInput}
								title="Reason number four"
								// subtitle="Admins can add and remove users and manage channel-level settings."
							/>
						</>
					) : null}
				</ModalWrapper>
			) : null}
			<SubTabs />
			<div className="container-finance">
				<div className="user-data__card-wrapper wrapper-card">
					{withdrawalCard.map((key) => (
						<div key={key} className="user-data__card">
							<div className="user-data__title">{key}</div>
							<div className="user-data__subtitle">
								{key.includes('sum')
									? `$${params.subTab === 'crypto' ? 0 : fiat?.total_sum_all_requests || 0}`
									: `${params.subTab === 'crypto' ? 0 : fiat?.total_count_all_requests || 0}`}
							</div>
						</div>
					))}
				</div>
			</div>
			<Fillter
				type={['PayPal', 'Bank Transfer']}
				status={['Main', 'Earnings']}
				typesName="Withdrawal method"
				setDataFiltter={setWithdrawalFiat}
				statusName="Wallet type"
				keyType="wallet_type"
				keyStatus="withdraw_method"
			/>
			<div className="table-wrapper">
				<div
					className={`table table--${
						params.subTab === 'fiat' ? 'withdrawal-all' : 'withdrawal-all-crypto'
					} table--channels-loader container-finance`}
				>
					{params.subTab === 'crypto' ? (
						<>
							<CryptoHeader />
							{loader ? <Loader /> : <CryptoBody />}
						</>
					) : (
						<>
							<WithdrawalHeader />
							{loader ? (
								<Loader />
							) : fiat?.requests?.length ? (
								<WithdrawalBody
									onDetails={onDetails}
									handlePopup={handlePopup}
									fiat={fiat?.requests}
								/>
							) : (
								<NotFound name={'withdrawal requests'} />
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};
