import { HeaderSuport } from 'components/HeaderSuport/HeaderSuport';
import { SidebarSupportLeft } from 'components/SidebarSupportLeft/SidebarSupportLeft';
import { SidebarSupportRight } from 'components/SidebarSupportRight/SidebarSupportRight';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
} from 'react-router-dom';
import { getIsAuth, getToken } from 'redux/reducers/auth/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	// getMassageRequest,
	getNotesRequest,
	getTypesRequest,
	getUsersRequest,
	removeListChats,
	subscribeAllRequest,
	ticketsRequest,
	changeStatus,
	onConect,
	setMassage,
} from 'redux/reducers/support/reducer';
import { getClient, getUsersSelector } from 'redux/reducers/support/selectors';
import { IUsersRequest } from 'redux/reducers/support/types';
import { getUserRequest } from 'redux/reducers/users/reducer';
import { setStatus, setTabSupport } from 'redux/reducers/app/reducer';
import { getTabNameSupport } from 'redux/reducers/app/selectors';
import { Centrifuge } from 'centrifuge';
import { onHttpCentrifuge } from 'untils/onHttp';
import { getTokenRefreshFatch } from 'untils/getTokenFatch';
import { onJsonParseBigin } from 'untils/jsonParse';
import { IsJsonString } from 'untils/isStringJson';
import { getUserDetails } from 'redux/reducers/users/selectors';
import { index } from 'untils/formValidation';
import { notificationContainer } from 'untils/notificationContainer';

type ContextType = {
	centrifuge: any;
};

interface IMassage {
	channel: string;
	data: {
		admin: boolean;
		input: string;
		admin_id: number;
	};
}

const SuportLayout: FC = () => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getIsAuth);
	const location = useLocation();
	const [openUser, setOpenUser] = useState<number>(0);
	const [hideLeft, setHideLeft] = useState(false);
	const [hideRight, setHideRight] = useState(false);
	const users = useSelector(getUsersSelector);
	const [filter, setFilter] = useState<IUsersRequest>({});
	const token = useSelector(getToken);
	const client = useSelector(getClient);
	const nameTabSupport = useSelector(getTabNameSupport);
	const navigate = useNavigate();
	const [fetching, setFetching] = useState<boolean>(false);
	const [offset, setOffset] = useState<number>(0);
	const [id_user, setId_user] = useState<number>(0);
	const userData = useSelector(getUserDetails);
	const { search } = useLocation();
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const [isScrollable, setIsScrollable] = useState(false);

	const centrifuge = new Centrifuge(onHttpCentrifuge() + 'wsapp', {
		token: token || localStorage.getItem('token') || '',
		getToken: getTokenRefreshFatch || '',
	});

	useEffect(() => {
		if (isAuth && token) {
			centrifuge.connect();

			centrifuge.on('connected', function (ctx: { client: string }) {
				dispatch(onConect(ctx.client));
				dispatch(subscribeAllRequest());
			});
		}

		return () => {
			centrifuge.disconnect();
		};
	}, []);

	useEffect(() => {
		if (isAuth && token) {
			centrifuge.on('publication', (message: IMassage) => {
				const result: any = IsJsonString(message?.data?.input)
					? onJsonParseBigin(message?.data?.input)
					: message?.data?.input;

				console.log(result?.in && result?.item);

				if (result?.in && result?.item) {
					dispatch(getUsersRequest({ ...filter, per_page: offset }));
				}

				if (message.channel === 'service') {
					if (result?.in?.userId !== userData?.data.id) {
						return;
					}

					if (!message?.data.admin_id) {
						const result: any = onJsonParseBigin(message?.data?.input);

						const idUserActiveChat = window.location.href.slice(
							window.location.href.indexOf('?') + 1,
						);

						// if (result?.userId === Number(idUserActiveChat)) {
						// 	console.log('document');

						// 	if (message?.data.admin_id) {
						// 		return;
						// 	}

						// 	dispatch(
						// 		setMassage({
						// 			admin: true,
						// 			message: result?.outMessage?.message,
						// 			hash: result?.document?.access_hash,
						// 			type: result?.document?.mime_type,
						// 		}),
						// 	);
						// 	return;
						// }

						if (result?.outMessage?.message) {
							result?.userId === Number(idUserActiveChat) &&
								dispatch(setMassage({ admin: false, message: result?.outMessage?.message }));
						}

						if (result?.document) {
							dispatch(
								setMassage({
									admin: true,
									message: result?.outMessage?.message,
									hash: result?.document?.access_hash,
									type: result?.document?.mime_type,
									file_name: result?.document?.attributes?.[0]?.file_name,
								}),
							);
						}

						// if (result?.outMessage?.message) {
						// 	// result?.userId === id_user && dispatch(getUsersRequest({}));

						// 	// dispatch(
						// 	// 	ticketsRequest({
						// 	// 		current_page: 1,
						// 	// 		per_page: 15,
						// 	// 		user_id: result?.in?.UserId,
						// 	// 	}),
						// 	// );
						// 	result?.userId === search.slice(1) &&
						// 		dispatch(setMassage({ admin: false, message: result?.outMessage?.message }));
						// }

						if (result?.media) {
							result?.user_id === +idUserActiveChat &&
								dispatch(
									setMassage({
										admin: false,
										type:
											result?.media?.photo_FLAGPHOTO?.predicate_name ||
											result?.media?.document?.predicate_name,
										message: result?.outMessage?.message || result?.media?.document?.message,
										mime_type:
											result?.media?.photo_FLAGPHOTO?.mime_type ||
											result?.media?.document?.mime_type,
										hash:
											result?.media?.photo_FLAGPHOTO?.access_hash ||
											result?.media?.document?.access_hash,
										file_name: result?.document?.attributes?.[0]?.file_name,
									}),
								);
						}
					}

					// if (message?.data.admin_id) {
					// 	dispatch(setMassage({ admin: true, message: result }));
					// }

					// dispatch(
					// 	ticketsRequest({
					// 		previous: nameTabSupport === 'Previos' ? true : false,
					// 		current_page: 1,
					// 		per_page: 15,
					// 		user_id: result?.in?.userId,
					// 	}),
					// );

					// if (result?.type || result?.type === 0) {
					// 	dispatch(changeStatus({ id: result.id as number, type: result.type }));
					// }
					if (result?.in?.outMessage) {
						// dispatch(getUsersRequest({}));
						// dispatch(
						// 	ticketsRequest({
						// 		current_page: 1,
						// 		per_page: 15,
						// 		user_id: result?.in?.UserId,
						// 	}),
						// );
						// dispatch(setMassage({ admin: false, message: result?.in?.outMessage?.message }));
					}
					// if (result?.outMessage?.message) {
					// 	dispatch(setMassage({ admin: false, message: result?.outMessage?.message }));
					// 	return;
					// }
					// dispatch(subscribeAllRequest());
				}
			});
		}
	}, [isAuth, userData]);

	const onOpenUser = (value: number, status: number | null = null, user_id: number) => {
		dispatch(removeListChats());
		setOpenUser(value === openUser ? 0 : value);
		dispatch(setTabSupport(value === openUser ? 0 : value));
		navigate({
			search: `?${user_id}`,
		});

		setId_user(user_id);

		dispatch(setStatus(status));
		value !== openUser &&
			dispatch(
				ticketsRequest({
					previous: nameTabSupport === 'Previos' ? true : false,
					current_page: 1,
					per_page: 15,
					user_id,
				}),
			);
		dispatch(
			getUserRequest({
				user_id: String(users?.data.find((user) => user.support_id === value)?.user_id) || '',
			}),
		);
		dispatch(getNotesRequest(String(value)));
	};

	const onFilter = (values: IUsersRequest) => {
		setFilter((prev) => ({ ...prev, ...values }));
	};

	const onSaveChange = () => {
		if (!filter?.country_code) {
			delete filter.country_code;
			delete filter.country_name;
		}

		if (!filter?.type_id) {
			delete filter.type_id;
		}

		dispatch(getUsersRequest(filter));
	};

	useEffect(() => {
		dispatch(getTypesRequest());
	}, []);

	useEffect(() => {
		if (!filter?.country_code) {
			delete filter.country_code;
			delete filter.country_name;
		}

		(filter?.search === '' || filter?.search) && dispatch(getUsersRequest(filter));
	}, [filter?.search]);

	useEffect(() => {
		fetching && dispatch(getUsersRequest({ ...filter, per_page: offset + 15 }));
		fetching && setOffset((prev) => prev + 15);
		setFetching(false);
	}, [fetching]);

	const handleScroll = (e: any) => {
		const container = scrollRef.current;

		if (container) {
			const { scrollTop, scrollHeight, clientHeight } = container;

			if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
				setFetching(true);
			}
		}
	};

	useEffect(() => {
		const checkScroll = () => {
			if (scrollRef.current) {
				const { scrollHeight, clientHeight } = scrollRef.current;
				// Якщо scrollHeight більший за clientHeight, контент можна прокручувати
				setIsScrollable(scrollHeight > clientHeight);
				if (!(scrollHeight > clientHeight)) {
					dispatch(getUsersRequest({ ...filter, per_page: offset + 15 }));
					setOffset((prev) => prev + 15);
				}
			}
		};

		// Викликаємо перевірку після рендерингу
		!isScrollable && checkScroll();

		if (users && offset < users.total) {
			document?.querySelector('.users__list')?.addEventListener('scroll', handleScroll);
		}
		return () =>
			document.querySelector('.users__list')?.removeEventListener('scroll', handleScroll);
	}, [users]);

	if (!isAuth) return <Navigate to={ELinksName.LOGIN} state={{ from: location }} replace />;

	return (
		<div className="wrapper wrapper-support">
			<HeaderSuport
				setHideLeft={setHideLeft}
				setHideRight={setHideRight}
				hideLeft={hideLeft}
				hideRight={hideRight}
				openUser={openUser}
				id_user={id_user}
			/>
			<main className="main">
				<section className="main-section">
					<SidebarSupportLeft
						setHideLeft={setHideLeft}
						hideLeft={hideLeft}
						activUser={openUser}
						onOpenUser={onOpenUser}
						users={users}
						onFilter={onFilter}
						onSaveChange={onSaveChange}
						offset={offset}
						setOffset={setOffset}
						scrollRef={scrollRef}
					/>
					{openUser ? (
						<Outlet context={{ centrifuge }} />
					) : (
						<div className="main-content chats-background"></div>
					)}
					{openUser ? <SidebarSupportRight hideRight={hideRight} openUser={openUser} /> : null}
				</section>
			</main>
		</div>
	);
};

export default SuportLayout;

export function useSuport() {
	return useOutletContext<ContextType>();
}
