import moment from 'moment';
import { FC, MouseEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IChannels } from 'redux/reducers/reports/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EReportsTabs, ETabsUsersDetails } from 'services/constants/lists-table';

interface IProps {
	reports: Array<IChannels>;
	param: string;
}

export const ReportsBody: FC<IProps> = ({ reports, param }) => {
	const navigate = useNavigate();

	const onLink = (event: MouseEvent, id_report: number) => {
		const target = event.target as HTMLDivElement;

		target.className !== 'link link--type2' &&
			navigate(`${ELinksName.REPORT_DETAILS}/${id_report}/${param || 'channel-report'}`);
	};

	return (
		<div className="table-body">
			{reports.map((report) => (
				<div
					style={{ cursor: 'pointer' }}
					onClick={(event: MouseEvent) => onLink(event, report.id)}
					key={report.id}
					className="tr"
				>
					<div className="td">
						<p className="td-hidden-name">User ID</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${report.user_id}/${'all-channels'}/${String(
								ETabsUsersDetails.USER_DATA,
							)}`}
							className="link link--type2"
						>
							{report.user_id}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Username</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${report.user_id}/${'all-channels'}/${String(
								ETabsUsersDetails.USER_DATA,
							)}`}
							className="link link--type2"
						>
							{report.username}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Channel</p>

						<Link
							to={`${ELinksName.CHANNEL_DETAIL_USER}/${report.channel_id}/all-channels`}
							className="link link--type2"
						>
							{report.channel}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Channel URL</p>
						<Link
							to={`${ELinksName.CHANNEL_DETAIL_USER}/${report.channel_id}/all-channels`}
							className="link link--type2"
						>
							{report.channel_url}
						</Link>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Type</p>
						<p>{report.type}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Total channel report</p>
						<p>{report.total_reports}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Date</p>
						<div>
							<p>{moment(report.created_at).format('MMM DD, YYYY')}</p>
							<p className="gray">{moment(report.created_at).format('h:mm A')}</p>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
