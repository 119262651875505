const validUsernamePattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{5,32}$/;

export const validateUsername = (
	setError: (value: string) => void,
	value: string,
	name: string,
) => {
	// Перевірка довжини
	if (value.length < 5 || value.length > 32) {
		const str = `${name} must be between 5 and 32 characters.`;
		setError(str);
	}
	// Перевірка наявності неприпустимих символів
	else if (!/^[a-zA-Z0-9]+$/.test(value)) {
		const str = `${name} must only contain letters and numbers.`;
		setError(str);
	}
	// Перевірка відповідності шаблону (щонайменше одна літера)
	else if (!validUsernamePattern.test(value)) {
		const str = `${name} must contain at least one letter and cannot consist only of numbers.`;
		setError(str);
	} else {
		setError(''); // Очищення помилки, якщо все вірно
	}
};
