import { fileTypes } from 'CONST/file-types';
import { CalendarSvg, DeleteSvg, DownloadSvg, PlusBlueSvg } from 'assets/svg-icon';
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Calendar } from 'ui/Calendar/Calendar';
import InputGray from 'ui/InputGray/InputGray';
import { SelectList } from 'ui/SelectList/SelectList';
import { TextareaField } from 'ui/TextareaField/TextareaField';
import zip from '../../../assets/images/zip.png';

const initialValues = {
	email: '',
	first_name: '',
	last_name: '',
	tittle: '',
	record_label: '',
	nickname: '',
};

export const EditTrackForms: FC<any> = ({ loginSubmit }) => {
	const [startDate, setStartDate] = useState<string>('');

	const [file, setFile] = useState<File | null>(null);

	const handleChange = (data: FileList) => {
		//@ts-ignore
		setFile(data);
	};

	const addDate = (date: Date | string, index: number) => {
		setStartDate(String(date));
	};

	return (
		<Formik
			initialValues={initialValues}
			// validationSchema={loginSchema}
			onSubmit={(values: any, { setSubmitting }) => {
				loginSubmit(values);
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, values }) => {
				return (
					<Form className="metadata__form-metadata form-metadata">
						<p className="text-black">Main info</p>

						<Field
							className="border-none"
							name="nickname"
							component={InputGray}
							value={values.nickname}
							placeholder={'Album Name'}
						/>

						<TextareaField
							className="border-none border-none-marg"
							name="description"
							placeholder={'Description'}
						/>

						<p className="text-gray">
							Adding a description to your track provides valuable context, allows listeners to
							better understand the inspiration, story, or emotions behind your music, and enhances
							their overall listening experience.
						</p>

						<p className="text-black">Partner information</p>

						<div className="form-metadata__group-input-row">
							<Field
								name="tittle"
								className="border-none"
								component={InputGray}
								value={values.tittle}
								placeholder={'Tittle*'}
							/>
							<Field
								className="border-none"
								name="record_label"
								component={InputGray}
								value={values.record_label}
								placeholder={'Record label*'}
							/>
						</div>
						<p className="text-black">Record label</p>
						<Field
							className="border-none"
							name="nickname"
							component={InputGray}
							value={values.nickname}
							placeholder={'Enter nickname or group name*'}
						/>
						<p className="text-gray">This field will be used as group or artist name</p>

						<div className="culendar-wrap culendar-wrap-width">
							<Calendar className="input-item-width" addDate={addDate} />
							<div className="culendar-svg-wrap">
								<CalendarSvg />
							</div>
						</div>

						<div className="form-metadata__group-input-padding border-marg">
							<SelectList className="not-background" placeholder="Key Tags*" data={['Test']} />
							<SelectList className="not-background" placeholder="Key Tags*" data={['Test']} />
							<button className="metadata__add-key-line metadata__add-padding-left" type="button">
								<PlusBlueSvg />
								<span>Add key tag(s)</span>
							</button>
						</div>

						<p className="text-black">Registration date</p>

						<div className="culendar-wrap culendar-wrap-width">
							<Calendar className="input-item-width" addDate={addDate} />
							<div className="culendar-svg-wrap">
								<CalendarSvg />
							</div>
						</div>

						<p className="text-black">YouTube video</p>

						<Field
							className="border-none"
							name="nickname"
							component={InputGray}
							value={values.nickname}
							placeholder={'Video link'}
						/>

						<p className="text-black">Copyright documents</p>

						<div className="drop-wrap">
							<div className="drop-wrap-solid">
								<FileUploader
									// classes="drop-custom"
									multiple={false}
									handleChange={handleChange}
									name="file"
									types={fileTypes}
								>
									<div className="file-uploader">
										<DownloadSvg />
										Upload file(s)
									</div>
								</FileUploader>
							</div>
						</div>

						<div className="registration__file-wrap">
							<div className="registration__file-inner">
								<img src={zip} alt="" />
								<span className="registration__file-name">Name example.zip</span>
							</div>
							<div style={{ display: 'flex', gap: '16px' }}>
								<DownloadSvg />
								<DeleteSvg />
							</div>
						</div>

						<div className="form-submit-flex">
							<button
								// disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="form-submit-flex__download form-submit-flex__btn-text"
							>
								Delete track
							</button>

							<button
								// disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="form-submit-flex__share form-submit-flex__btn-text"
							>
								Save
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
