import { IPropsWithdrawalsHeader } from 'components/TransactionComponents/type';
import { FC, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const HistoryHeader: FC<IPropsWithdrawalsHeader> = ({ setDataFiltter }) => {
	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		setDataFiltter({ order_by: target.id, sort: target.name });
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p> User ID</p>
						<div className="sort-td">
							<button onClick={sortTable} id="user_id" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="user_id" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> User email</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Wallet balance</p>
						<div className="sort-td">
							<button onClick={sortTable} id="wallet_balance" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="wallet_balance" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Type</p>
						<div className="sort-td">
							<button onClick={sortTable} id="payment_method" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="payment_method" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Status</p>
						<div className="sort-td">
							<button onClick={sortTable} id="status" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="status" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="request_date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="request_date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p> Amount</p>
						<div className="sort-td">
							<button onClick={sortTable} id="amount" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="amount" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
