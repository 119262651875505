import React, { MouseEvent, useState } from 'react';
import recommendationImage from '../../../assets/images/Recommendations.png';
import './Recommendations.scss';
import AddNewTemplate from './AddNewTemplate/AddNewTemplate';
import { Accordion } from './Accordion/Accordion';
import { ArrowDownGraySvg, ArrowUpGraySvg } from 'assets/svg-icon';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { IFrequenc, IResponseAdvertiseList } from 'redux/reducers/ai-chat-bot/types';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { editFrequencyRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { useParams } from 'react-router-dom';
import { IData } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';

interface IProps {
	advertiseList: IResponseAdvertiseList;
	editAdvertise: any;
	frequency: IFrequenc;
	datAdvertise: IData;
	isModified: boolean;
	onSaveEdit: () => void;
}

const Recommendations: React.FC<IProps> = ({
	onSaveEdit,
	isModified,
	datAdvertise,
	frequency,
	advertiseList,
	editAdvertise,
}) => {
	const [open, setOpen] = useState(false);
	const [аrequency, setFrequency] = useState(frequency.frequency);
	const [openAccordion, setOpenAccordion] = useState('');
	const dispatch = useDispatch();
	const params = useParams();

	const onClose = () => {
		setOpen(false);
	};

	const handleButton = (event: MouseEvent) => {
		const { id } = event.currentTarget as HTMLDivElement;
		setOpenAccordion(id === openAccordion ? '' : id);
	};

	const handleTimezoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setFrequency(Number(event.target.value));
		dispatch(
			editFrequencyRequest({
				bot_id: Number(params.id),
				frequency: Number(event.target.value),
				state: true,
			}),
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
			<div className="structure__box">
				<div className="structure__header">
					<div className="structure__name frequency-title">Display frequency</div>
					<div className="frequency-wrapper">
						<span>Frequency of offer display:</span>
						<select value={аrequency} onChange={handleTimezoneChange}>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((num) => (
								<option key={num} value={num}>
									{num}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			{open && <AddNewTemplate editAdvertise={editAdvertise} onClose={onClose} />}
			{advertiseList.total ? (
				advertiseList.advertise_list.map((advertise, index) => (
					<div key={advertise.advertise_id} className="structure__box">
						<div
							id={String(advertise.advertise_id)}
							onClick={handleButton}
							className="structure__header"
						>
							<div className="structure__name">
								<span>Recommendation</span>
								<span className="structure__line">{`${index + 1}`}</span>
							</div>
							<div className="structure__arrow">
								{openAccordion === String(advertise.advertise_id) ? (
									<ArrowUpGraySvg />
								) : (
									<ArrowDownGraySvg />
								)}
							</div>
						</div>
						{openAccordion === String(advertise.advertise_id) ? (
							<Accordion
								templates={advertiseList.advertise_list}
								advertise={advertise}
								text={'text'}
								editAdvertise={editAdvertise}
								datAdvertise={datAdvertise}
								isModified={isModified}
								onSaveEdit={onSaveEdit}
							/>
						) : null}
					</div>
				))
			) : (
				<div className="recommendations">
					<div className="no-recommendations">
						<img src={recommendationImage} alt="recommendation image" />
						<span>no recommendations</span>
					</div>
				</div>
			)}
			<button type="button" className="btn-add-recommendation">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
				>
					<path
						d="M14.25 9H3.75M9 14.25L9 3.75"
						stroke="#0A49A5"
						strokeWidth="1.5"
						strokeLinecap="round"
					/>
				</svg>
				<span onClick={() => setOpen(true)}>Add New Template</span>
			</button>
		</div>
	);
};

export default Recommendations;
