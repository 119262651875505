import { NUMDEF } from 'CONST/cardName';
import { FC } from 'react';
import { Avatar } from 'ui/Avatar/Avatar';

export const Image: FC<{ photo_access_hash: number }> = ({ photo_access_hash }) => {
	return (
		<div>
			{Number(photo_access_hash) && String(photo_access_hash) !== String(NUMDEF) ? (
				<Avatar hash={String(photo_access_hash)} />
			) : null}
		</div>
	);
};
