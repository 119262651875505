import { DownloadSvg } from 'assets/svg-icon';
import { ChangeEvent, FC, useState } from 'react';
import { DropFile } from 'ui/DropFile/DropFile';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { IAppearanceData } from '../data';

interface IProps {
	structure: IAppearanceData;
	onUpload?: (data: File, name: string) => void;
	nameFile?: string;
	size?: number;
	loader?: boolean;
}

export const Accordion: FC<IProps> = ({ structure, onUpload, nameFile, size, loader }) => {
	const handleChange = (data: any, name: string) => {
		const f = data;
		onUpload?.(data, name);
	};

	return (
		<div>
			<div className="accordion__box accordion__box-2">
				<div className="appearance">
					<div className="appearance-title">{firstUppercaseLetter(structure.title)}</div>
					<div className="appearance-subtitle">{structure.subtitle}</div>
				</div>
				<div className="accordion__right-content">
					<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
						<DropFile
							photo={structure.avatar}
							svg={<DownloadSvg />}
							size={size}
							nameFile={nameFile}
							handleChange={(file: any) => handleChange(file, structure.name)}
							loader={loader}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
