import { ArrowDownSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { useSelect } from 'hooks/useSelect';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { IUsersRequest } from 'redux/reducers/support/types';

interface IProps {
	data: Array<any>;
	maxWidth?: string;
	placeholder?: string;
	onSelect?: (value: string, id?: number | string) => void;
	defaultSelect?: string;
	onFilter?: (values: IUsersRequest) => void;
	nameKey?: string;
	keySelect?: string;
	id?: number;
	className?: string;
	func?: (value: string) => void;
	onImport?: () => void;
	label?: string;
}

export const SelectList: FC<IProps> = ({
	defaultSelect,
	data,
	maxWidth,
	onSelect,
	placeholder = 'Country',
	onFilter,
	nameKey,
	keySelect,
	id,
	className,
	onImport,
	func,
	label,
}) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const [text, setText] = useState<string>(defaultSelect || data[0]?.name);

	const handleSelect = (event: MouseEvent, index: number) => {
		const { name } = event.target as HTMLInputElement;
		setText(name);
		setOpen(false);
		onSelect?.(name?.toLowerCase().replaceAll(' ', '_'), id || keySelect);
		nameKey && onFilter?.({ [nameKey]: index });
		onImport?.();
	};

	const svg = data?.find((el) => el?.name === text);

	useEffect(() => {
		func?.(text || defaultSelect || '');
	}, [text]);

	return (
		<div className={`select-custom max-width-persent input-wrapper-custom100 ${maxWidth}`}>
			<div className={classNames('select', { active: open })}>
				<div className="input input-custom">
					{label ? (
						<label className="popup-box__label" htmlFor="">
							{label}
						</label>
					) : null}
					<div ref={triggerRef} onClick={toggleOpen} className="input-wrapper pointer">
						{svg?.svg && (
							<div className="input-icon input-icon--bigger input-icon--left">
								<svg.svg />
							</div>
						)}
						<input
							readOnly
							className={`input-item input-item--left-icon input-item--left-icon-none ${
								svg?.svg ? 'input-padding-left' : ''
							} ${className ? className : ''}`}
							type="text"
							name="country"
							value={text}
							placeholder={placeholder}
						/>
						<div className="input-icon input-icon--bigger input-icon--right">
							<ArrowDownSvg />
						</div>
					</div>
				</div>
				<div className="select__drop select__drop-position" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{data.map((el, index) => (
									<li key={el + index} className="countries-list">
										<button
											id={String(index + 1)}
											className="select__svg"
											name={el?.name || el}
											onClick={(event: MouseEvent) => handleSelect(event, index)}
											type="button"
										>
											{el?.svg && (
												<div className="">
													<el.svg />
												</div>
											)}
											{el?.name || el}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
