export const SvgActionButtonDecline = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.39 4.2373L10.065 2.4373C9.76504 2.1373 9.09004 1.9873 8.64004 1.9873H5.79004C4.89004 1.9873 3.91504 2.6623 3.69004 3.5623L1.89004 9.0373C1.51504 10.0873 2.19004 10.9873 3.31504 10.9873H6.31504C6.76504 10.9873 7.14004 11.3623 7.06504 11.8873L6.69004 14.2873C6.54004 14.9623 6.99004 15.7123 7.66504 15.9373C8.26504 16.1623 9.01504 15.8623 9.31504 15.4123L12.39 10.8373"
			stroke="#EF4062"
			strokeMiterlimit="10"
		/>
		<path
			d="M16.2151 4.2375V11.5875C16.2151 12.6375 15.7651 13.0125 14.7151 13.0125H13.9651C12.9151 13.0125 12.4651 12.6375 12.4651 11.5875V4.2375C12.4651 3.1875 12.9151 2.8125 13.9651 2.8125H14.7151C15.7651 2.8125 16.2151 3.1875 16.2151 4.2375Z"
			stroke="#EF4062"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export const SvgActionButtonAppruve = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.625 8.625L6.99529 12.75L15.75 4.5"
			stroke="#27AE60"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
