import { ArrowDownGraySvg, ArrowUpGraySvg, EditSmallSvg } from 'assets/svg-icon';
import React, { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editBotStructureInfoRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { IStructureList, IStructureRsponse } from 'redux/reducers/ai-chat-bot/types';
// import { Input } from 'ui/Input';
import { Textarea } from 'ui/Textarea/Textarea';
import './StructureList.scss';
import { IEntities, TextEditor } from 'ui/TextEditor/TextEditor';
import { getAiLoaderSelector } from 'redux/reducers/ai-chat-bot/selectors';
import { SmallLoader } from 'ui/SmallLoader';

interface IPropsStructureList {
	structures: IStructureRsponse;
	structure: IStructureList;
	keyStructure: string;
	handleButton: (event: MouseEvent) => void;
}

export const StructureList: FC<IPropsStructureList> = ({
	handleButton,
	structures,
	keyStructure,
	structure,
}) => {
	const [description, setDescription] = useState(structure.text);
	const [title, setTitle] = useState(structure.title);
	const [entities, setEntities] = useState<Array<IEntities> | []>([]);
	const aiLoader = useSelector(getAiLoaderSelector);
	const dispatch = useDispatch();

	const onReset = () => {
		setDescription('');
	};

	const onSave = () => {
		const data = {
			bot_id: structures?.bot_id as number,
			structure: structures?.structure?.map((item) => {
				if (item.key === keyStructure) {
					return {
						...item,
						text: description,
						title,
						entities,
					};
				}

				return item;
			}),
		};

		dispatch(editBotStructureInfoRequest({ request: data as IStructureRsponse, onReset }));
	};

	const onChange = (event: ChangeEvent) => {
		const { value } = event.target as HTMLTextAreaElement;
		setDescription(value);
	};

	return (
		<div key={structure.key} className="structure__box">
			<div id={structure.key} onClick={handleButton} className="structure__header">
				<div className="structure__name">
					<div className="structure__line">
						<span></span>
						<span></span>
					</div>
					<span>{structure.title}</span>
					<EditSmallSvg />
				</div>
				<div className="structure__arrow">
					{keyStructure === String(structure.key) ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
				</div>
			</div>
			{keyStructure === String(structure.key) ? (
				<div className="accordion accordion-input">
					{/* <div className="accordion__box accordion__box-2">
						<div className="accordion__left-content">Title</div>
						<label className="accordion__label">
							<Input
								name={'title'}
								type={'text'}
								handleInput={onChange}
								onKeyDownInput={onEditTemplate}
								value={title}
								placeholder={'Title'}
							/>
						</label>
					</div> */}
					<div className="accordion__box accordion__box-2">
						<div className="accordion__left-content">Text</div>
						{!structure.has_entities ? (
							<label className="accordion__label">
								<Textarea
									handleTextarea={onChange}
									name={'description'}
									placeholder={'Description'}
									value={description}
								/>
							</label>
						) : (
							structure && (
								<TextEditor
									setDescription={setDescription}
									defaultText={description}
									setEntities={setEntities}
									entities={entities}
									defaultEntities={structure.entities}
								/>
							)
						)}
					</div>
					<div>
						{/* Кнопка стає активною тільки при зміні description */}
						<button
							disabled={structure.text === description}
							onClick={onSave}
							className="save-button"
						>
							{aiLoader ? <SmallLoader /> : 'Save'}
						</button>
					</div>
				</div>
			) : null}
		</div>
	);
};
