export enum ELinksName {
	HOME = '/',
	LOGIN = '/login',
	RESET_PASSWORD = '/reset-password',
	PASSWORD_CONFIRMATION = '/password-confirmation',
	INVITE_LINK = '/invite_link',
	DASHBOARD = '/dashboard',
	CHANNELS = '/channels',
	NAME_EXEMPLE = '/channels/name-exemple',
	POST_DETAILS = '/channels/posts/post',
	MEDIA_DETAILS = '/channels/media-and-files',
	GROUPS = '/groups',
	GROUPS_DETAILS = '/groups-details',
	USERS = '/users',
	ADMINS = '/admins',
	ADMINS_DETAILS = '/admins/admin-details',
	FINANCE = '/finance',
	MONETIZATION = '/monetization',
	MONETIZATION_DETAILS = '/monetization/details',
	AI_SERVICES = '/ai-services',
	// TRANSACTIONS = 'transactions',
	CHANNEL_DETAIL_USER = '/channels-details-user',
	USERS_USER_DETAILS = '/users/channel-details',
	USER_DETAILS = '/users/user-details',
	TRANSACTIONS = '/transactions',
	REPORTS = '/reports',
	REPORT_DETAILS = '/reports-details',
	RESERVED_USERNAMES = '/reserved',
	REGISTRATION_REQUESTS = '/registration-requests',
	SUPORT = '/support',
	APK = '/apk-file',
	USER_PARTNER = '/user-partner',
	ERROR = '/error-500',
	CONTENT = '/content',
	TRACK_DETAILS = '/track-details',
	ALBUM_DETAILS = '/album-details',
	EDIT_TRACK = '/edit-track',
	SYSTEM_CHANNELS = '/system-channels',
	TERMS_MANAGEMENT = '/terms-management',
	TERMS_MANAGEMENT_CHANGE = '/terms-management/change',
	ELLO_PARTNERS = '/ello-partners',
	REQUEST_DETAILS = '/ello-partners/request-details',
	MANAGEMENT_DETAILS_PERSONAL_DATA = '/ello-partners/management-details',
	MANAGEMENT_DETAILS_CONTENT = '/ello-partners/management-details/content',
	AI_CHAT_BOT = '/ai-chat-bot',
	AI_CHAT_BOT_STATISTICS = '/statistics-bot',
	REACTIONS = '/reactions',
	REACTIONS_ADD = '/reactions-add',
	REACTIONS_EDIT = '/reactions-edit',
	EDIT_BOT = '/edit-bot',
	CREATE_BOT = '/create-bot',
	CODES_INFO = '/codes-info',
	CREATE_TEXT_TEMPLATE = 'create-text-template',
	CREATE_IMAGE_TEMPLATE = 'create-image-template',
}

export enum EParams {
	TAB = '/:tab?',
	SUBTAB = '/:subTab?',
	ID = '/:id?',
	NAME = '/:name?',
	USER_ID = '/:userId?',
	CHANNEL_ID = '/:channelId?',
}
