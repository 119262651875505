import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAiChatBotsState,
	IAiChatBotsREquest,
	IAiChatBotsResponse,
	ITotalStatisticsRequest,
	ITotalStatisticsResponse,
	IPricingResponse,
	IPricingRequest,
	IBotInfo,
	IStatisticsBot,
	IStatisticsBotRequest,
	ICommandAllData,
	IBot,
	ICategory,
	IButtonBot,
	IEditBot,
	ICreateBotRequest,
	IRecommendedResponse,
	IRecommendedReqvest,
	IUpload,
	IBotTurnOfAndOn,
	IBotPromoCodeList,
	IBotPromoCodeRevoke,
	IBotPromoCode,
	IBotPromoCodeListResonse,
	IAddBotDataRequest,
	IBotInfoResponse,
	IRequestCodeRevoke,
	IListLogo,
	IRemoveRequest,
	IResponseTemplateText,
	IStructureRsponse,
	IRequestTemplate,
	IStructureRequest,
	IPartners,
	IResponseAdvertiseList,
	IRequest,
	ITimeZoneList,
	IFrequenc,
} from './types';

export const initialState: IAiChatBotsState = {
	aiChatBots: null,
	aiChatBotsForCreate: null,
	aiChatBot: null,
	statistics: null,
	pricing: null,
	commands: null,
	bot: null,
	category: null,
	buttons: null,
	commandsInfo: null,
	totalStatistics: null,
	recommended: null,
	botsList: null,
	botInfo: null,
	listLogoImg: null,
	listTemplateText: null,
	structures: null,
	templatePhoto: null,
	partners: null,
	аdvertiseList: null,
	timeZoneList: null,
	frequency: null,
	aiChatBotLoader: false,
	codeLoader: false,
	pricingLoader: false,
	recommendedLoader: false,
	uploadLogoLoader: false,
	uploadBackgroundLoader: false,
};

const aiChatBots = createSlice({
	name: '@@aiChatBots',
	initialState,
	reducers: {
		getAiChatBotsRequest: (state, action: PayloadAction<IAiChatBotsREquest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getAiChatBotsSuccess: (state, action: PayloadAction<IAiChatBotsResponse>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.aiChatBots = payload;
		},

		getAiChatBotsForCreateRequest: (state, action: PayloadAction<IAiChatBotsREquest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getAiChatBotsForCreateSuccess: (state, action: PayloadAction<IAiChatBotsResponse>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.aiChatBotsForCreate = payload;
		},

		getAiChatBotRequest: (state, action: PayloadAction<IAiChatBotsREquest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getAiChatBotSuccess: (state, action: PayloadAction<IAiChatBotsResponse>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.aiChatBots = payload;
		},

		getBotsStatisticsRequest: (state, action: PayloadAction<IStatisticsBotRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getBotsStatisticsSuccess: (state, action: PayloadAction<IStatisticsBot>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.statistics = payload;
		},

		getTotalStatisticsRequest: (state, action: PayloadAction<ITotalStatisticsRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getTotalStatisticsSuccess: (state, action: PayloadAction<ITotalStatisticsResponse>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.totalStatistics = payload;
		},

		getPricingRequest: (state, action: PayloadAction<string>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getPricingSuccess: (state, action: PayloadAction<IPricingResponse>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.pricing = payload;
		},

		editPricingRequest: (state, action: PayloadAction<IPricingRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.pricingLoader = true;
		},

		editPricingSuccess: (state, action: PayloadAction<IPricingResponse>) => {
			const aiChatBotsState = state;
			aiChatBotsState.pricingLoader = false;
		},

		getBotInfoRequest: (state, action: PayloadAction<IPricingRequest>) => {
			const aiChatBotsState = state;
			// aiChatBotsState.pricingLoader = true;
		},

		getBotInfoSuccess: (state, action: PayloadAction<IBotInfo | null>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			// aiChatBotsState.pricingLoader = false;
			aiChatBotsState.aiChatBot = payload;
		},

		getCommandsRequest: (state, action: PayloadAction<string>) => {
			const aiChatBotsState = state;
			aiChatBotsState.pricingLoader = true;
		},

		getCommandsSuccess: (state, action: PayloadAction<ICommandAllData | null>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			aiChatBotsState.pricingLoader = false;
			aiChatBotsState.commands = payload;
		},

		editCommandRequest: (state, action: PayloadAction<ICommandAllData>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		editCommandSuccess: (state, action: PayloadAction<ICommandAllData>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.commands = payload;
		},

		getBotDataRequest: (state, action: PayloadAction<string>) => {
			const aiChatBotsState = state;
			// aiChatBotsState.aiChatBotLoader = true;
		},

		getBotDataSuccess: (state, action: PayloadAction<IBot | null>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			// aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.bot = payload;
		},

		getBotСategoryRequest: (state) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getBotСategorySuccess: (state, action: PayloadAction<Array<ICategory>>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.category = payload;
		},

		getButtonsRequest: (state, action: PayloadAction<string>) => {},

		getButtonsSuccess: (state, action: PayloadAction<IButtonBot>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.buttons = payload;
		},

		getCommandsInfoRequest: (state, action: PayloadAction<string>) => {},

		getCommandsInfoSuccess: (state, action: PayloadAction<ICommandAllData>) => {
			const aiChatBotsState = state;
			const { payload } = action;
			aiChatBotsState.commandsInfo = payload;
		},

		editBotRequest: (state, action: PayloadAction<IEditBot>) => {},

		editBotSuccess: (state, action: PayloadAction<IEditBot>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			aiChatBotsState.bot = payload as IBot;
		},

		editButtonsRequest: (state, action: PayloadAction<IButtonBot>) => {},

		editButtonsSuccess: (state, action: PayloadAction<IButtonBot>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			aiChatBotsState.buttons = payload;
		},

		createBotRequest: (state, action: PayloadAction<ICreateBotRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},

		getRecommendedRequest: (state, action: PayloadAction<IRecommendedReqvest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.recommendedLoader = true;
		},

		getRecommendedSuccess: (state, action: PayloadAction<IRecommendedResponse>) => {
			const aiChatBotsState = state;
			const { payload } = action;

			aiChatBotsState.recommendedLoader = false;
			aiChatBotsState.recommended = payload;
		},

		editBotTurnOfAndOnRequest: (state, action: PayloadAction<IBotTurnOfAndOn>) => {},

		uploadLogoRequest: (state, action: PayloadAction<IUpload>) => {
			const aiChatBotsState = state;
			aiChatBotsState.uploadLogoLoader = true;
		},
		uploadLogoSuccess: (state) => {
			const aiChatBotsState = state;
			aiChatBotsState.uploadLogoLoader = false;
		},
		uploadBackgroundRequest: (state, action: PayloadAction<IUpload>) => {
			const aiChatBotsState = state;
			aiChatBotsState.uploadBackgroundLoader = true;
		},
		uploadBackgroundSuccess: (state) => {
			const aiChatBotsState = state;
			aiChatBotsState.uploadBackgroundLoader = false;
		},

		addBotPromoRequest: (state, action: PayloadAction<IAddBotDataRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.codeLoader = true;
		},
		addBotPromoSuccess: (state) => {
			const aiChatBotsState = state;
			aiChatBotsState.codeLoader = false;
		},

		botPromoCodeRequest: (state, action: PayloadAction<IBotPromoCode>) => {
			const aiChatBotsState = state;
			aiChatBotsState.codeLoader = true;
		},
		botPromoCodeSuccess: (state, action: PayloadAction<IBotInfoResponse | null>) => {
			const aiChatBotsState = state;
			aiChatBotsState.botInfo = action.payload;
			aiChatBotsState.codeLoader = false;
		},

		botPromoCodeRevokeRequest: (state, action: PayloadAction<IRequestCodeRevoke>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},
		botPromoCodeRevokeSuccess: (state, action: PayloadAction<IBotPromoCodeRevoke>) => {
			const { code } = action.payload;
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = false;

			if (aiChatBotsState.botInfo) {
				aiChatBotsState.botInfo = {
					...aiChatBotsState.botInfo,
					list: aiChatBotsState.botInfo.list.filter((item) => item.code !== code),
					total: aiChatBotsState.botInfo.total - 1,
					count_codes_all: aiChatBotsState.botInfo.count_codes_all - 1,
				};
			}
		},

		botPromoCodeListRequest: (state, action: PayloadAction<IBotPromoCodeList>) => {},
		botPromoCodeListSuccess: (state, action: PayloadAction<IBotPromoCodeListResonse>) => {
			const aiChatBotsState = state;
			aiChatBotsState.botsList = action.payload;
		},

		listLogoRequest: (state, action: PayloadAction<string>) => {},
		listLogoSuccess: (state, action: PayloadAction<Array<IListLogo>>) => {
			const aiChatBotsState = state;
			aiChatBotsState.listLogoImg = action.payload;
		},

		removeLogoRequest: (state, action: PayloadAction<IRemoveRequest>) => {},
		removeLogoSuccess: (state, action: PayloadAction<IRemoveRequest>) => {
			const aiChatBotsState = state;
			if (aiChatBotsState.listLogoImg) {
				aiChatBotsState.listLogoImg = aiChatBotsState.listLogoImg.filter(
					(ava) => ava.id !== action.payload.photo_id,
				);
			}
		},

		getTemplateTextRequest: (state, action: PayloadAction<string>) => {},
		getTemplateTextSuccess: (state, action: PayloadAction<IResponseTemplateText>) => {
			const aiChatBotsState = state;
			aiChatBotsState.listTemplateText = action.payload;
		},

		editTemplateTextRequest: (state, action: PayloadAction<IRequestTemplate>) => {},
		editTemplateTextSuccess: (state, action: PayloadAction<IResponseTemplateText>) => {
			const aiChatBotsState = state;
			aiChatBotsState.listTemplateText = action.payload;
		},

		getBotStructureInfoRequest: (state, action: PayloadAction<string>) => {},
		getBotStructureInfoSuccess: (state, action: PayloadAction<IStructureRsponse | null>) => {
			const aiChatBotsState = state;
			aiChatBotsState.structures = action.payload;
		},

		editBotStructureInfoRequest: (state, action: PayloadAction<IStructureRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},
		editBotStructureInfoSuccess: (state, action: PayloadAction<IStructureRsponse>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.structures = action.payload;
		},

		getTemplatePhotoRequest: (state, action: PayloadAction<string>) => {},
		getTemplatePhotoSuccess: (state, action: PayloadAction<IResponseTemplateText>) => {
			const aiChatBotsState = state;
			aiChatBotsState.templatePhoto = action.payload;
		},

		editTemplatePhotoRequest: (state, action: PayloadAction<IRequestTemplate>) => {},
		editTemplatePhotoSuccess: (state, action: PayloadAction<IResponseTemplateText>) => {
			const aiChatBotsState = state;
			aiChatBotsState.templatePhoto = action.payload;
		},

		deleteBotRequest: (state, action: PayloadAction<string>) => {},
		deleteBotSuccess: (state, action: PayloadAction<string>) => {
			window.history.go(-1);
		},

		getPartnersRequest: (state) => {},
		getPartnersSuccess: (state, action: PayloadAction<IPartners>) => {
			const aiChatBotsState = state;
			aiChatBotsState.partners = action.payload;
		},

		getAdvertiseListRequest: (state, action: PayloadAction<IRequest>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = true;
		},
		getAdvertiseListSuccess: (state, action: PayloadAction<IResponseAdvertiseList>) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.аdvertiseList = action.payload;
		},

		addAdvertiseRequest: (
			state,
			action: PayloadAction<{ dispatch: any; onClose: () => void; bot_id: string; formData: any }>,
		) => {
			const aiChatBotsState = state;
			aiChatBotsState.uploadLogoLoader = true;
		},
		addAdvertiseSuccess: (state, action: PayloadAction<IResponseAdvertiseList>) => {
			const aiChatBotsState = state;
			aiChatBotsState.uploadLogoLoader = false;

			// aiChatBotsState.аdvertiseList = action.payload;
		},

		timeZoneListRequest: () => {},
		timeZoneListSuccess: (state, action: PayloadAction<Array<ITimeZoneList>>) => {
			const aiChatBotsState = state;
			aiChatBotsState.timeZoneList = action.payload;
		},

		editAdvertiseRequest: (state, action: PayloadAction<any>) => {
			const aiChatBotsState = state;
		},
		editAdvertiseSuccess: (state, action: PayloadAction<IResponseAdvertiseList>) => {
			const aiChatBotsState = state;
			// aiChatBotsState.аdvertiseList = action.payload;
		},

		deleteAdvertiseRequest: (state, action: PayloadAction<any>) => {
			const aiChatBotsState = state;
		},
		deleteAdvertiseSuccess: (state, action: PayloadAction<{ bot_id: string; id: string }>) => {
			const aiChatBotsState = state;
			if (aiChatBotsState.аdvertiseList?.advertise_list) {
				aiChatBotsState.аdvertiseList.advertise_list =
					aiChatBotsState.аdvertiseList.advertise_list.filter(
						(el) => el.advertise_id !== Number(action.payload.id),
					);
				aiChatBotsState.аdvertiseList.total = aiChatBotsState.аdvertiseList.total - 1;
			}
		},

		deleteAdvertiseImageRequest: (
			state,
			action: PayloadAction<{ bot_id: string; id: string; hash: string }>,
		) => {
			const aiChatBotsState = state;
		},
		deleteAdvertiseImageSuccess: (
			state,
			action: PayloadAction<{ bot_id: string; id: string; hash: string }>,
		) => {
			const aiChatBotsState = state;
			if (aiChatBotsState.аdvertiseList) {
				aiChatBotsState.аdvertiseList.advertise_list =
					aiChatBotsState.аdvertiseList.advertise_list.map((item) => {
						if (item.medias.find((el) => el.access_hash === action.payload.hash)) {
							return {
								...item,
								medias: item.medias.filter(
									(element) => element.access_hash !== action.payload.hash,
								),
							};
						}
						return item;
					});
			}
		},

		uploadAdvertiseImageRequest: (
			state,
			action: PayloadAction<{ bot_id: string; id: string; body: any }>,
		) => {
			const aiChatBotsState = state;
		},
		uploadAdvertiseImageSuccess: (state, action: PayloadAction<IResponseAdvertiseList>) => {
			const aiChatBotsState = state;
			// aiChatBotsState.аdvertiseList = action.payload;
		},

		getFrequencyRequest: (state, action: PayloadAction<string>) => {
			const aiChatBotsState = state;
		},
		getFrequencySuccess: (state, action: PayloadAction<IFrequenc>) => {
			const aiChatBotsState = state;
			aiChatBotsState.frequency = action.payload;
		},

		editFrequencyRequest: (state, action: PayloadAction<IFrequenc>) => {
			const aiChatBotsState = state;
		},
		editFrequencySuccess: (state, action: PayloadAction<IFrequenc>) => {
			const aiChatBotsState = state;
			// aiChatBotsState.frequency = action.payload;
		},

		aiChatBotInitState: (state) => {
			const aiChatBotsState = state;
			aiChatBotsState.aiChatBotLoader = false;
			aiChatBotsState.pricingLoader = false;
			aiChatBotsState.recommendedLoader = false;
			aiChatBotsState.uploadBackgroundLoader = false;
			aiChatBotsState.uploadLogoLoader = false;
			aiChatBotsState.codeLoader = false;
		},
	},
});

export default aiChatBots.reducer;
export const {
	getAiChatBotsRequest,
	getAiChatBotsSuccess,
	getAiChatBotsForCreateRequest,
	getAiChatBotsForCreateSuccess,
	getAiChatBotRequest,
	getBotsStatisticsRequest,
	getBotsStatisticsSuccess,
	getTotalStatisticsRequest,
	getTotalStatisticsSuccess,
	getPricingRequest,
	getPricingSuccess,
	editPricingRequest,
	editPricingSuccess,
	getBotInfoRequest,
	getBotInfoSuccess,
	getCommandsRequest,
	getCommandsSuccess,
	editCommandRequest,
	editCommandSuccess,
	getBotDataRequest,
	getBotDataSuccess,
	getBotСategoryRequest,
	getBotСategorySuccess,
	getButtonsRequest,
	getButtonsSuccess,
	getCommandsInfoRequest,
	getCommandsInfoSuccess,
	editBotRequest,
	editBotSuccess,
	editButtonsRequest,
	editButtonsSuccess,
	createBotRequest,
	getRecommendedRequest,
	getRecommendedSuccess,
	uploadLogoRequest,
	uploadLogoSuccess,
	uploadBackgroundRequest,
	editBotTurnOfAndOnRequest,
	uploadBackgroundSuccess,
	addBotPromoRequest,
	addBotPromoSuccess,
	botPromoCodeRequest,
	botPromoCodeSuccess,
	botPromoCodeRevokeRequest,
	botPromoCodeRevokeSuccess,
	botPromoCodeListRequest,
	botPromoCodeListSuccess,
	listLogoRequest,
	listLogoSuccess,
	removeLogoRequest,
	removeLogoSuccess,
	getTemplateTextRequest,
	getTemplateTextSuccess,
	editTemplateTextRequest,
	editTemplateTextSuccess,
	getBotStructureInfoRequest,
	getBotStructureInfoSuccess,
	editBotStructureInfoRequest,
	editBotStructureInfoSuccess,
	getTemplatePhotoRequest,
	editTemplatePhotoSuccess,
	editTemplatePhotoRequest,
	deleteBotRequest,
	deleteBotSuccess,
	aiChatBotInitState,
	getPartnersRequest,
	getPartnersSuccess,
	getAdvertiseListRequest,
	getAdvertiseListSuccess,
	addAdvertiseRequest,
	addAdvertiseSuccess,
	timeZoneListRequest,
	timeZoneListSuccess,
	editAdvertiseRequest,
	editAdvertiseSuccess,
	deleteAdvertiseRequest,
	deleteAdvertiseSuccess,
	deleteAdvertiseImageRequest,
	deleteAdvertiseImageSuccess,
	uploadAdvertiseImageRequest,
	uploadAdvertiseImageSuccess,
	getFrequencyRequest,
	getFrequencySuccess,
	editFrequencyRequest,
	editFrequencySuccess,
} = aiChatBots.actions;
