import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'untils/notificationContainer';
import {
	activeReactionRequest,
	activeReactionSuccess,
	addReactionRequest,
	deleteReactionRequest,
	deleteReactionSuccess,
	editReactionRequest,
	getReactionRequest,
	getReactionSuccess,
	getReactionsRequest,
	getReactionsSuccess,
	reactionsInitState,
} from './reducer';
import {
	IActiveReaction,
	IAddRequest,
	IDeleteReaction,
	IEditRequest,
	IReactionRequest,
	IReactionResponse,
	IReactions,
} from './types';
import { ELinksName } from 'services/constants/list-links-sidebar';

function* getReactionsWorker(action: PayloadAction<IReactionRequest>) {
	const { payload } = action;

	try {
		const response: { data: Array<IReactions> } = yield call(api.reactions.getReactions, payload);
		yield put(getReactionsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reactionsInitState());
	}
}

function* getReactionWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		const response: { data: IReactionResponse } = yield call(api.reactions.getReaction, payload);
		yield put(getReactionSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reactionsInitState());
	}
}

function* addReactionWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		yield call(api.reactions.addReaction, payload.data);
		payload.navigation(ELinksName.REACTIONS);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reactionsInitState());
	}
}

function* editReactionWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		yield call(api.reactions.editReaction, payload.data);
		payload.navigation(ELinksName.REACTIONS);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reactionsInitState());
	}
}

function* deleteReactionWorker(action: PayloadAction<IDeleteReaction>) {
	const { payload } = action;

	try {
		yield call(api.reactions.deleteReaction, payload.id);
		payload.navigation(ELinksName.REACTIONS);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reactionsInitState());
	}
}

function* activeReactionWorker(action: PayloadAction<IActiveReaction>) {
	const { payload } = action;

	try {
		yield call(api.reactions.activeReaction, payload.data);
		yield put(activeReactionSuccess(payload));
	} catch (error) {
		payload.setToggles((prev: Array<number>) =>
			prev.filter((toggle) => Number(toggle) !== Number(payload.data.id)),
		);
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reactionsInitState());
	}
}

export function* reactionsSaga() {
	yield takeEvery(getReactionsRequest.type, getReactionsWorker);
	yield takeEvery(getReactionRequest.type, getReactionWorker);
	yield takeEvery(addReactionRequest.type, addReactionWorker);
	yield takeEvery(editReactionRequest.type, editReactionWorker);
	yield takeEvery(deleteReactionRequest.type, deleteReactionWorker);
	yield takeEvery(activeReactionRequest.type, activeReactionWorker);
}
