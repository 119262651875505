import { endpoint } from 'services/endpoint';
import { http, multipart } from 'services/http';
import { IAiChatBotsApi } from './types';
import {
	IAddBotPromoRequest,
	IAiChatBotsREquest,
	IBotPromoCode,
	IBotPromoCodeList,
	IBotPromoCodeRevoke,
	IBotTurnOfAndOnRequest,
	IButtonBot,
	ICommandAllData,
	ICreateBot,
	IEditBot,
	IFrequenc,
	IPricingConfig,
	IRecommendedReqvest,
	IRemoveRequest,
	IRequest,
	IResponseTemplateText,
	IStatisticsBotRequest,
	IStructureRsponse,
	ITotalStatistics,
	IUpload,
} from 'redux/reducers/ai-chat-bot/types';

export const aiChatBots: IAiChatBotsApi = {
	getAiChatBots: (params: IAiChatBotsREquest) =>
		http.post(endpoint.aiChatBots.GET_LIST_CHAT_BOTS, params).then((response) => response.data),
	getTotalStatistics: (params: ITotalStatistics) =>
		http.post(endpoint.aiChatBots.TOTAL_STATISTICS_LIST, params).then((response) => response.data),
	getPricing: (params: string) =>
		http.get(endpoint.aiChatBots.GET_BOT_INFO_PRICE(params)).then((response) => response.data),
	editPricing: (params: { bot_id: number; config: IPricingConfig }) =>
		http.post(endpoint.aiChatBots.EDIT_BOT_PRICE, params).then((response) => response.data),
	getBotsStatistics: (params: IStatisticsBotRequest) =>
		http.post(endpoint.aiChatBots.BOT_STATISTICS_LIST, params).then((response) => response.data),
	getBotInfo: (params: string) =>
		http.get(endpoint.aiChatBots.GET_BOT_INFO(params)).then((response) => response.data),
	getCommands: (params: string) =>
		http.get(endpoint.aiChatBots.GET_COMMANDS(params)).then((response) => response.data),
	editCommand: (params: ICommandAllData) =>
		http.post(endpoint.aiChatBots.EDIT_COMMAND, params).then((response) => response.data),
	getBotData: (params: string) =>
		http.get(endpoint.aiChatBots.GET_BOT_DATA(params)).then((response) => response.data),
	getBotСategory: () =>
		http.get(endpoint.aiChatBots.GET_CATEGORY_LIST).then((response) => response.data),
	getButtons: (params: string) =>
		http.get(endpoint.aiChatBots.GET_BUTTONS(params)).then((response) => response.data),
	getCommandInfo: (params: string) =>
		http.get(endpoint.aiChatBots.GET_COMMAND_INFO(params)).then((response) => response.data),
	editBot: (params: IEditBot) =>
		http.post(endpoint.aiChatBots.EDIT_BOT_USER, params).then((response) => response.data),
	editButtons: (params: IButtonBot) =>
		http.post(endpoint.aiChatBots.EDIT_BUTTONS, params).then((response) => response.data),
	createBot: (params: ICreateBot) =>
		http.post(endpoint.aiChatBots.ADD_NEW_BOT, params).then((response) => response.data),
	getRecommended: (params: IRecommendedReqvest) =>
		http.post(endpoint.aiChatBots.GET_RECOMMENDED, params).then((response) => response.data),
	uploadLogo: (params: IUpload) =>
		multipart
			.post(endpoint.aiChatBots.UPLOAD_LOGO(params.botId), params.data.file)
			.then((response) => response.data),
	uploadBackground: (params: IUpload) =>
		multipart
			.post(endpoint.aiChatBots.UPLOAD_BACKGROUND(params.botId), params.data.file)
			.then((response) => response.data),
	editBotTurnOfAndOn: (params: IBotTurnOfAndOnRequest) =>
		http.post(endpoint.aiChatBots.EDIT_BOT_TURN_ON, params).then((response) => response.data),
	addBotPromo: (params: IAddBotPromoRequest) =>
		http.post(endpoint.aiChatBots.ADD_BOT_PROMO, params).then((response) => response.data),
	botPromoCode: (params: IBotPromoCode) =>
		http.post(endpoint.aiChatBots.BOT_PROMO_CODE, params).then((response) => response.data),
	botPromoCodeRevoke: (params: IBotPromoCodeRevoke) =>
		http.post(endpoint.aiChatBots.BOT_PROMO_CODE_REVOKE, params).then((response) => response.data),
	botPromoCodeList: (params: IBotPromoCodeList) =>
		http.post(endpoint.aiChatBots.BOT_PROMO_CODE_LIST, params).then((response) => response.data),
	getLogoList: (params: string) =>
		http.get(endpoint.aiChatBots.GET_LOGO_LIST(params)).then((response) => response.data),
	removeLogo: (params: IRemoveRequest) =>
		http.post(endpoint.aiChatBots.REMOVE_LOGO_IMG, params).then((response) => response.data),
	getTemplateText: (params: string) =>
		http.get(endpoint.aiChatBots.GET_TEMPLATE_TEXT(params)).then((response) => response.data),
	editTemplateText: (params: IResponseTemplateText) =>
		http.post(endpoint.aiChatBots.EDIT_TEMPLATE_TEXT, params).then((response) => response.data),
	getBotStructureInfo: (params: string) =>
		http.get(endpoint.aiChatBots.BOT_SRUCTURE_INFO(params)).then((response) => response.data),
	editBotStructureInfo: (params: IStructureRsponse) =>
		http.post(endpoint.aiChatBots.EDIT_BOT_SRUCTURE_INFO, params).then((response) => response.data),
	getTemplatePhoto: (params: string) =>
		http.get(endpoint.aiChatBots.BOT_TEMPLATE_PHOTO(params)).then((response) => response.data),
	editTemplatePhoto: (params: IResponseTemplateText) =>
		http
			.post(endpoint.aiChatBots.EDIT_BOT_TEMPLATE_PHOTO, params)
			.then((response) => response.data),
	deleteBot: (params: string) =>
		http.post(endpoint.aiChatBots.DELETE_BOT_USER(params)).then((response) => response.data),
	getPartners: () => http.get(endpoint.aiChatBots.GET_PARTNERS).then((response) => response.data),
	getAdvertiseList: (params: IRequest) =>
		http.post(endpoint.aiChatBots.ADVERTISE_LIST, params).then((response) => response.data),
	addAdvertiseList: (params: any) =>
		multipart
			.post(endpoint.aiChatBots.ADD_ADVERTISE(params.bot_id), params.formData)
			.then((response) => response.data),
	getTimeZoneList: () =>
		http.get(endpoint.aiChatBots.TIMEZONE_LIST).then((response) => response.data),

	editAdvertiseList: (params: any) =>
		http.post(endpoint.aiChatBots.EDIT_ADVERTISE, params).then((response) => response.data),
	deleteAdvertise: (params: { bot_id: string; id: string }) =>
		http
			.post(endpoint.aiChatBots.ADVERTISE_REMOVE(params.bot_id, params.id))
			.then((response) => response.data),
	deleteAdvertiseImage: (params: { bot_id: string; id: string; hash: string }) =>
		http.post(endpoint.aiChatBots.ADVERTISE_MEDIA_REMOVE(params)).then((response) => response.data),
	uploadAdvertiseImage: (data: { bot_id: string; id: string; body: any }) =>
		multipart
			.post(endpoint.aiChatBots.UPLOAD_ADVERTISE(data.bot_id, data.id), data.body)
			.then((response) => response.data),
	getFrequency: (id: string) =>
		http.get(endpoint.aiChatBots.GET_ADVERTISE_FREQUENC(id)).then((response) => response.data),
	editFrequency: (body: IFrequenc) =>
		http.post(endpoint.aiChatBots.EDIT_ADVERTISE_FREQUENC, body).then((response) => response.data),
};
