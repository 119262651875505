import { FC, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import InputGray from 'ui/InputGray/InputGray';

const initialValues = {
	name: '',
	username: '',
	description: '',
};

export const CreateSystemForm: FC<any> = ({ loginSubmit }) => {
	const [startDate, setStartDate] = useState<string>('');

	const [file, setFile] = useState<File | null>(null);

	const handleChange = (data: FileList) => {
		//@ts-ignore
		setFile(data);
	};

	const addDate = (date: Date | string, index: number) => {
		setStartDate(String(date));
	};

	return (
		<Formik
			initialValues={initialValues}
			// validationSchema={loginSchema}
			onSubmit={(values: any, { setSubmitting }) => {
				loginSubmit(values);
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, values }) => {
				return (
					<Form className="metadata__form-metadata form-metadata">
						<div className="system__form-wrap">
							<Field
								className="border-none"
								name="name"
								component={InputGray}
								value={values.name}
								placeholder={'Name'}
							/>

							<Field
								className="border-none"
								name="username"
								component={InputGray}
								value={values.username}
								placeholder={'@username'}
							/>

							<Field
								className="border-none"
								name="description"
								component={InputGray}
								value={values.description}
								placeholder={'Description (optional)'}
							/>
						</div>

						<p className="text-gray-2">
							You can provide an additional description for your system channel.
						</p>

						<div className="form-submit-flex">
							<button
								// disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="form-submit-flex__download form-submit-flex__btn-text"
							>
								Create
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
