import { store } from 'redux/store';
import { IAxiosError, IAxiosRequestConfig } from './types';
import { getTokenRefreshFatch } from 'untils/getTokenFatch';
import axios from 'axios';

let isRefreshing = false;
let failedQueue: Array<(token: string) => void> = [];

const processQueue = (error: any, token: string | null = null) => {
	failedQueue.forEach((prom) => {
		if (token) {
			prom(token);
		} else {
			prom('');
		}
	});
	failedQueue = [];
};

const onError = async (error: IAxiosError) => {
	const status = error.response ? error.response.status : null;
	const errorResponse = error.config as IAxiosRequestConfig;

	if (status === 401 && !errorResponse?._retry) {
		if (isRefreshing) {
			return new Promise((resolve, reject) => {
				failedQueue.push((newToken: string) => {
					if (newToken) {
						errorResponse._retry = true;
						errorResponse.headers = {
							...errorResponse.headers, // Зберігаємо існуючі заголовки
							Authorization: `Bearer ${newToken}`,
						};
						resolve(axios(errorResponse));
					} else {
						reject(error);
					}
				});
			});
		}

		errorResponse._retry = true;
		isRefreshing = true;

		try {
			await getTokenRefreshFatch();
			const state = store.getState();
			const newAccessToken = state.auth.accessToken;

			processQueue(null, newAccessToken);

			if (newAccessToken) {
				errorResponse.headers = {
					...errorResponse.headers, // Зберігаємо існуючі заголовки
					Authorization: `Bearer ${newAccessToken}`,
				};
			}

			return axios(errorResponse);
		} catch (tokenError) {
			processQueue(tokenError, null);
			return Promise.reject(tokenError);
		} finally {
			isRefreshing = false;
		}
	}

	return Promise.reject(error);
};

export default onError;
