import { ArrowDownSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { FC, useEffect, useState, useRef, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ReactCountryFlag } from 'react-country-flag';
import { putCounties } from 'redux/reducers/users/reducer';
import { cutString } from 'untils/cutString';

interface ICountry {
	value: string;
	label: string;
}

interface CustomInputProps {
	addCountry: (value: string) => void;
}

export const CountrySelect: FC<CustomInputProps> = ({ addCountry }) => {
	const [countries, setCountries] = useState<Array<ICountry>>([]);
	const [countriesDefault, setCountriesDefault] = useState<Array<ICountry>>([]);
	const [text, setText] = useState<string>('');
	const ref = useRef<HTMLDivElement | null>(null);
	const [onOpen, setOnOpen] = useState<boolean>(false);
	const dispatch = useDispatch();

	const [selectedCountry, setSelectedCountry] = useState<ICountry>({
		value: '',
		label: '',
	});

	useOutsideClick(ref, () => setOnOpen(false));

	const handleSelect = (country: ICountry) => {
		setSelectedCountry(country);
		setOnOpen(false);
		setText(cutString(country.label, 4, country.label.length));
		addCountry(cutString(country.label, 4, country.label.length).slice(0, 3));
	};

	const showAll = () => {
		setText('All');
		addCountry('');
		setOnOpen(false);
	};

	const onInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		const countriesAll = countriesDefault.filter(
			(country) =>
				country.label.slice(4).trim().toLowerCase().slice(0, value.toLowerCase().length) ===
				value.toLowerCase(),
		);

		setText(value);
		addCountry(value);
		dispatch(putCounties(countriesAll));
		setCountries(countriesAll);
		setOnOpen(true);
	};

	useEffect(() => {
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
				dispatch(putCounties(data.countries));
				setCountriesDefault(data.countries);
				// setSelectedCountry(data.userSelectValue);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div className="select-custom">
			<div ref={ref} className={classNames('select', { active: onOpen })}>
				<div className="input input-custom pointer">
					<div
						onClick={() => setOnOpen(true)}
						onFocus={() => setOnOpen(true)}
						className="input-wrapper input-wrapper--custom"
					>
						<input
							onChange={onInput}
							className="input-item input-item--left-icon"
							type="text"
							name="country"
							value={text}
							placeholder="Country"
						/>
						<div className="input-icon input-icon--bigger input-icon--right">
							<ArrowDownSvg />
						</div>

						<span className="input-icon">
							<SearchSvg />
						</span>
					</div>
				</div>
				<div className="select__drop select__drop-custom select__drop-custom-country">
					<div className="select__drop-scroll select__drop-scroll-custom">
						<div className="select__drop-item">
							<ul>
								<li className="countries-list">
									<button onClick={showAll} type="button">
										All
									</button>
								</li>
								{countries.map((country) => (
									<li className="countries-list" key={country.label}>
										<button onClick={() => handleSelect(country)} type="button">
											<ReactCountryFlag
												countryCode={country.value}
												svg
												style={{ fontSize: '2rem', marginRight: '10px' }}
											/>
											{cutString(country.label, 4, country.label.length)}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
