import { Loader } from 'components/Loader';
import { UsersSubtabs } from 'components/UsersComponents/UsersSubtabs';
import { UsersTableBody } from 'components/UsersComponents/UsersTableBody';
import { UsersTableHeader } from 'components/UsersComponents/UsersTableHeader';
import { UsersTabs } from 'components/UsersComponents/UsersTabs';
import { typeFilter, typeStatus } from 'CONST/userFilter';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getUsersListRequest } from 'redux/reducers/users/reducer';
import { getLoader, getUsersList } from 'redux/reducers/users/selectors';
import { Fillter } from 'ui/Fillter/Fillter';
import { IDataFiltter } from '../Channels';
import { Pagination } from 'ui/Pagination';
import { NotFound } from 'ui/NotFound/NotFound';
import { ETabsUsers } from 'services/constants/lists-table';
import { usersTitle } from 'CONST/users';
import { numeralSpaceFunc } from 'untils/numerals';
import { TCounters } from 'redux/reducers/users/types';

const Users: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const params = useParams();
	const usersList = useSelector(getUsersList);
	const loader = useSelector(getLoader);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({
		country_code: '',
		current_page: 1,
		date_from: '',
		date_to: '',
		search: '',
		type: params.subTab?.replace('-profile', '') || 'personal',
	});

	const getData = (): boolean => {
		if (params.tab === ETabsUsers.DELETE_USERS) {
			return true;
		}
		return params.tab === ETabsUsers.BLOCKED_USERS ? true : false;
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
		dispatch(
			getUsersListRequest({
				...dataFiltter,
				current_page: value || 1,
				type: params.subTab?.replace('-profile', '') || 'personal',
				[params.tab === ETabsUsers.DELETE_USERS ? 'is_deleted' : 'is_blocked']: getData(),
			}),
		);
	};

	useEffect(() => {
		submitFilter(+location.search.slice(1) || 1);
	}, [dispatch, params.tab, dataFiltter, params.subTab]);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Users</p>
				</div>
			</div>
			<UsersTabs />
			<div
				className={
					dataFiltter?.search?.length && !usersList?.data.users?.length
						? 'table-block table-block-custom'
						: 'table-block'
				}
			>
				<Fillter setDataFiltter={setDataFiltter} keyStatus="status" keyType="types" />
				<div className="filter-custom__info-card">
					{usersTitle.map((el) => (
						<div key={el} className="filter-custom__card">
							<p className="filter-custom__title">{el}</p>
							<p className="filter-custom__subtitle">
								{numeralSpaceFunc(
									usersList?.data?.users_counters[
										el.toLowerCase().replaceAll(' ', '_') as TCounters
									] || 0,
								)}
							</p>
						</div>
					))}
				</div>
				<UsersSubtabs />
				<div className="table-wrapper">
					<div className="table table--users-registered-personal">
						<UsersTableHeader setDataFiltter={setDataFiltter} />
						{loader ? (
							<Loader />
						) : usersList?.data.users?.length ? (
							<UsersTableBody
								search={dataFiltter.search || ''}
								members={usersList?.data.users}
								param={params.tab}
							/>
						) : (
							<NotFound name={dataFiltter?.search || ''} />
						)}
					</div>
				</div>
			</div>
			{!loader && usersList?.data && usersList?.data?.total > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(usersList.data?.total / 15)}
				/>
			) : null}
		</div>
	);
};

export default Users;
