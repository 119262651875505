import { FC } from 'react';
import { Link } from 'react-router-dom';
import { EditSvg } from 'assets/svg-icon';
import { IAdminsResponse } from 'redux/reducers/admins/types';
import moment from 'moment';
import { ELinksName } from 'services/constants/list-links-sidebar';

interface IProps {
	roles: Array<IAdminsResponse> | undefined;
}

export const AdminBody: FC<IProps> = ({ roles }) => {
	return (
		<div className="table-body">
			{roles?.map((role) => (
				<div key={role.id} className="tr tr-admin tr-admin-body">
					<div className="td">
						<p className="td-hidden-name">Name</p>
						<Link
							className={role.status === 'pending' ? 'gray-link' : ''}
							to={`${ELinksName.ADMINS_DETAILS}/${role.email}`}
						>
							{role.first_name}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Email</p>
						<Link
							className={role.status === 'pending' ? 'gray-link' : ''}
							to={`${ELinksName.ADMINS_DETAILS}/${role.email}`}
						>
							{role.email}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Status</p>
						<p className={`upper-first-word`}>{role.status}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Last active</p>
						<p>
							{role.last_login ? moment(new Date(role.last_login)).format('MMM DD, YYYY') : null}
						</p>
						<p className="gray">
							{role.last_login ? moment(new Date(role.last_login)).format('h:mm a') : '-'}
						</p>
					</div>
					<div className="td td--right-custom td--left">
						<p className="td-hidden-name">Action</p>
						<Link to={`${ELinksName.ADMINS_DETAILS}/${role.email}`}>
							<EditSvg />
						</Link>
					</div>
				</div>
			))}
			{!roles?.length && <div>Empty</div>}
		</div>
	);
};
