import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubtabsTransaction } from 'services/constants/lists-table';

export const TransactionSabTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs">
			<Link
				to={`${ELinksName.TRANSACTIONS}/${params.tab || ''}/${ESubtabsTransaction.TRANSACTION}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubtabsTransaction.TRANSACTION || !params.subTab,
				})}
			>
				Transactions
			</Link>
		</div>
	);
};
