import { PlusBlueSvg } from 'assets/svg-icon';
import { FC, useEffect } from 'react';
import './Reactions.scss';
import { ReactionsList } from 'components/ReactionsComponent/ReactionsList/ReactionsList';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getReactionsRequest } from 'redux/reducers/reactions/reducer';
import { getReactionsSelector } from 'redux/reducers/reactions/selectors';

export const Reactions: FC = () => {
	const dispatch = useDispatch();
	const reactionsList = useSelector(getReactionsSelector);

	useEffect(() => {
		dispatch(
			getReactionsRequest({
				current_page: 1,
				per_page: 15,
			}),
		);
	}, []);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<div className="header-title">
						<p className="title">Reactions</p>
						<div className="add-btn-wrap">
							<Link
								// onClick={onClosePopup}
								style={{
									display: 'flex',
									gap: '5px',
									background: '#fff',
									color: '#0A49A5',
									border: '1px solid #CFCFD2',
								}}
								className="add-btn"
								to={ELinksName.REACTIONS_ADD}
							>
								<PlusBlueSvg />
								Add reaction
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="reactions-block reactions">
				{reactionsList ? <ReactionsList reactions={reactionsList} /> : null}
			</div>
		</div>
	);
};
