import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	getAdminsListRequest,
	getAdminsListSuccess,
	adminsInitState,
	createAdminSuccess,
	createAdminRequest,
	getAdminSuccess,
	getAdminRequest,
	changeAdminRequest,
	deleteAdminRequest,
	enableOrDisable2FaAdmin,
	getCSVRequest,
} from './reducer';
import { IAdminsRequest, IAdminsResponse } from './types';
import {
	I2FaRequest,
	IAddNewUserSlice,
	IAdmin,
	IChangeAdmin,
	IDeleteUser,
	IDeleteUserSlice,
} from 'ui/AddNewAdmins/type';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { notificationContainer } from 'untils/notificationContainer';

function* getAdminsListWorker({ payload }: PayloadAction<IAdminsRequest>) {
	try {
		const response: { data: Array<IAdminsResponse> } = yield call(api.admins.adminsList, payload);
		yield put(getAdminsListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

function* getAdminWorker({ payload }: PayloadAction<IAdmin>) {
	try {
		const response: IAdminsResponse = yield call(api.admins.getAdmin, payload);
		yield put(getAdminSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

function* createAdminWorker({ payload }: PayloadAction<IAddNewUserSlice>) {
	const { paramsApi, onAddAdmin } = payload;

	try {
		yield call(api.admins.createAdmins, paramsApi);
		yield put(createAdminSuccess());
		yield onAddAdmin();
		yield notificationContainer('administrator was created successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

function* changeAdminWorker({ payload }: PayloadAction<IChangeAdmin>) {
	try {
		yield call(api.admins.changeAdmin, payload);
		yield put(createAdminSuccess());
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

function* deleteAdminWorker({ payload }: PayloadAction<IDeleteUserSlice>) {
	const { paramsApi, navigate } = payload;

	try {
		yield call(api.admins.deleteAdmin, paramsApi);
		yield put(createAdminSuccess());
		yield navigate(`${ELinksName.ADMINS}`);
		yield notificationContainer('admin removal successful', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

function* enableOrDisable2FaAdminWorker({ payload }: PayloadAction<I2FaRequest>) {
	try {
		yield call(api.admins.enableOrDisableAdmin, payload);
		yield put(createAdminSuccess());
		yield notificationContainer(`2fa ${!payload.disable ? 'enable' : 'disable'}`, 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

function* getCSVWorker() {
	try {
		const response: Blob = yield call(api.admins.getCSV);
		const a = document.createElement('a');
		const file = new Blob([response], { type: 'application/csv' });
		a.href = URL.createObjectURL(file);
		a.download = 'Information.csv';
		a.click();
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(adminsInitState());
	}
}

export function* adminsSaga() {
	yield takeEvery(getAdminsListRequest.type, getAdminsListWorker);
	yield takeEvery(createAdminRequest.type, createAdminWorker);
	yield takeEvery(getAdminRequest.type, getAdminWorker);
	yield takeEvery(changeAdminRequest.type, changeAdminWorker);
	yield takeEvery(deleteAdminRequest.type, deleteAdminWorker);
	yield takeEvery(enableOrDisable2FaAdmin.type, enableOrDisable2FaAdminWorker);
	yield takeEvery(getCSVRequest.type, getCSVWorker);
}
