import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IBalancesDeposit,
	IBalancesWithdrawl,
	ICommissionsResonse,
	IFiatRequest,
	IFiatResponse,
	IFinanceState,
	IMethods,
	IRequestIWithdrawalUpdate,
	IStatisticsRequest,
	IStatisticsResponse,
	ITransactionGraphRequest,
	ITransactionGraphResponse,
	IWithdrawalDetails,
} from './finance-types';

export const initialState: IFinanceState = {
	fiatList: null,
	fiat: null,
	commissions: null,
	paymentFiat: null,
	statistics: null,
	graph: null,
	methods: null,
	balancesWithdrawal: null,
	balancesDeposit: null,
	financeLoader: false,
	loader: false,
};

const finance = createSlice({
	name: '@@finance',
	initialState,
	reducers: {
		getFinanceRequest: (state, action: PayloadAction<IFiatRequest>) => {
			const getFinanceState = state;
			getFinanceState.financeLoader = true;
		},

		getFinanceSuccess: (state, action: PayloadAction<IFiatResponse>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.fiatList = payload;
			getFinanceState.financeLoader = false;
		},

		getFinanceDetailsRequest: (state, action: PayloadAction<string>) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getFinanceDetailsSuccess: (state, action: PayloadAction<IWithdrawalDetails | null>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.fiat = payload;
			getFinanceState.loader = false;
		},

		setWithdrawalRequest: (state, action: PayloadAction<IRequestIWithdrawalUpdate>) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		setWithdrawalSuccess: (state, action: PayloadAction<number>) => {
			const getFinanceState = state;
			const { payload } = action;

			if (getFinanceState.fiatList?.requests) {
				getFinanceState.fiatList.requests = getFinanceState.fiatList?.requests.filter(
					(item) => item.tx_id !== Number(payload),
				);
			}
			getFinanceState.loader = false;
		},

		getCommissionsRequest: (state) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getCommissionsSuccess: (state, action: PayloadAction<ICommissionsResonse>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.commissions = payload;
			getFinanceState.loader = false;
		},

		setCommissionRequest: (state, action: PayloadAction<ICommissionsResonse>) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		setCommissionSuccess: (state, action: PayloadAction<ICommissionsResonse>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.commissions = payload;
			getFinanceState.loader = false;
		},

		getMethodsRequest: (state) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getMethodsSuccess: (state, action: PayloadAction<IMethods>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.methods = payload;
			getFinanceState.loader = false;
		},

		setMethodsRequest: (state, action: PayloadAction<IMethods>) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		setMethodsSuccess: (state, action: PayloadAction<IMethods>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.methods = payload;
			getFinanceState.loader = false;
		},

		getTransactionRequest: (state, action: PayloadAction<IStatisticsRequest>) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getTransactionSuccess: (state, action: PayloadAction<IStatisticsResponse>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.statistics = payload;
			getFinanceState.loader = false;
		},

		getBalancesDepositRequest: (state) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getBalancesDepositSuccess: (state, action: PayloadAction<IBalancesDeposit>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.balancesDeposit = payload;
			getFinanceState.loader = false;
		},

		getBalancesWithdrawalRequest: (state) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getBalancesWithdrawalSuccess: (state, action: PayloadAction<IBalancesWithdrawl>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.balancesWithdrawal = payload;
			getFinanceState.loader = false;
		},

		getTransactionGraphRequest: (state, action: PayloadAction<ITransactionGraphRequest>) => {
			const getFinanceState = state;
			getFinanceState.loader = true;
		},

		getTransactionGraphSuccess: (
			state,
			action: PayloadAction<Array<ITransactionGraphResponse>>,
		) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.graph = payload;
			getFinanceState.loader = false;
		},

		getPDFRequest: (state, action: PayloadAction<string>) => {
			const getFinanceState = state;
			getFinanceState.financeLoader = true;
		},

		getPDFSuccess: (state, action: PayloadAction<Array<ITransactionGraphResponse>>) => {
			const getFinanceState = state;
			const { payload } = action;
			getFinanceState.graph = payload;
			getFinanceState.financeLoader = false;
		},

		financeInitState: (state) => {
			const financeInitState = state;
			financeInitState.financeLoader = false;
			financeInitState.loader = false;
		},
	},
});

export default finance.reducer;
export const {
	getFinanceRequest,
	getFinanceSuccess,
	getFinanceDetailsRequest,
	getFinanceDetailsSuccess,
	setWithdrawalRequest,
	setWithdrawalSuccess,
	getCommissionsRequest,
	getCommissionsSuccess,
	setCommissionRequest,
	setCommissionSuccess,
	getMethodsRequest,
	getMethodsSuccess,
	setMethodsRequest,
	setMethodsSuccess,
	getTransactionRequest,
	getTransactionSuccess,
	getBalancesDepositRequest,
	getBalancesDepositSuccess,
	getBalancesWithdrawalRequest,
	getBalancesWithdrawalSuccess,
	getTransactionGraphRequest,
	getTransactionGraphSuccess,
	getPDFRequest,
	getPDFSuccess,
	financeInitState,
} = finance.actions;
