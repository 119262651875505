import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';

import { IStructureData } from '../data';
import { SelectList } from 'ui/SelectList/SelectList';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { Input } from 'ui/Input';
import { IButtons } from 'redux/reducers/ai-chat-bot/types';

interface IProps {
	structure: Array<IButtons>;
	onEditButton: (
		name: { [key: string]: string },
		option: number,
		key: string,
		event: KeyboardEvent<HTMLInputElement>,
	) => void;
	keyBtn?: string;
}

export const Accordion: FC<IProps> = ({ structure, onEditButton, keyBtn }) => {
	const [input, setInput] = useState<{ [key: string]: string }>({});

	const onChange = (event: ChangeEvent) => {
		const { value, id } = event.target as HTMLInputElement;

		if (value.length > 30) {
			return;
		}

		setInput((prev) => ({ ...prev, [id]: value || ' ' }));
	};

	return (
		<div className="accordion">
			{structure.map((btn, index) => (
				<div key={index} className="accordion__box accordion__box-2">
					<div className="accordion__left-content">{`Button ${index + 1}`}</div>
					<label className="accordion__label">
						Name
						<input
							title="Press Enter to make changes"
							onChange={onChange}
							onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
								onEditButton(input, btn.option, keyBtn || '', event)
							}
							value={input[btn.option] || btn.name}
							id={String(btn.option)}
							className="accordion__input"
							type="text"
						/>
						<span>{30 - (input[btn.option]?.length || btn.name?.length)}</span>
					</label>
					{/* <label className="accordion__label">
						Command
						<input id={String(btn.option)} className="accordion__input" type="text" />
					</label> */}
					{/* <div className="accordion__right-content">
					<Editor styles={{ minHeight: 'auto' }} />
				</div> */}
				</div>
			))}
			{/* {structure.number_of_templates ? (
				<div className="accordion__box">
					<div className="accordion__left-content">{structure.number_of_templates}</div>
					<div className="accordion__right-content">
						<SelectList placeholder="Select from the list" data={['1', '2']} />
					</div>
				</div>
			) : null} */}
		</div>
	);
};
