import { DocxSvg, FigSvg, PdfSvg, XlsSvg, ZipSvg, FileSvg } from 'assets/svg-icon';

export const getTypeFile = (key: string) => {
	switch (key) {
		case 'zip':
			return <ZipSvg width={46} height={55} />;
		case 'docx':
			return <DocxSvg width={46} height={55} />;
		case 'xls':
		case 'xlsx':
			return <XlsSvg width={46} height={55} />;
		case 'pdf':
			return <PdfSvg width={46} height={55} />;
		case 'fig':
			return <FigSvg width={46} height={55} />;
		default:
			return <FileSvg width={46} height={55} />;
	}
};
