import { type_transaction } from 'CONST/typeFile';
import { Loader } from 'components/Loader';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, MouseEvent, useState } from 'react';
import { ITransactionResponse } from 'redux/reducers/users/types';
import { NotFound } from 'ui/NotFound/NotFound';
import { SvgArrowUpHeader, SvgArrowDownHeader } from 'ui/svg-image/SvgAllChannels';
import { numeralFunc } from 'untils/numerals';

interface IProps {
	tableDate: ITransactionResponse;
	loader: boolean;
	setDataFiltter: (value: IDataFiltter) => void;
}

export const UserTransactionHistory: FC<IProps> = ({ setDataFiltter, tableDate, loader }) => {
	const transactions = tableDate?.data?.transactions;
	const [sort, setSort] = useState<number>(0);
	const [prev, setPrev] = useState<string>('');

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;

		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });

				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });

				break;
			default:
				setDataFiltter({ order_by: target.id });

				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};

	return (
		<div className="table table--users-transaction-history">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>#Number</p>
							<div className="sort-td">
								<button onClick={sortTable} name="asc" id="number" type="button">
									<SvgArrowUpHeader />
								</button>
								<button onClick={sortTable} name="desc" id="number" type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Date</p>
							<div className="sort-td">
								<button name="asc" id="date" onClick={sortTable} type="button">
									<SvgArrowUpHeader />
								</button>
								<button name="desc" id="date" onClick={sortTable} type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p> Type</p>
							<div className="sort-td">
								<button name="asc" id="type" onClick={sortTable} type="button">
									<SvgArrowUpHeader />
								</button>
								<button name="desc" id="type" onClick={sortTable} type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Amount</p>
							<div className="sort-td">
								<button name="asc" id="amount" onClick={sortTable} type="button">
									<SvgArrowUpHeader />
								</button>
								<button name="desc" id="amount" onClick={sortTable} type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Commission</p>
							<div className="sort-td">
								<button name="asc" id="commission" onClick={sortTable} type="button">
									<SvgArrowUpHeader />
								</button>
								<button name="desc" id="commission" onClick={sortTable} type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Status</p>
							<div className="sort-td">
								<button name="asc" id="status" onClick={sortTable} type="button">
									<SvgArrowUpHeader />
								</button>
								<button name="desc" id="status" onClick={sortTable} type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{loader ? (
					<Loader />
				) : transactions?.length ? (
					transactions?.map((transaction) => (
						<div key={transaction.id} className="tr">
							<div className="td">
								<p className="td-hidden-name">#Number</p>
								<p>{transaction.peer_id}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Date</p>
								<p>{transaction.created_at}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name"> Type</p>
								<p>
									{
										transaction.payment_method[0].toUpperCase() +
											transaction.payment_method.slice(1).replaceAll('_', ' ')
										// type_transaction[String(transaction.peer_type)]
									}
								</p>
							</div>
							<div className="td td--fw-500">
								<p className="td-hidden-name">Amount</p>
								<p className="green">{`$${numeralFunc(transaction.amount)}`}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Commission</p>
								<p>{`$${numeralFunc(transaction.fee)}`}</p>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Status</p>
								<p>{transaction.status}</p>
							</div>
						</div>
					))
				) : (
					<NotFound name={'channels'} />
				)}
			</div>
		</div>
	);
};
